import React, { useEffect, useState } from 'react'
import { Row, Col, Carousel, Card, Button } from 'react-bootstrap';
import { Splide, SplideSlide } from '@splidejs/react-splide';
// import '@splidejs/react-splide/css';
import Logo from '../images/ScopeHaiLogo.png'
import cert1 from '../images/cert1.png'
import cert2 from '../images/cert2.png'
import cert3 from '../images/cert3.png'
import cert4 from '../images/cert4.png'
import job1 from '../images/jobs/comp1.webp'
import job2 from '../images/jobs/comp2.webp'
import job3 from '../images/jobs/comp3.webp'
import job4 from '../images/jobs/comp4.webp'
import { connect } from 'react-redux';
import { setToken } from '../../features/auth/authSlice';
import { fetchPlacementDrives, getAllJobs } from '../../features/constants/apis'
import { GetAllApplicationsOfDrive } from '../../features/constants/apis';
import { Link, useNavigate } from "react-router-dom";
import PlacementDriveCard from './Cards/PlacementDriveCard';
import ShimmerJobs from './Shimmer/ShimmerJobs';
import JobListCard from './JobListCard';

function EmployerDashboard({ userData, role, fullName, authToken, ID }) {
    const [placementDrives, setPlacementDrives] = useState([])
    const [empPlacementDriveCount, setEmpPlacementDriveCount] = useState(0)

    const [recentDrives, setRecentDrives] = useState([])
    const [recentJobs, setRecentJobs] = useState([])

    const [jobs, setJobs] = useState([])
    const [employerJobCount, setEmployerJobCount] = useState(null)

    const [jobAppliations, setJobApplications] = useState(0);
    const [jobAppliationsCount, setJobApplicationsCount] = useState(0);

    const tempData = [
        {
            "ID": 1,
            "Image": cert1,
            "Name": "Amazon and Flipkart managemet",
            "Title": "Amazon and Flipkart Training and Placement program",
            "Stat1": "Amazon",
            "Stat2": "Flipkart",
            "Stat3": "Training",
            "Stat4": "Placement",
            "Price": "₹8,999/-",
            "CreatedBy": 1
        },
        {
            "ID": 2,
            "Image": cert2,
            "Name": "Video editing",
            "Title": "Video editing and commercial reel making",
            "Stat1": "Videos",
            "Stat2": "Reels",
            "Stat3": "Shorts",
            "Stat4": "Commercials",
            "Price": "₹12,999/-",
            "CreatedBy": 1
        },
        {
            "ID": 3,
            "Image": cert3,
            "Name": "Trading",
            "Title": "Equity markets and Trading trainer certification",
            "Stat1": "Equity",
            "Stat2": "Trading",
            "Stat3": "Financial",
            "Stat4": "Training",
            "Price": "₹6,999/-",
            "CreatedBy": 1
        },
        {
            "ID": 4,
            "Image": cert4,
            "Name": "Data analysis",
            "Title": "Data analysis for E-commerce business",
            "Stat1": "Data analysis",
            "Stat2": "E-commerce",
            "Stat3": "Big data",
            "Stat4": "Business",
            "Price": "₹19,999/-",
            "CreatedBy": 1
        },
        {
            "ID": 5,
            "Image": cert1,
            "Name": "Amazon and Flipkart managemet",
            "Title": "Amazon and Flipkart Training and Placement program",
            "Stat1": "Amazon",
            "Stat2": "Flipkart",
            "Stat3": "Training",
            "Stat4": "Placement",
            "Price": "₹8,999/-",
            "CreatedBy": 1
        },
        {
            "ID": 6,
            "Image": cert2,
            "Name": "Video editing",
            "Title": "Video editing and commercial reel making",
            "Stat1": "Videos",
            "Stat2": "Reels",
            "Stat3": "Shorts",
            "Stat4": "Commercials",
            "Price": "₹12,999/-",
            "CreatedBy": 1
        }
    ]

    const navigate = useNavigate();
    const handleViewNewDriveForm = () => {
        navigate(`/new-placement-drive-form`)
    }
    const handleViewNewJobForm = () => {
        navigate(`/new-job-form`)
    }

    useEffect(() => {
        const fetchData = async () => {
            const PDdata = await fetchPlacementDrives(authToken);
            setPlacementDrives(PDdata.data);
            console.log(PDdata.data)

            const empPlacementDrive = PDdata.data.filter(drive => drive?.CreatedBy === ID);
            setEmpPlacementDriveCount(empPlacementDrive.length);
            // console.log(empPlacementDriveCount)
            console.log(empPlacementDrive[0]?.CreatedOn)

            const today = new Date()

            const recentlyAddedDrives = empPlacementDrive.filter(drive => {
                const createdDate = new Date(drive?.CreatedOn);
                console.log(createdDate);

                const timeDifference = today - createdDate;
                const dayDifference = timeDifference / (1000 * 3600 * 24);
                console.log(dayDifference);

                return dayDifference <= 15;
            });

            setRecentDrives(recentlyAddedDrives);
            console.log(recentlyAddedDrives)
        }
        fetchData();
    }, [authToken, ID, empPlacementDriveCount]);

    // jobs
    useEffect(() => {
        const fetchJobData = async () => {
            const data = await getAllJobs(authToken);
            setJobs(data.data);
            console.log(data.data);

            // const employerJobs = data.data.filter(job => (job?.EmployerID === ID) && (job?.PlacementDriveID === null))
            const employerJobs = data.data.filter(job => job?.EmployerID === ID);
            setEmployerJobCount(employerJobs.length);

            console.log(employerJobs)

            const today = new Date();
            const recentlyAddedJobs = employerJobs.filter(job => {
                const createdDate = new Date(job?.CreatedOn);
                console.log('created', createdDate);
                console.log(job.CreatedOn)

                const timeDifference = today - createdDate;
                const dayDifference = timeDifference / (1000 * 3600 * 24)

                return dayDifference <= 15;
            })
            setRecentJobs(recentlyAddedJobs);
            console.log(recentlyAddedJobs)

        };
        fetchJobData()
    }, [authToken])


    useEffect(() => {
        const fetchPDApplications = async () => {
            const data = await GetAllApplicationsOfDrive(authToken)
            setJobApplications(data.data)

            const applicationData = data.filter(application => application?.EmployerID === ID);
            setJobApplicationsCount(applicationData.length);
        }
        fetchPDApplications()
    }, [authToken, ID])

    const handleViewDriveDetails = (drive) => {
        // setSelectedDrive(drive);
        navigate(`/emp-placement-drive-details?drive=${drive}`)
    }



    return (
        <div id="appCapsule" className='Dashboard'>
            {/* Dashboard Count View Start */}
            <div className='section full py-4'>
                <div className='container'>
                    {/* <h1 className="title text-light">Dashboard</h1> */}
                    <div className="row">
                        <div className="col-lg-4 col-6 mb-2">
                            <div className="card">
                                <Link to='/all-emp-jobs'>
                                    <div className="card-body d-flex justify-content-between align-items-center flex-sm-col">
                                        <div className='db-bg-icon order-sm-1'>
                                            <ion-icon size="large" name="person-outline"></ion-icon>
                                        </div>
                                        <div className='order-sm-0'>
                                            <h5 className="card-title">{employerJobCount}</h5>
                                            <h6 className="card-subtitle">Current Hiring</h6>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        </div>
                        <div className="col-lg-4 col-6 mb-2">
                            <div className="card">
                                <Link to='/emp-placement-drives'>
                                    <div className="card-body d-flex justify-content-between align-items-center flex-sm-col">
                                        <div className='db-bg-icon order-sm-1'>
                                            <ion-icon size="large" name="albums-outline"></ion-icon>
                                        </div>
                                        <div className='order-sm-0'>
                                            <h5 className="card-title">{empPlacementDriveCount}</h5>
                                            <h6 className="card-subtitle">Placement Drives</h6>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        </div>
                        <div className="col-lg-4 col-6 mb-2">
                            <div className="card">
                                <Link to='/all-emp-applications'>
                                    <div className="card-body d-flex justify-content-between align-items-center flex-sm-col">
                                        <div className='db-bg-icon order-sm-1'>
                                            <ion-icon size="large" name="person-add-outline"></ion-icon>
                                        </div>
                                        <div className='order-sm-0'>
                                            <h5 className="card-title">{jobAppliationsCount}</h5>
                                            <h6 className="card-subtitle">New Applications</h6>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Dashboard Count View End */}
            {/* User Profile Start */}
            <div className="section full mt-2 mb-4">
                <div className='container'>
                    <h1 className="title">Hi, {fullName}</h1>
                    <h2 className='sub-title'>Welcome to your Employer Dashboard !</h2>
                    <p>Here is how your profile is performing on Scopehai Talent platform.</p>
                    <Button variant="primary" size="lg" className='me-2 mb-1' onClick={handleViewNewDriveForm}>New Placement Drive</Button>
                    <Button variant="outline-primary" size="lg" className="mb-1" onClick={handleViewNewJobForm}>Start Hiring</Button>
                    {/* <Row>
                        <Col className='d-flex justify-content-center'>
                            <Button variant="primary" size="lg">New Placement</Button>
                        </Col>
                        <Col className='d-flex justify-content-center'>
                            <Button variant="outline-primary" size="lg">Start Hiring</Button>
                        </Col>
                    </Row> */}
                </div>
            </div>
            {/* User Profile End */}

            {recentDrives.length > 0 && (
                <div className="section full mt-2 mb-4 py-4">
                    <div className='container'>
                        <h2>Recent Placement Drives</h2>
                        {/* <Row xs={1} md={2} className="g-4"> */}
                        <Splide
                            options={{
                                rewind: false,
                                gap: '.5rem',
                                perMove: 1,
                                perPage: 2,
                                type: 'slide',
                                autoplay: false,
                                interval: 3000,
                                breakpoints: {
                                    875: {
                                        perPage: 2
                                    },
                                    585: {
                                        perPage: 1
                                    }
                                }
                            }}
                            aria-label="React Splide Example">
                            {recentDrives.map((drive, idx) => (
                                <SplideSlide>
                                    <PlacementDriveCard key={idx} drive={drive} handleViewDriveDetails={handleViewDriveDetails} />
                                </SplideSlide>
                            ))}
                        </Splide>
                        {/* </Row> */}
                    </div>
                </div>
            )}

            {recentJobs.length > 0 && (
                <div className="section full mt-2 mb-4 py-4">
                    <div className='container'>
                        <h2>Recent Jobs</h2>
                        {recentJobs.length === null ? <ShimmerJobs />
                            :
                            <JobListCard
                                allJobs={recentJobs}
                            />
                        }
                    </div>
                </div>
            )}
        </div>
    )
}

const mapStateToProps = ({ auth }) => {
    const { userData, role, fullName, ID, authToken } = auth

    return {
        userData,
        role,
        fullName,
        ID,
        authToken
    }
}

const mapDispatchToProps = {
    setToken
}


export default connect(mapStateToProps, mapDispatchToProps)(EmployerDashboard)