import React from "react";
import { connect } from "react-redux";
import { useParams, useNavigate, Link } from 'react-router-dom';
import { useEffect, useState } from "react";
import { useLocation } from 'react-router-dom';
import { Row, Col, Card, Button  } from "react-bootstrap";
// import job5 from '../images/jobs/comp5.webp';
// import job5 from '../images/Favicon_large-removebg-preview.png'
import job5 from '../images/Favicon_large-removebg-preview.png'

import userPhoto from '../images/user-profile.webp'
import { GetAllApplicationsOfDrive, fetchPlacementDrivesData } from "../../features/constants/apis";
import { ShimmerCategoryList } from "react-shimmer-effects";
import ShimmerEmpPdDetails from './Shimmer/ShimmerEmpPdDetails';
import { FaArrowLeft } from "react-icons/fa";

const EmpPlacementJobsDetails = ({driveID, userData, role, fullName, ID, authToken, selectedJob }) =>{

    const [placementDrivesData, setPlacementDrivesData] = useState([]);
    const [ApplicationsOfUser, setApplicationsOfUser] = useState(null);

    const [pDriveJob, setPDriveJob] = useState([])

    const navigate = useNavigate();

   
    
    // const handleViewJobDetails = (job) =>{
    //     // setSelectedDrive(drive);
    //     navigate(`/emp-placement-drive-details?Title=${drive}&Job=${job}`)
    // }


    // const { Title } = useParams();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const getDriveID = searchParams.get('driveID');
    const JobId = searchParams.get('Job');


    const toBack = `/emp-placement-drive-details?drive=${getDriveID}`

    useEffect(()=>{
        console.log('Drive ID:', getDriveID);
        const fetchData = async () => {
            const data = await fetchPlacementDrivesData(getDriveID, authToken);
            setPlacementDrivesData(data.data);
            console.log(data)
        }
        fetchData();
    }, [authToken, getDriveID]);

    console.log(placementDrivesData)

    
    useEffect(()=>{
        if (placementDrivesData && placementDrivesData.Jobs) {
            const placementDriveJob = placementDrivesData.Jobs;
            console.log(placementDriveJob)
            const pdJob = placementDriveJob.filter((item) => item.JobID == JobId);
            console.log(pdJob);
            setPDriveJob(pdJob[0])
        } else {
            console.log("placementDrivesData or placementDrivesData.Jobs is undefined");
        }
    }, [JobId, placementDrivesData,])
    
    console.log(pDriveJob)

    
    useEffect(() => {

        const fetchData = async () => {
            const SendData = {
                placementDriveID : getDriveID,
                employerID : ID,
                jobID: JobId
            }
            const data = await GetAllApplicationsOfDrive(SendData, authToken);
            setApplicationsOfUser(data);
            console.log(data)
        }
        fetchData();
    }, [authToken, getDriveID, ID, JobId])
 
    console.log(selectedJob);

    const handleViewUserProfile = (candidateID) => {
        navigate(`/candidate-profile?candidate=${candidateID}`)
    }


    return(
        <div id="appCapsule">
            <div className="section mb-2 mt-2 full">
                <div className="container pb-2">
                <Link to={toBack} type="submit" className='my-2 pe-2' ><FaArrowLeft style={{width: '23px', height: '23px'}}/></Link> 
                
                {pDriveJob === null ? <ShimmerEmpPdDetails /> : 
                <Row xs={1} md={1} className="g-4">
                    <Col>
                        <Card>
                        <Card.Body>
                                <Row className='pb-2'>
                                    <Col lg="2" md="2" xs="2"><Card.Img variant="top" src={pDriveJob?.Image ? pDriveJob?.Image : job5}/></Col>
                                    <Col lg="10" md="10" xs="10">
                                        <Card.Title>{pDriveJob?.jobTitle}</Card.Title>
                                        <Card.Text className="">{pDriveJob?.companyName}</Card.Text>
                                        <Card.Text>{pDriveJob?.jobCtcFrom} - {pDriveJob?.jobCtcTo} LPA . {pDriveJob?.jobOpenings} openings</Card.Text>
                                    </Col>
                                </Row>
                                {/* <Card.Text className='py-3'>{data?.Criteria}</Card.Text> */}
                                </Card.Body>
                        </Card>
                    </Col>
                </Row>
                }
                

                

                </div>
            </div>

            {/* Applicants List Start */}
            
            {(role === 'Employer' || role === 'Admin') &&
            <>
                <div className="section full mt-4 mb-4">
                    <div className='container'>
                        <div className="section-title ps-0">All Applied Candidates</div>
                        <ul className="listview image-listview media">
                        {/* <ShimmerCategoryList title items={6} categoryStyle="STYLE_SEVEN" /> */}
                            {
                                ApplicationsOfUser === null &&
                                <li className='my-2'>
                                <div className="item">
                                    <div className="imageWrapper in">
                                        <div className="in">
                                            <div>Loading...</div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            }

                            {
                                ApplicationsOfUser !== null && ApplicationsOfUser.length === 0 &&
                                <li className='my-2'>
                                <div className="item">
                                    <div className="imageWrapper in">
                                        <div className="in">
                                            <div>No Application Applied</div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            }
                            {
                                ApplicationsOfUser !== null && ApplicationsOfUser.length > 0 &&
                                ApplicationsOfUser.map((item, i) => {
                                    return (
                                        <li key={i} className='my-2'>
                                            <div className="item">
                                                <div className="imageWrapper in">
                                                    <img src={userPhoto} alt="" className="imaged w64" />
                                                    <div className="in">
                                                        <div>{item.CandidateName}</div>
                                                    </div>
                                                </div>
                                                <div className="in">
                                                    <div>{item.JobTitle}</div>
                                                </div>
                                                <div className="in">
                                                    <div>{item.PlacementDriveName}</div>
                                                </div>
                                                <div className='d-flex align-items-center'>
                                                    {/* <Button className='me-2 p-1 rounded' variant="secondary">
                                                        <ion-icon style={{marginRight: '0px'}} name="bookmark-outline"></ion-icon>
                                                    </Button> */}
                                                    <Button variant="primary" onClick={() => handleViewUserProfile(item?.CandidateID)}>View</Button>
                                                </div>
                                            </div>
                                        </li>
                                    )
                                })
                            }
                        </ul>
                    </div>
                </div>
            </>
            }
            {/* Applicants List End */}

        </div>
    )
}

const mapStateToProps = ( { auth, selectedDrive } ) => {
    const { userData, role, fullName, ID, authToken } = auth
    const { selectedJob } = selectedDrive
  
    return {
        userData,
        authToken,
        role,
        fullName, ID, selectedJob
    }
  }

export default connect(mapStateToProps) (EmpPlacementJobsDetails)