import React from 'react'
import { useEffect } from 'react'
import { Button, Col, Form, Row } from 'react-bootstrap'
import { FaArrowLeft } from 'react-icons/fa'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { GetAllApplicationsOfDrive } from '../../features/constants/apis'
import { useState } from 'react'
import DataTable, { createTheme } from 'react-data-table-component'
import ApplicantCard from './Cards/ApplicantCard'

const EmpApplicantsList = ({ID, authToken}) => {

    const [jobApplicants, setJobApplicants] = useState([]);

    const [searchedTerm, setSearchedTerm] = useState('')

    useEffect(() => {
        const fetchPDApplications = async () => {
            console.log(authToken)
            const data = await GetAllApplicationsOfDrive(authToken)
            // console.log(data)
            setJobApplicants(data.filter(application => application?.EmployerID === ID))
            // console.log(data.filter(application => application?.EmployerID === ID))
        }
        fetchPDApplications();
    }, [authToken, ID]);


    const filteredApplications = jobApplicants.filter(application => (application.JobTitle.toLowerCase().includes(searchedTerm.toLocaleLowerCase())) || (application.PlacementDriveName.toLowerCase().includes(searchedTerm.toLocaleLowerCase())))

    // console.log(searchedTerm.toLocaleLowerCase())
    // console.log(filteredApplications)

    const viewProfile = (id) => {
        window.location.href = `candidate-profile?candidate=${id}`
    }

    const applicantsColumn = [
        {
            name: 'Sr. No.',
            selector: (row, index) => (index + 1),
            width: '100px',
            sortable: true
        },
        {
            name: 'CandidateName',
            selector: (row) => row.CandidateName,
            width: '210px',
            sortable: true
        },
        {
            name: 'Job Title',
            selector: (row) => row.JobTitle,
            width: '200px',
            wrap: true,
            // sortable: true
        },
        {
            name: 'Placement-Drive Name',
            selector: (row) => row.PlacementDriveName,
            width: '250px',
            sortable: true
        },
        {
            name: 'Shortlist',
            cell: (row)=> (
                <div className="d-flex justify-content-center">
                <button className="btn btn-primary" onClick={() => viewProfile(row.ID)}>View</button>
                </div>
            ),
            width: '150px',
            wrap: true
        },
    ];

    createTheme('solarized', {
        background: {
          default: 'transparent',
        },
        context: {
          background: '#CDFFD8',
          text: '#FFFFFF',
        },
        divider: {
          default: '#45A994',
        },
        action: {
          button: 'rgba(0,0,0,.54)',
          hover: 'rgba(0,0,0,.08)',
          disabled: 'rgba(0,0,0,.12)',
        },
      }, 'light');


      const convertToCSV = (data) => {
        const headers = Object.keys(data[0]);
        const rows = data.map(row => headers.map(header => `"${row[header]}"`).join(','));
        return [headers.join(','), ...rows].join('\n');
    };

    // Function to trigger the CSV download
    const downloadCSV = (csvData, filename) => {
        const blob = new Blob([csvData], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.setAttribute('hidden', '');
        a.setAttribute('href', url);
        a.setAttribute('download', filename);
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    };

    // Export table data to CSV
    const exportToCSV = () => {
        const dataToExport = jobApplicants.map((row, index) => ({
            "Sr No.": index + 1,
            "ID": row.ID,
            "Application Date": row.CreatedOn,
            "Candidate Name": row.CandidateName,
            "JobTitle": row.JobTitle,
            "Placement-Drive Name": row.PlacementDriveName,
            "Shortlist": row.Shortlist,
        }));
        const csv = convertToCSV(dataToExport);
        downloadCSV(csv, 'JobApplicantions.csv');
    };


  return (
    <div id="appCapsule">
        <div className='section full py-4'>
            <div className='container'>
                <Button variant="primary mb-2">
                    <Link to="/" type="submit" className='my-2 text-white'><FaArrowLeft style={{width: '15px', height: '15px', marginRight: "7px"}}/>Go to Dashboard</Link>
                </Button>
                <h1 className="title text-dark">All Applicants</h1>
                {/* <div className="section-title text-dark ps-0">Recommended training and placement programs</div> */}
            </div>
        </div>

        <div className='section full mt-2 mb-4'>
            <div className='container'>
            <div className='top-sec-heading d-flex pb-4 justify-content-between'>
                                <h4>{jobApplicants.length} applicantions found</h4>
                                <Row>
                                    <Col xs="auto">
                                        <Form.Control
                                        type="text"
                                        placeholder="Search"
                                        className=" mr-sm-2"
                                        onChange={(e)=> setSearchedTerm(e.target.value)}
                                        />
                                    </Col>
                                    <Col xs="auto">
                                        <Button variant="outline-warning" onClick={exportToCSV}>Export to CSV</Button>
                                    </Col>
                                </Row>
                            </div>
                {/* <Row className='justify-content-between smy-3'> */}
                <div className="mt-5">
                    <ApplicantCard filteredApplications={filteredApplications} />
                </div>
                    {/* <div>
                        {filteredApplications.length > 0 && (
                            <DataTable
                                columns={applicantsColumn}
                                data={filteredApplications}
                                striped={true}
                                theme='solarized'
                            />
                        )}
                    </div> */}
                {/* </Row> */}
            </div>
        </div>
    </div>
  )
}

const mapStateToProps =({auth}) => {
    const {ID, authToken } = auth
    return {
        ID,
        authToken
    }
}

export default connect(mapStateToProps)(EmpApplicantsList)