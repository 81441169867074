import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { fetchJobData } from "../../../features/constants/apis";
import { Badge, Button, Card, Col, Modal, Row, Tab, Tabs } from "react-bootstrap";
import { GoDotFill } from "react-icons/go";
import job5 from "../../images/jobs/job6.png"
import userPhoto from '../../images/user-profile.webp'

const AdminJobDetailModal = ({ show, onHide, jobData, filteredCandidates, filteredCandidateLength, applicationsOfUser }) => {

    const [key, setKey] = useState('home');

    console.log(applicationsOfUser)

    return (
        <Modal
            show={show}
            onHide={onHide}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >

            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Job Details
                </Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <Col>
                    <Card className='placement-drive-card'></Card>
                    <Row className='pb-2 border-bottom border-gray pd-card-head'>
                        <Col lg="2"><Card.Img variant="top" src={jobData?.Image ? jobData?.Image : job5} /></Col>
                        <Col lg="10">
                            <div className="d-flex justify-content-between">
                                <Card.Title>{jobData?.Title}</Card.Title>
                            </div>
                            <Card.Text className='drive-details dot-icon'> {jobData.Type} <GoDotFill /> {jobData?.Ctc} LPA <GoDotFill /> {jobData?.Openings} Openings </Card.Text>
                        </Col>
                    </Row>
                    <div className='d-flex justify-content-between align-items-center gap-3'>
                        <div>
                            <Card.Text>{jobData?.Education} <span className="text-muted">required</span></Card.Text>
                            <Card.Text>
                                <div className="pb-2">
                                    <h5>General Skills:</h5>
                                    <div>
                                        {jobData?.Skills?.GeneralSkills ?
                                            jobData?.Skills?.GeneralSkills.map((skill, index) => (
                                                <div key={index} className="chip chip-primary ms-05 mb-05">
                                                    <span className="chip-label">{skill}</span>
                                                </div>
                                            )) : <span className="text-muted">No General Skill Found</span>
                                        }
                                    </div>
                                </div>
                                <div className="pb-2">
                                    <h5>Professional Skills:</h5>
                                    <div>
                                        {jobData?.Skills?.ProfessionalSkills ?
                                            jobData?.Skills?.ProfessionalSkills.map((skill, index) => (
                                                <div key={index} className="chip chip-primary ms-05 mb-05">
                                                    <span className="chip-label">{skill}</span>
                                                </div>
                                            )) : <span className="text-muted">No Professioanl Skill Found</span>
                                        }
                                    </div>
                                </div>
                            </Card.Text>
                            <Card.Text className='mb-0 location'><ion-icon name="location-outline"></ion-icon> {jobData?.Location}</Card.Text>
                            <Card.Text>{jobData?.CompanyName}</Card.Text>
                        </div>
                    </div>
                    <div className="section full mt-4 mb-4 candidatesList">
                            <div className='container'>
                                <Tabs
                                    defaultActiveKey="suggested"
                                    id="controlled-tab-example"
                                    // id="noanim-tab-example"
                                    // activeKey={key}
                                    onSelect={(k) => setKey(k)}
                                    className="mb-3"
                                    >
                                    <Tab eventKey="suggested" title="Suggested">
                                   {
                                        <div>
                                            <div className="d-flex gap-2 align-items-center">
                                                <div className="section-title ps-0">All Suggested Candidates</div>
                                                <Badge bg="secondary">{filteredCandidates ? Object.keys(filteredCandidates).length : '0'}</Badge>
                                            </div>
                                            <ul className="listview image-listview media">
                                                
                                                {
                                                    (filteredCandidates === null) &&
                                                        <li className='my-2'>
                                                        <div className="item">
                                                            <div className="imageWrapper in">
                                                                <div className="in">
                                                                    <div>Loading...</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </li>
                                                } 
                                                {
                                                    filteredCandidates !== null && filteredCandidateLength === 0 &&
                                                        <li className='my-2'>
                                                        <div className="item">
                                                            <div className="imageWrapper in">
                                                                <div className="in">
                                                                    <div>No Suggestion found</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </li>
                                                }

                                                {filteredCandidates ? (
                                                    filteredCandidates.map((candidate, i) => (
                                                            <li key={i} className='my-2'>
                                                                <div className="item">
                                                                    <div className="imageWrapper in">
                                                                    <img src={userPhoto} alt="" className="imaged w64" />
                                                                        <div className="in">
                                                                            <div>{candidate?.FullName}</div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="in">
                                                                        <div>{candidate?.JobName}</div>
                                                                    </div>
                                                                    <div className='d-flex align-items-center'>
                                                                        {/* <Button className='me-2 p-1 rounded' variant="secondary">
                                                                            <ion-icon style={{marginRight: '0px'}} name="bookmark-outline"></ion-icon>
                                                                        </Button> */}
                                                                        {/* <Button variant="primary" onClick={() => handleViewUserProfile(candidate?.ID)}>View</Button> */}
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        ))
                                                    ) : <p>no suggestion</p>
                                                }

                                            </ul>
                                        </div>
                                    }

                                    </Tab>
                                    <Tab eventKey="applied" title="Applied">
                                        <div className="d-flex gap-2 align-items-center">
                                            <div className="section-title ps-0">All Applied Candidates</div>
                                            <Badge bg="secondary">{applicationsOfUser ? applicationsOfUser.length : '0'}</Badge>
                                        </div>
                                        <ul className="listview image-listview media">
                                            {
                                            applicationsOfUser === null &&
                                                <li className='my-2'>
                                                <div className="item">
                                                    <div className="imageWrapper in">
                                                        <div className="in">
                                                            <div>Loading...</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                            }

                                            {
                                            applicationsOfUser !== null && applicationsOfUser.length === 0 &&
                                                <li className='my-2'>
                                                <div className="item">
                                                    <div className="imageWrapper in">
                                                        <div className="in">
                                                            <div>No Application found</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                            }
                                            {
                                            applicationsOfUser !== null && applicationsOfUser.length > 0 &&
                                            applicationsOfUser.map((item, i) => {
                                                    return (
                                                        <li key={i} className='my-2'>
                                                            <div className="item">
                                                                <div className="imageWrapper in">
                                                                <img src={item.CandidateImage ? item.CandidateImage : userPhoto} alt="" className="imaged w64" />
                                                                    <div className="in">
                                                                        <div>{item.CandidateName}</div>
                                                                    </div>
                                                                </div>
                                                                <div className="in">
                                                                    <div>{item.JobName}</div>
                                                                </div>
                                                                <div className='d-flex align-items-center'>
                                                                    {/* <Button className='me-2 p-1 rounded' variant="secondary">
                                                                        <ion-icon style={{marginRight: '0px'}} name="bookmark-outline"></ion-icon>
                                                                    </Button> */}
                                                                    {/* <Button variant="primary" onClick={() => handleViewUserProfile(item?.CandidateID)}>View</Button> */}
                                                                </div>
                                                            </div>
                                                        </li>
                                                    )
                                                })
                                            }
                                        </ul>
                                    </Tab>
                                </Tabs>
                                
                            </div>
                        </div>
                </Col>
            </Modal.Body>

        </Modal>
    )
}

const mapStateToProps = ({ auth, selectedDrive }) => {
    const { ID, role } = auth
    const { selectedJob } = selectedDrive

    return {
        ID,
        role,
        selectedJob
    }
}


export default connect(mapStateToProps)(AdminJobDetailModal);