import React from 'react'
import { Link } from 'react-router-dom'
import { connect } from "react-redux"

const Footer = () => {
    return (
        <>
            <div>
                <div class="section full mt-2 d-block d-md-none">
                    <div class="appBottomMenu">
                        <Link to="/summary" class="item text-secondary">
                            <div class="col d-flex justify-content-center align-items-center flex-column">
                                    <ion-icon name="storefront-outline" class="fs-4"></ion-icon>
                                <strong>Summary</strong>
                            </div>
                        </Link>
                        <Link to="/items" class="item text-secondary">
                            <div class="col">
                                <ion-icon name="cube-outline" class="fs-4"></ion-icon>
                                <strong>Items</strong>
                            </div>
                        </Link>
                        <Link to="/newOrder" class="item">
                            <div class="col">
                                <div class="action-button large">
                                    <ion-icon name="add-outline"></ion-icon>
                                </div>
                            </div>
                        </Link>
                        <Link to="/orders?tab=sales" class="item">
                            <div class="col">
                                <ion-icon name="cart-outline"></ion-icon>
                                <strong>Orders</strong>
                            </div>
                        </Link>
                        <Link to="/transactions" class="item">
                            <div class="col">
                                <ion-icon name="swap-vertical-outline"></ion-icon>
                                <strong>Transactions</strong>
                            </div>
                        </Link>
                    </div>
                </div>
            </div>   
        </>
    );
}

const mapStateToProps = () => {
    return {
    }
  }
  
  const mapDispatchToProps = {
  }

export default connect(mapStateToProps, mapDispatchToProps)(Footer)