import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  selectedDrive: null,
  selectedJob: null
};

const selectedDriveSlice = createSlice({
  name: 'selectedDrive',
  initialState,
  reducers: {
    setSelectedDrive(state, action) {
      state.selectedDrive = action.payload;
    },
    clearSelectedDrive(state) {
      state.selectedDrive = null;
    },
    setSelectedJob(state, action){
      state.selectedJob = action.payload;
    }
  },
});

export const { setSelectedDrive, clearSelectedDrive, setSelectedJob } = selectedDriveSlice.actions;
export default selectedDriveSlice.reducer;
