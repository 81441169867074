import React from 'react'

function ComingSoon() {
  return (
    <div id="appCapsule">
        <div className='section full mt-5 py-4'>
            <div className='container'>
                <h1 className="title text-center text-dark">Coming Soon</h1>
            </div>
        </div>
    </div>
  )
}

export default ComingSoon
