import React, { useState, useEffect } from 'react'
import { createCourseApplication } from '../../features/constants/apis';
import { Link, useNavigate } from 'react-router-dom'
import { Button, Card, Col, Row } from 'react-bootstrap'
import Iframe from 'react-iframe';
import { connect } from 'react-redux';
import { useSearchParams} from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';

const CertificateIframe = ({ID, authToken}) => {

    const [loaderState, setLoaderState] = useState(null);
    const [searchParams] = useSearchParams();
    
    const handleCourseApplication = ()=>{
        console.log(ID, authToken)
        const fetchData = async () => {
            const retrievedID = searchParams.get('id');
            const courseApplicationData = {
                'CourseID': retrievedID,
                'tokenUserID': ID
            }
            console.log(retrievedID, ID )
            const courseAppData = await createCourseApplication(courseApplicationData, authToken)
            console.log(courseApplicationData, courseAppData)
            if (courseAppData.data.message === 'Application Successful') {
                toast.success('Your application has been received successfully!', {
                    position: "top-right",
                    autoClose: 6000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light"
                })
            } else if (courseAppData.message === 'Already Applied') {
                toast.success('Already enrolled to this course.', {
                    position: "top-right",
                    autoClose: 6000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light"
                })
            }
        }
        fetchData();
    }

    return (
        <div id="appCapsule py-0">
            <div className='section full mt-2'>
                <Iframe src='https://ecampus.yourerpcoach.com/' styles={{height: "100vh", width: "100%"}} title='Certificate Landing Page'/>

                <div class="btn-fixed enroll-btn py-4">
                    <Button 
                        className="apply-btn btn-fixed text-center tran3s xs-mt-20 mb-2 text-nowrap"
                        variant='primary'
                        onClick={handleCourseApplication}
                    >Enroll Now</Button>
                </div>
                <ToastContainer />
            </div>
        </div>
    )
}

const mapStateToProps = ({ auth }) => {
    const { ID, authToken } = auth
    return {
      ID,
      authToken
    }
}  

export default connect(mapStateToProps) (CertificateIframe)