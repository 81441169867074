import React, { useState, useEffect } from 'react'
import { connect } from "react-redux";
import ShimmerJobs from '../Shimmer/ShimmerJobs';
import { Button, Image } from 'react-bootstrap';
import { useNavigate } from 'react-router';
import SampleImage from '../../images/profile-placeholder.jpeg'
import tick from '../../images/icon/tick-image.png'

const ApplicantCard = ({ authToken, role, allJobs, ID, filteredApplications }) => {

    const navigate = useNavigate();

    const [expandedItem, setExpandedItem] = useState(null);
    // const [loading, setLoading] = useState(true); 

    const toggleAccordion = (idx) => {
        setExpandedItem(expandedItem === idx ? null : idx);
    };

    const handleViewApplicationProfile = (id, JobID) => {
        navigate(`/candidate-profile?candidate=${id}&job=${JobID}`)
        
    }

    const handleContactApplicant = (phone) => {
        window.location.href = `tel:${phone}`;
    };

    console.log(filteredApplications)


    return (
        <>
            {(role === 'Employer') &&
                <ul className="listview image-listview media mt-4">
                    {
                        filteredApplications.map((application, idx) => {
                            return (
                                <li className='my-2 custom-accordion pb-2' key={application.ID}>
                                    <div className="item accordion-header py-3" onClick={() => toggleAccordion(idx)}>
                                        <div className="imageWrapper in">
                                            <img src={application?.Image ? application?.Image : SampleImage} alt="" className="imaged w64" />
                                            <div className="in position-relative">
                                                <div>
                                                    <div className="text-muted">{application.CareerProfile.degree}</div>
                                                    <div>{application.CandidateName}</div>
                                                    <div className='position-absolute text-muted d-flex align-items-center gap-1'>
                                                        {expandedItem === idx ?
                                                            <>
                                                                <span>Hide</span> <ion-icon name="chevron-up-outline"></ion-icon>
                                                            </> :
                                                            <>
                                                                <span>View More</span> <ion-icon name="chevron-down-outline"></ion-icon>
                                                            </>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="in">
                                            <div>
                                                <div className="text-muted">{application.PlacementDriveName}</div>
                                                {application.JobTitle}
                                            </div>
                                        </div>
                                        <div className="in">
                                            <div>
                                                <div className="text-muted">+<span>{application.Phone.slice(0, 2)}</span> {application.Phone.slice(2)}</div>
                                                {application.Education}
                                            </div>
                                        </div>
                                        <div className='d-flex align-items-center gap-1'>
                                            <Button variant="primary" onClick={() => handleContactApplicant(application.Phone)}>Contact</Button>
                                            <Button variant="primary" onClick={() => handleViewApplicationProfile(application?.CandidateID, application?.JobID)}>View</Button>
                                        </div>
                                    </div>
                                    <div className='accordion-body' style={{ display: expandedItem === idx ? 'block' : 'none' }}>
                                        <div className='pb-2'>
                                            {application.Degree && application.Degree.join(', ')}
                                        </div>
                                        <h4>Skills Required</h4>
                                        <div className='pb-2'>
                                            <h5>General Skills: </h5>
                                            {application.Skills?.GeneralSkills ?
                                                application.Skills.GeneralSkills.map((skill, index) => (
                                                    <div key={index} className="chip bg-transparent text-black ms-05 mb-1 skill-chip">
                                                        <span className="chip-label d-flex justify-content-center align-items-center gap-1"><Image className='tick-outlined' src={tick}/>{skill}</span>
                                                    </div>
                                                )) : <span className='text-muted'>No General Skill Found</span>
                                            }
                                        </div>
                                        <div>
                                            <h5>Professional Skills:</h5>
                                            {application.Skills?.ProfessionalSkills ?
                                                application.Skills.ProfessionalSkills.map((skill, index) => (
                                                    <div key={index} className="chip bg-transparent text-black ms-05 mb-1 skill-chip">
                                                        <span className="chip-label d-flex justify-content-center align-items-center gap-1"><Image className='tick-outlined' src={tick}/>{skill}</span>
                                                    </div>
                                                )) : <span className='text-muted'>No Professional Skill Found</span>
                                            }
                                        </div>
                                    </div>
                                </li>
                            );
                        })}
                </ul>
            }
        </>
    );
}

const mapStateToProps = ({ auth }) => {
    const { userData, role, fullName, ID, authToken } = auth;

    return {
        userData,
        role,
        fullName,
        ID,
        authToken
    };
}

export default connect(mapStateToProps)(ApplicantCard);
