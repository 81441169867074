import React, { useEffect, useState} from 'react'
import { Link, useNavigate } from "react-router-dom";
import { Row, Col, Card, Form, Button, Modal, InputGroup } from 'react-bootstrap'
import ShimmerEmpPd from '../Pages/Shimmer/ShimmerEmpPd';
import moment from 'moment';
import { fetchPlacementDrives } from '../../features/constants/apis'
// import EmpPlacementDrivesDetails from './EmpPlacementDrivesDetail';
import { connect } from 'react-redux';
import PlacementDriveCard from './Cards/PlacementDriveCard';
import { FaArrowLeft } from 'react-icons/fa';
import DataTable, { createTheme } from 'react-data-table-component';

const AdminPlacementDrives = ({ authToken, userData, role, fullName, ID }) => {

    // const [show, setShow] = useState(false);
    // const handleClose = () => setShow(false);
    // const handleShow = () => setShow(true);

    // console.log(ID)

    const [placementDrives, setPlacementDrives] = useState([]);

    const [searchedTerm, setSearchedTerm] = useState('')

    const navigate = useNavigate();
    // const [selectedDrive, setSelectedDrive] = useState(null);

    const handleViewDriveDetails = (drive) =>{
        // setSelectedDrive(drive);
        navigate(`/emp-placement-drive-details?drive=${drive}`)
    }

    const handleViewNewDriveForm = () => {
        navigate(`/new-placement-drive-form`)
    }

    useEffect(()=>{
        const fetchData = async () => {
            const data = await fetchPlacementDrives(authToken);
            setPlacementDrives(data.data);
            console.log(data)
        }
        fetchData();
    }, [authToken])


    const calculateCountdown = (fromDate) => {
        const now = moment();
        const fromDateMoment = moment(fromDate);
        const diffDays = fromDateMoment.diff(now, 'days');
        const formattedDate = moment(fromDate).format('DD MMMM YYYY');
        return {diffDays, formattedDate};
    };

    const fromDates = placementDrives.map(drive => drive.FromDate);

    const desiredPDColumns = [
        {
            name: 'ID',
            selector: (row) => row.ID,
            width: '100px',
            sortable: true
        },
        {
            name: 'Image',
            selector: (row) => <img src={row.Image} alt={row.Name} width={50} />,
            sortable: false,
            width: 'auto',
        },
        {
            name: 'Title',
            selector: (row) => <span style={{ color: "initial", cursor: "pointer" }} onClick={()=>handleViewDriveDetails(row.ID)}>{row.Title}</span>,
            width: '150px',
            wrap: true,
            sortable: true
        },
        {
            name: 'Criteria',
            selector: (row) => row.Criteria,
            width: '150px',
            wrap: true,
            sortable: true
        },
        {
            name: 'Qualification',
            selector: (row) => row.Qualification,
            width: '150px',
            wrap: true,
            sortable: true
        },
        {
            name: 'Location',
            selector: (row) => row.Location,
            width: '150px',
            sortable: true
        },
        {
            name: 'No. of Openings',
            selector: (row) => row.Jobs.length,
            width: '100px',
            wrap: true,
            sortable: true
        },
        {
            name: 'Salary (LPA)',
            selector: (row) => [row.ctcFrom, row.ctcTo].join('-'),
            width: '150px',
            sortable: true
        },
        {
            name: 'Date',
            selector: (row) => [row.FromDate, row.ToDate].join('-'),
            width: '200px',
            sortable: true
        }
    ];

    const filteredPD = placementDrives.filter(pd => pd.Title.toLowerCase().includes(searchedTerm.toLocaleLowerCase()));


    const convertToCSV = (data) => {
        const headers = Object.keys(data[0]);
        const rows = data.map(row => headers.map(header => `"${row[header]}"`).join(','));
        return [headers.join(','), ...rows].join('\n');
    };

    // Function to trigger the CSV download
    const downloadCSV = (csvData, filename) => {
        const blob = new Blob([csvData], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.setAttribute('hidden', '');
        a.setAttribute('href', url);
        a.setAttribute('download', filename);
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    };

    // Export table data to CSV
    const exportToCSV = () => {
        // Prepare the data for CSV export
        const dataToExport = placementDrives.map((row, index) => ({
            "ID": index + 1,
            "Image": row.Image,
            "Name": row.Name,
            "Title": row.Title,
            "Criteria": row.Criteria,
            "Qualification": row.Qualification,
            "Location": row.Location,
            "No. of Openings": row.Jobs.length,
            "Salary (LPA)": [row.ctcFrom, row.ctcTo].join('-'),
            "Date": [row.FromDate, row.ToDate].join('-'),
        }));
        const csv = convertToCSV(dataToExport);
        downloadCSV(csv, 'Jobs.csv');
    };


    return placementDrives.length === 0 ? <ShimmerEmpPd/> : 
    (
    
    <div id="appCapsule">
        {/* Placement Drive Card Starts */}
        <div className="section mb-2 mt-2 full">
            <div className="container pb-2">
                <Button variant="primary mb-2">
                    <Link to="/" type="submit" className='my-2 text-white'><FaArrowLeft style={{width: '15px', height: '15px', marginRight: "7px"}}/>Go to Dashboard</Link>
                </Button>
                <div className='d-flex justify-content-between'>
                    <h1 className='text-primary'>Current Placement Drives</h1>
                    {(role === 'Employer' || role === 'Admin') &&
                    <>
                        <Button className='add-btn btn-secondary' variant="primary" onClick={handleViewNewDriveForm}>Add</Button>
                    </>
                    }
                </div>
                <div className="d-flex justify-content-end">
                    <Row>
                        <Col xs="auto">
                            <Form.Control
                            type="text"
                            placeholder="Search"
                            className=" mr-sm-2"
                            onChange={(e)=> setSearchedTerm(e.target.value)}
                            />
                        </Col>
                        <Col xs="auto">
                            <Button variant="outline-warning" onClick={exportToCSV}>Export to CSV</Button>
                        </Col>
                    </Row>
                </div>
                { placementDrives.length === 0 ? (
                    <div className="text-center">No placement drive found</div>
                ) : (
                    <div>
                        {placementDrives.length > 0 && (
                        <DataTable
                            columns={desiredPDColumns}
                            data={filteredPD}
                            striped={true}
                            theme='solarized'
                        />
                        )}
                    </div>
                    // <Row xs={1} md={2} className="g-4">
                    //     {(role === 'Employer' || role === 'Admin') && placementDrives.length > 0 && (
                    //         placementDrives.map((drive, idx) => (
                    //             <>
                    //                 <PlacementDriveCard key={idx} drive={drive} handleViewDriveDetails={handleViewDriveDetails} />
                    //             </>
                    //         ))
                    //     )}
                    // </Row>
                )}
                
                {/* condition for shimmer effect */}
                {placementDrives.length === 0 && <ShimmerEmpPd />}
            </div>
        </div>
        {/* Placement Drive Card Ends */}

    </div>
  )
}

const mapStateToProps = ( { auth } ) => {
    const { userData, role, fullName, ID } = auth
  
    return {
        userData,
        role,
        fullName,
        ID
    }
}
  
export default connect(mapStateToProps) (AdminPlacementDrives)