import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { useLocation } from 'react-router-dom';
import { useParams, useNavigate } from 'react-router-dom';
import { Row, Col, Card, Button, Form, Accordion, Badge } from 'react-bootstrap'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import job5 from '../images/jobs/job6.png';
import { GetAllApplicationsOfJob, GetCandidates, createJobApplication, fetchJobData, getAllJobs } from '../../features/constants/apis';
import { connect } from 'react-redux';
import { setToken } from '../../features/auth/authSlice';
import { postNewJob } from '../../features/constants/apis';
import { setSelectedJob } from "../../features/data/selectedDriveSlice.js";
import ShimmerJobs from './Shimmer/ShimmerJobs.js';
import { Link } from 'react-router-dom';
import { IoFunnel, IoFunnelOutline } from "react-icons/io5";
import { FaArrowLeft } from 'react-icons/fa';
import DataTable, { createTheme } from 'react-data-table-component';
import AdminJobDetailModal from './Modals/AdminJobDetailModal';


function AdminJobs({ authToken, role, ID }) {

    const navigate = useNavigate();

    const [showJobModal, setShowJobModal] = useState(false);
    const [selectedJob, setSelectedJob] = useState([])

    const [jobID, setJobID] = useState(null)

    const [allJobs, setAllJobs] = useState([]);

    const [candidates, setCandidates] = useState([])

    const [applicationsOfUser, setApplicationsOfUser] = useState([])

    const [searchedTerm, setSearchedTerm] = useState('')

    useEffect(() => {
        const fetchAllJobs = async () => {
            const data = await getAllJobs(authToken);
            setAllJobs(data.data);
            console.log(data.data)
        }
        fetchAllJobs();
    }, [authToken]);

    const handleAddNewJob = () => {
        // setIsNewJob(true);

        console.log("true")
        navigate(`/new-job-form`)
    }

    const [activeKey, setActiveKey] = useState(null);

    const handleToggleAccordion = () => {
        setActiveKey(activeKey === '0' ? null : '0');
    };

    const showJobModalHandler = (jobID) => {
        console.log(jobID)
        setJobID(jobID)
        setShowJobModal(true)
        const jobs = allJobs.find(job => job.ID === jobID)
        setSelectedJob(jobs)
    }

    useEffect(() => {
        const fetchCandidates = async () => {
            const candidateData = await GetCandidates(authToken);
            setCandidates(candidateData)
            console.log(candidateData)
            console.log(candidateData?.data[8]?.SkillProfile?.GeneralSkills)
        }
        fetchCandidates()
    }, [authToken])

    const filteredCandidates = candidates?.data?.filter(candidate => {

        if (!selectedJob || (!candidate.SkillProfile.GeneralSkills && !candidate.SkillProfile.ProfessionalSkills)) {
            return false;
        }
        const generalSkillsMatch = selectedJob?.Skills?.GeneralSkills.length > 0 && 
        selectedJob?.Skills?.GeneralSkills.every(skill => candidate?.SkillProfile?.GeneralSkills?.includes(skill));
       
        const professionalSkillsMatch = selectedJob?.Skills?.ProfessionalSkills.length > 0 &&
        selectedJob?.Skills?.ProfessionalSkills.every(skill => candidate?.SkillProfile?.ProfessionalSkills?.includes(skill));

    
        return generalSkillsMatch && professionalSkillsMatch;
    });
    
    const filteredCandidateLength = filteredCandidates ? Object.keys(filteredCandidates).length : 0;
    console.log(filteredCandidateLength)


    useEffect(()=>{
        const fetchData = async () =>{
            const sendData = {
                employerID: ID,
                jobID: jobID
            }
            const data = await GetAllApplicationsOfJob(sendData, authToken);
            setApplicationsOfUser(data)
            console.log(data)
        }
        fetchData()
    }, [authToken, ID, jobID])



    const desiredJobsColumns = [
        {
            name: 'ID',
            selector: (row) => row.ID,
            width: '100px',
            sortable: true
        },
        {
            name: 'Image',
            selector: (row) => <img src={row.Image} alt={row.Name} width={50} />,
            sortable: false,
            width: 'auto',
        },
        {
            name: 'Title',
            selector: (row) => <span style={{ color: "initial", cursor: "pointer" }} onClick={() => showJobModalHandler(row.ID)}>{row.Title}</span>,
            width: '200px',
            wrap: true,
            sortable: true
        },
        {
            name: 'Company Name',
            selector: (row) => row.CompanyName,
            width: '150px',
            wrap: true,
            sortable: true
        },
        {
            name: 'Location',
            selector: (row) => row.Location,
            width: '150px',
            sortable: true
        },
        {
            name: 'Description',
            selector: (row) => row.Description,
            width: '300px',
            wrap: true
        },
        {
            name: 'Type',
            selector: (row) => row.Type,
            width: '100px',
            wrap: true
        },
        {
            name: 'Salary',
            selector: (row) => row.Ctc,
            width: '150px',
            wrap: true
        },
        {
            name: 'Experience (Years)',
            selector: (row) => row.Experience,
            width: '150px',
            wrap: true
        },
        {
            name: 'Required General Skills',
            selector: (row) => [row.Skills.GeneralSkills].join(', '),
            width: '300px',
            wrap: true
        },
        {
            name: 'Required Professional Skills',
            selector: (row) => [row.Skills.ProfessionalSkills].join(', '),
            width: '300px',
            wrap: true
        }
    ];

    const filteredJobs = allJobs.filter(job => job.Title.toLowerCase().includes(searchedTerm.toLocaleLowerCase()));

    console.log(filteredJobs)

    const convertToCSV = (data) => {
        const headers = Object.keys(data[0]);
        const rows = data.map(row => headers.map(header => `"${row[header]}"`).join(','));
        return [headers.join(','), ...rows].join('\n');
    };

    // Function to trigger the CSV download
    const downloadCSV = (csvData, filename) => {
        const blob = new Blob([csvData], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.setAttribute('hidden', '');
        a.setAttribute('href', url);
        a.setAttribute('download', filename);
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    };

    // Export table data to CSV
    const exportToCSV = () => {
        // Prepare the data for CSV export
        const dataToExport = allJobs.map((row, index) => ({
            "ID": index + 1,
            "Image": row.Image,
            "Name": row.Name,
            "Title": row.Title,
            "Company Name": row.CompanyName,
            "Location": row.Location,
            "Type": row.Type,
            "Salary": row.Ctc,
            "Experience (Years)": row.Experience,
            "Required General Skills": row.Skills.GeneralSkills && row.Skills.GeneralSkills.join(', '),
            "Required Professional Skills": row.Skills.ProfessionalSkills && row.Skills.ProfessionalSkills.join(', '),
        }));
        const csv = convertToCSV(dataToExport);
        downloadCSV(csv, 'Jobs.csv');
    };


    return (
        <div id="appCapsule">
            <section className="section full pt-4">
                <div className='container'>
                    <Button variant="primary mb-2">
                        <Link to="/" type="submit" className='my-2 text-white'><FaArrowLeft style={{ width: '15px', height: '15px', marginRight: "7px" }} />Go to Dashboard</Link>
                    </Button>
                    <Row>
                        <Col className='d-flex justify-content-between'>
                            <div>
                                <div className="d-flex align-items-center gap-2">
                                    <h1 className="title center text-dark" style={{ margin: "0" }}>Job Listing</h1>
                                    <div>
                                        <Link to="" onClick={handleToggleAccordion}>
                                            {activeKey === '0' ?
                                                <>
                                                    <Badge style={{ background: "#ababab" }} bg="none"><IoFunnel style={{ height: 'auto', width: '25px' }} /> Filter</Badge>
                                                </>
                                                :
                                                <>
                                                    <Badge style={{ background: "#ababab" }} bg="none"><IoFunnelOutline style={{ height: 'auto', width: '25px' }} /> Filter</Badge>
                                                </>

                                            }
                                        </Link>
                                    </div>

                                </div>
                                {role === "Candidate" ? <div className="section-title ps-0 text-dark">Top jobs matching your profile</div> : <div className="section-title ps-0 text-dark">Your Jobs</div>}
                                {/* <p className='text-dark'>We have considered your profi  le and the inputs given by you and have pulled some of the jobs from our recruitement partners.</p> */}
                            </div>
                            <div>
                                {(role === 'Employer' || role === 'Admin') &&
                                    <Button className='add-btn btn-secondary' variant="primary" onClick={handleAddNewJob}>Add</Button>
                                }
                            </div>
                        </Col>
                    </Row>
                </div>
            </section>

            <section className="container section full mt-2 mb-4">
                <div className='container'>
                    <Row>
                        <Col md="12">
                            {/* <div className='top-sec-heading'>
                            <h4>Filter By</h4>
                        </div> */}
                            <Accordion activeKey={activeKey}>
                                <Accordion.Item eventKey="0">
                                    {/* <Accordion.Header><h2>Filter By</h2></Accordion.Header> */}
                                    <Accordion.Body>
                                        <Row>
                                            <Col md="4">
                                                <Accordion style={{ borderTop: 'none' }}>
                                                    <Accordion.Item eventKey="0">
                                                        <Accordion.Header>Location</Accordion.Header>
                                                        <Accordion.Body>
                                                            <Form.Select aria-label="Default select example">
                                                                <option>Select your location</option>
                                                                <option value="1">Mumbai</option>
                                                                <option value="2">Navi Mumbai</option>
                                                                <option value="3">Pune</option>
                                                            </Form.Select>
                                                        </Accordion.Body>
                                                    </Accordion.Item>
                                                </Accordion>
                                            </Col>
                                            <Col md="4">
                                                <Accordion>
                                                    <Accordion.Item eventKey="1">
                                                        <Accordion.Header>Job Type</Accordion.Header>
                                                        <Accordion.Body>
                                                            <Form className='wide-block py-3'>
                                                                <Form.Check
                                                                    label="Full time"
                                                                    name="group1"
                                                                    type='radio'
                                                                    id={`job-type-1`}
                                                                />
                                                                <Form.Check
                                                                    label="Part time"
                                                                    name="group1"
                                                                    type='radio'
                                                                    id={`job-type-2`}
                                                                />
                                                                <Form.Check
                                                                    label="Freelance"
                                                                    name="group1"
                                                                    type='radio'
                                                                    id={`job-type-3`}
                                                                />
                                                            </Form>
                                                        </Accordion.Body>
                                                    </Accordion.Item>
                                                </Accordion>
                                            </Col>
                                            <Col md="4">
                                                <Accordion>
                                                    <Accordion.Item eventKey="2">
                                                        <Accordion.Header>Experience</Accordion.Header>
                                                        <Accordion.Body>
                                                            <Form className='wide-block py-3'>
                                                                <Form.Check
                                                                    label="Fresher"
                                                                    name="group2"
                                                                    type='radio'
                                                                    id={`xp-1`}
                                                                />
                                                                <Form.Check
                                                                    label="Intermediate"
                                                                    name="group2"
                                                                    type='radio'
                                                                    id={`xp-2`}
                                                                />
                                                                <Form.Check
                                                                    label="Expert"
                                                                    name="group2"
                                                                    type='radio'
                                                                    id={`xp-3`}
                                                                />
                                                            </Form>
                                                        </Accordion.Body>
                                                    </Accordion.Item>
                                                </Accordion>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md="4">
                                                <Accordion>
                                                    <Accordion.Item eventKey="3">
                                                        <Accordion.Header>Salary</Accordion.Header>
                                                        <Accordion.Body>
                                                            <Form.Label>Range</Form.Label>
                                                            <Form.Range />
                                                            <Form className='wide-block py-3'>
                                                                <Form.Check
                                                                    label="3-5 LPA"
                                                                    name="group3"
                                                                    type='radio'
                                                                    id={`salary-1`}
                                                                />
                                                                <Form.Check
                                                                    label="5-7 LPA"
                                                                    name="group3"
                                                                    type='radio'
                                                                    id={`salary-2`}
                                                                />
                                                                <Form.Check
                                                                    label="7-10 LPA"
                                                                    name="group3"
                                                                    type='radio'
                                                                    id={`salary-3`}
                                                                />
                                                            </Form>
                                                        </Accordion.Body>
                                                    </Accordion.Item>
                                                </Accordion>
                                            </Col>
                                            <Col md="4">
                                                <Accordion>
                                                    <Accordion.Item eventKey="4">
                                                        <Accordion.Header>Category</Accordion.Header>
                                                        <Accordion.Body>
                                                            <Form className='wide-block py-3'>
                                                                <Form.Check
                                                                    label="Developer"
                                                                    name="group3"
                                                                    type='checkbox'
                                                                    id={`category-1`}
                                                                />
                                                                <Form.Check
                                                                    label="Finance"
                                                                    name="group3"
                                                                    type='checkbox'
                                                                    id={`category-2`}
                                                                />
                                                                <Form.Check
                                                                    label="Design"
                                                                    name="group3"
                                                                    type='checkbox'
                                                                    id={`category-3`}
                                                                />
                                                            </Form>
                                                        </Accordion.Body>
                                                    </Accordion.Item>
                                                </Accordion>
                                            </Col>
                                            <Col md="4">
                                                <Accordion>
                                                    <Accordion.Item eventKey="5">
                                                        <Accordion.Header>Tags</Accordion.Header>
                                                        <Accordion.Body>
                                                            <Form className='wide-block py-3'>
                                                                <Form.Check
                                                                    label="Developer"
                                                                    name="group3"
                                                                    type='checkbox'
                                                                    id={`Tags-1`}
                                                                />
                                                                <Form.Check
                                                                    label="Finance"
                                                                    name="group3"
                                                                    type='checkbox'
                                                                    id={`Tags-2`}
                                                                />
                                                                <Form.Check
                                                                    label="Design"
                                                                    name="group3"
                                                                    type='checkbox'
                                                                    id={`Tags-3`}
                                                                />
                                                            </Form>
                                                        </Accordion.Body>
                                                    </Accordion.Item>
                                                </Accordion>
                                            </Col>
                                        </Row>
                                        <div className='job-filter-box'>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="12 pt-4">
                            <div className='top-sec-heading d-flex pb-4 justify-content-between'>
                                <h4>All {allJobs.length} jobs found</h4>
                                <Row>
                                    <Col xs="auto">
                                        <Form.Control
                                        type="text"
                                        placeholder="Search"
                                        className=" mr-sm-2"
                                        onChange={(e)=> setSearchedTerm(e.target.value)}
                                        />
                                    </Col>
                                    <Col xs="auto">
                                        <Button variant="outline-warning" onClick={exportToCSV}>Export to CSV</Button>
                                    </Col>
                                </Row>
                            </div>
                            <div className='pt-2'>
                                {allJobs.length > 0 && (
                                    <DataTable
                                        columns={desiredJobsColumns}
                                        data={filteredJobs}
                                        striped={true}
                                        theme='solarized'
                                    />
                                )}
                            </div>
                        </Col>
                    </Row>
                    <AdminJobDetailModal
                        show={showJobModal}
                        onHide={() => setShowJobModal(false)}
                        jobData={selectedJob}
                        filteredCandidates={filteredCandidates}
                        filteredCandidateLength={filteredCandidateLength}
                        applicationsOfUser={applicationsOfUser}
                    />
                </div>
            </section>
        </div>
    )
}
const mapStateToProps = ({ auth }) => {
    const { userData, role, fullName, ID, authToken } = auth

    return {
        userData,
        role,
        fullName,
        ID,
        authToken
    }
}

const mapDispatchToProps = {
    setToken,
    setSelectedJob
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminJobs)


// job details modal
function jobDetailModal(props) {

}