import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    locationModalVisible: false,
    allCities: [
      { 'city': 'Mumbai'},
      { 'city': 'Pune'},
      { 'city': 'Bangalore'},
      { 'city': 'Kolkata'},
      { 'city': 'Delhi'},
      { 'city': 'Chennai'}     
    ],
    selectedCity: "Mumbai"
}

export const locationModalSlice = createSlice({
  name: 'locationModal',
  initialState,
  reducers: {
    showLocationModal: (state, action) => {
      const {
        locationModalVisible
      } = action.payload
      state.locationModalVisible=locationModalVisible
    },
    hideLocationModal: (state, action) => {
        state.locationModalVisible=false
    },
    setSelectedCity: (state, action) => {
      const {
        selectedCity
      } = action.payload
      state.selectedCity=selectedCity
    }
  }
})

export const { showLocationModal, hideLocationModal, setSelectedCity } = locationModalSlice.actions;


export default locationModalSlice.reducer
