import React, { useEffect, useState } from 'react'
import { Table } from 'react-bootstrap'
import { getAllCourseApplications } from '../../features/constants/apis'
import { Link } from 'react-router-dom'
import { FaArrowLeft } from 'react-icons/fa'

const CourseApplications = () => {

    const [courseApplications, setCourseApplications] = useState([])

    useEffect(() =>{
        const fetchCourseApplications = async() =>{
            const data = await getAllCourseApplications();
            // console.log(data)
            setCourseApplications(data.data)
        }
        fetchCourseApplications();
    }, [])
  return (
    <div id="appCapsule">
        <div className='section full py-4'>
            <div className='container'>
            <Link to="/" type="submit" className='my-2 pe-2' ><FaArrowLeft style={{width: '23px', height: '23px'}}/></Link>
            <h1 className='text-primary'>Course Applications</h1> 
            <Table striped bordered hover>
                <thead>
                    <tr>
                    <th>#</th>
                    <th>CandidateName</th>
                    <th>CourseTitle</th>
                    <th>CourseID</th>
                    <th>Mobile</th>
                    </tr>
                </thead>
                <tbody>
                {courseApplications.map((item, index) => (
                    <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{item.CandidateName}</td>
                        <td>{item.CourseTitle}</td>
                        <td>{item.CourseID}</td>
                        <td>{item.CandidatePhone}</td>
                    </tr>
                ))}
                    
                </tbody>
                </Table>
            </div>
        </div>
    </div>
  )
}

export default CourseApplications