import React, { Fragment, useEffect } from 'react'
import { connect } from 'react-redux'
import { BrowserRouter, Routes, Route} from 'react-router-dom'
import { setToken } from './features/auth/authSlice'
import Authentication from './components/Pages/Authentication'
import Signup from './components/Pages/Signup'
import Layout from './components/Pages/Layout'
import Load from './components/Pages/Load'
import ComingSoon from './components/Pages/ComingSoon'
import Dashboard from './components/Pages/Dashboard'
import Certificates from './components/Pages/Certificates'
import Joblist from './components/Pages/Joblist'
import BuildCareer from './components/Pages/BuildCareer'
import jwt_decode from 'jwt-decode'
import GetStarted from './components/Pages/GetStarted'
import Header from './components/layout/Header/Header'
// import Footer from './components/layout/Footer/Footer'
import Summary from './components/Pages/Summary'
import CompleteProfile from './components/Pages/CompleteProfile'
import EmployerDashboard from './components/Pages/EmployerDashboard'
import EmpPlacementDrives from './components/Pages/EmpPlacementDrives'
import Hiring from './components/Pages/Hiring'
// import { relativeTimeRounding } from 'moment'
import EmpPlacementDrivesDetails from './components/Pages/EmpPlacementDrivesDetail'
import EmpPlacementJobsDetails from './components/Pages/EmpPlacementJobsDetails'
// import { exportDefaultDeclaration } from '@babel/types'
import NewPlacementDriveForm from './components/Pages/Forms/NewPlacementDriveForm'
import CertificationDetails from './components/Pages/CertificationDetails'
import CandidateProfile from './components/Pages/CandidateProfile'
import EmployerProfile from './components/Pages/EmployerProfile'
import LoaderState from './components/Pages/LoaderState'
import NewPlacementDriveJobFrom from './components/Pages/Forms/NewPlacementDriveJobFrom'
import NewJobForm from './components/Pages/Forms/NewJobForm'
import JobDetails from './components/Pages/JobDetails'
// import CertificateIframe from './components/Pages/CertificateLandingPage'
import CertificateLandingPage from './components/Pages/CertificateLandingPage'
import CourseApplications from './components/Pages/CourseApplications'
import CertificateIframe from './components/Pages/CertificationIframe'
import AdminDashboard from './components/Pages/AdminDashboard'
import AdminCertifications from './components/Pages/AdminCertifications'
import AdminJobs from './components/Pages/AdminJobs'
import AdminPlacementDrives from './components/Pages/AdminPlacementDrives'
import AdminUsers from './components/Pages/AdminUsers'
import ChatBot from './components/Pages/ChatBot'
import AnimatedLoader from './components/Pages/AnimatedLoader'
import { useState } from 'react'
import EmployerJobs from './components/Pages/EmployerJobs'
import EmpApplicantsList from './components/Pages/EmpApplicantsList'

const App = ({
  authToken,
  setToken,
  role,
  showRegistrationStep
}) => {
  // let navigate = useNavigate()
  // const navigateTo = (URL) => {
  //   navigate(URL)
  //   // document.getElementById('modalCloseButton').click()
  // }

  // chat bot
  const [isChatBotOpen, setIsChatBotOpen] = useState(false);

  const toggleChatBot = () => {
    setIsChatBotOpen(!isChatBotOpen);
  };

  const validateToken = () => {
    if (authToken) {
      return true
    } else {
      const token = localStorage.getItem('token')
      const tokenData = (token) ? jwt_decode(token) : false
      if (tokenData && new Date(tokenData.exp * 1000) > new Date()) {
        if (tokenData.userInfo.hasOwnProperty('ID') && tokenData.userInfo.hasOwnProperty('RoleID')) {
          setToken({ authToken: token })
          console.log('Rohit')
          return true
        } else {
          // localStorage.clear()
          console.log('Hussain')
          return false
        }
      } else {
        // localStorage.clear()
        console.log('Nikhil')
        return false
      }
    }
  }
  const userLoggedIn = validateToken()
  console.log(userLoggedIn)
  
  useEffect(() =>{
    console.log(showRegistrationStep)
    if (showRegistrationStep !== null) {
    }
  }, [showRegistrationStep])
  

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
        {!userLoggedIn &&
        <>
          {/* {(showRegistrationStep === 1 || showRegistrationStep === 2 || showRegistrationStep === 3)?
            <Fragment>
              <Route path='complete-profile' element={<ViewCompleteProfile />} />
            </Fragment>
            : */}
            <Fragment>
              <Route path="*" element={<Auth />} />
              <Route path="login" element={<Auth />} />
              <Route path='signup' element={<SignUp />} />
              <Route path="getStarted" element={<GetStartedView />} />
              <Route path='complete-profile' element={<ViewCompleteProfile />} />
              <Route path='loader' element={<LoaderPageView />} />
              <Route path='welcome' element={<AnimatedLoaderView/>} />
              {/* <Route path="getStartedLogin" element={<GetStartedLoginView />} /> */}
            </Fragment>
          {/* } */}
        </>
        }
        {userLoggedIn &&
          <>
          {role === 'Candidate' &&
            <Fragment>
              <Route path="/" element={<DashboardView />} />
              <Route path="login" element={<Auth />} />
              <Route path='signup' element={<SignUp />} />
              <Route path="getStarted" element={<GetStartedView />} />
              <Route path='coming-soon' element={<ComingSoonView />} />
              <Route path="dashboard" element={<DashboardView />} />
              <Route path='certification-and-trainings' element={<CertificatesView />} />
              <Route path='jobs' element={<JoblistView/>} />
              <Route path="build-career" element={<BuildCareerView />} />
              <Route path="load" element={<SelectWebsiteList />} />
              <Route path="summary" element={<SummaryView />} />
              <Route path="*" element={<DashboardView />} />
              <Route path='complete-profile' element={<ViewCompleteProfile />} />
              <Route path='placement-drives' element={<EmpPlacementDrivesView />} />
              <Route path='emp-placement-drive-details' element={<EmpPlacementDrivesDetailsView />} />
              <Route path='emp-placement-job-details' element={<EmpPlacementJobsDetailsView />} />
              <Route path='certification-details' element={<CertificationDetailsView />} />
              <Route path='candidate-profile' element={<CandidateProfileView />} />
              <Route path='loader' element={<LoaderPageView />} />
              <Route path='landing-page' element={<CertificateLandingPageView/>} />
              <Route path='certification-iframe' element={<CertificateIframeView/>} />
              <Route path="chat-bot" element={<ChatBot />} />
              <Route path='welcome' element={<AnimatedLoaderView/>} />
            </Fragment>
          }
          {role === 'Employer' &&
            <Fragment>
              <Route path="/" element={<EmployerDashboardView />} />
              <Route path="login" element={<Auth />} />
              <Route path='signup' element={<SignUp />} />
              <Route path="getStarted" element={<GetStartedView />} />
              <Route path='coming-soon' element={<ComingSoonView />} />
              <Route path="load" element={<SelectWebsiteList />} />
              <Route path="summary" element={<SummaryView />} />
              <Route path='employer-dashboard' element={<EmployerDashboardView />} />
              <Route path='jobs' element={<JoblistView/>} />
              <Route path='emp-placement-drives' element={<EmpPlacementDrivesView />} />
              <Route path='emp-placement-drive-details' element={<EmpPlacementDrivesDetailsView />} />
              <Route path='new-placement-drive-form' element={<NewPlacementDriveFormView />} />
              <Route path='emp-placement-job-details' element={<EmpPlacementJobsDetailsView />} />
              <Route path='new-placement-drive-job-form' element={<NewPlacementDriveJobFormView />} />
              <Route path='job-details' element={<JobDetailsView />} />
              <Route path='new-job-form' element={<NewJobFormView />} />
              <Route path='hirings' element={<HiringView/>} />
              <Route path='certification-details' element={<CertificationDetailsView />} />
              <Route path='candidate-profile' element={<CandidateProfileView />} />
              <Route path='loader' element={<LoaderPageView />} />
              <Route path='landing-page' element={<CertificateLandingPageView />} />
              <Route path='employer-profile' element={<EmployerProfileView />} />
              <Route path='certification-iframe' element={<CertificateIframeView/>} />
              <Route path='welcome' element={<AnimatedLoaderView/>} />
              <Route path='all-emp-jobs' element={<EmployerJobsview />} />
              <Route path='all-emp-applications' element={<EmpApplicantsListView/>} />
        
            </Fragment>
          }
          {role === 'Admin' &&
            <Fragment>
              <Route path="/" element={<AdminDashboardView />} />
              <Route path="login" element={<Auth />} />
              <Route path='signup' element={<SignUp />} />
              <Route path="getStarted" element={<GetStartedView />} />
              <Route path='coming-soon' element={<ComingSoonView />} />
              <Route path="admin-dashboard" element={<AdminDashboardView />} />
              <Route path='admin-certifications' element={<AdminCertificationsView />} />
              <Route path='admin-jobs' element={<AdminJobsView />} />
              <Route path='admin-placement-drives' element={<AdminPlacementDrivesView />} />
              <Route path='admin-users' element={<AdminUsersView />} />
              <Route path='certification-and-trainings' element={<CertificatesView />} />
              <Route path='jobs' element={<JoblistView/>} />
              <Route path="build-career" element={<BuildCareerView />} />
              <Route path="chat-bot" element={<ChatBot />} />
              <Route path='complete-profile' element={<ViewCompleteProfile />} />
              <Route path="load" element={<SelectWebsiteList />} />
              <Route path="summary" element={<SummaryView />} />
              <Route path="*" element={<AdminDashboardView />} />
              <Route path='employer-dashboard' element={<EmployerDashboardView />} />
              <Route path='emp-placement-drives' element={<EmpPlacementDrivesView />} />
              <Route path='emp-placement-drive-details' element={<EmpPlacementDrivesDetailsView />} />
              <Route path='emp-placement-job-details' element={<EmpPlacementJobsDetailsView />} />
              <Route path='new-placement-drive-form' element={<NewPlacementDriveFormView />} />
              <Route path='new-placement-drive-job-form' element={<NewPlacementDriveJobFrom />} />
              <Route path='hirings' element={<HiringView/>} />
              <Route path='certification-details' element={<CertificationDetailsView />} />
              <Route path='candidate-profile' element={<CandidateProfileView />} />
              <Route path='loader' element={<LoaderPageView />} />
              <Route path='job-details' element={<JobDetailsView />} />
              <Route path='new-job-form' element={<NewJobFormView />} />
              <Route path='landing-page' element={<CertificateLandingPageView />} />
              <Route path='employer-profile' element={<EmployerProfileView />} />
              <Route path='course-applications' element={<CourseApplicationsView />} />
              <Route path='certification-iframe' element={<CertificateIframeView/>} />
              <Route path='welcome' element={<AnimatedLoaderView/>} />
            </Fragment>
          }
          </>
        }
        </Routes>
        {/* <button className="chatbot-toggle-button" onClick={toggleChatBot}>
          {isChatBotOpen ? '✖' : '💬'}
        </button> */}

        {/* {isChatBotOpen && <ChatBot />} */}
      </BrowserRouter>
    </div>
  );
}

function Auth() {
  return (
    <div className="App pt-5">
      <Authentication></Authentication>
    </div>
  )
}

function SignUp() {
  return (
    <div className='App pt-5'>
      <Signup></Signup>
    </div>
  )
}

function SelectWebsiteList() {
  return (
    <div className="App">
      <Load></Load>
    </div>
  )
}

function ComingSoonView() {
  return (
    <div className='App'>
      <Header></Header>
        <ComingSoon></ComingSoon>
    </div>
  )
}

function DashboardView() {
  return (
    <div className="App">
      {/* <Layout> */}
      <Header></Header>
        <Dashboard></Dashboard>
      {/* <Footer></Footer> */}
      {/* </Layout> */}
    </div>
  )
}

function CertificatesView() {
  return (
    <div className='App'>
      <Header></Header>
        <Certificates></Certificates>
      {/* <Footer></Footer> */}
    </div>
  )
}

function JoblistView() {
  return (
    <div className='App'>
      <Header></Header>
        <Joblist></Joblist>
      {/* <Footer></Footer> */}
    </div>
  )
}

function BuildCareerView() {
  return (
    <div className='App'>
      <Header></Header>
        <BuildCareer></BuildCareer>
      {/* <Footer></Footer> */}
    </div>
  )
}

function ChatBotView() {
  return (
    <div className='App'>
      {/* <Header></Header> */}
        <ChatBot></ChatBot>
      {/* <Footer></Footer> */}
    </div>
  )
}

function ViewCompleteProfile() {
  return (
    <div className='App'>
      {/* <Header></Header> */}
        <CompleteProfile></CompleteProfile>
      {/* <Footer></Footer> */}
    </div>
  )
}

function GetStartedView() {
  return (
    <div className="App">
    {/* <Layout> */}
      <GetStarted></GetStarted>
    {/* </Layout> */}
    </div>
  )
}

function SummaryView() {
  return (
    <div className="App">
    <Layout>
      <Summary></Summary>
    </Layout>
    </div>
  )
}

function EmployerDashboardView() {
  return (
    <div className='App'>
      <Header></Header>
        <EmployerDashboard></EmployerDashboard>
    </div>
  )
}

function EmpPlacementDrivesView() {
  return (
    <div className='App'>
      <Header></Header>
        <EmpPlacementDrives></EmpPlacementDrives>
    </div>
  )
}

function EmpPlacementDrivesDetailsView() {
  return (
    <div className='App'>
      <Header></Header>
        <EmpPlacementDrivesDetails></EmpPlacementDrivesDetails>
    </div>
  )
}

function NewPlacementDriveFormView() {
  return (
    <div className='App'>
      <Header/>
      <NewPlacementDriveForm></NewPlacementDriveForm>
    </div>
  )
}

function NewPlacementDriveJobFormView() {
  return (
    <div className='App'>
      <Header/>
      <NewPlacementDriveJobFrom></NewPlacementDriveJobFrom>
    </div>
  )
}

function JobDetailsView() {
  return (
    <div className='App'>
      <Header></Header>
        <JobDetails></JobDetails>
    </div>
  )
}

function NewJobFormView() {
  return (
    <div className='App'>
      <Header/>
      <NewJobForm></NewJobForm>
    </div>
  )
}

function EmpPlacementJobsDetailsView() {
  return (
    <div className='App'>
      <Header></Header>
        <EmpPlacementJobsDetails></EmpPlacementJobsDetails>
    </div>
  )
}

function HiringView() {
  return (
    <div className='App'>
      <Header></Header>
        <Hiring></Hiring>
    </div>
  )
}

function CertificationDetailsView() {
  return (
    <div className='App'>
      <Header></Header>
        <CertificationDetails></CertificationDetails>
    </div>
  )
}

function CandidateProfileView() {
  return (
    <div className='App'>
      <Header></Header>
        <CandidateProfile></CandidateProfile>
    </div>
  )
}

function LoaderPageView() {
  return (
    <div className='App'>
      <LoaderState></LoaderState>
    </div>
  )
}
function AnimatedLoaderView() {
  return (
    <div className='App'>
      <AnimatedLoader></AnimatedLoader>
    </div>
  )
}

function CertificateLandingPageView() {
  return (
    <div className="App">
      <Header/>
      <CertificateLandingPage/>
    </div>
  )
}

function EmployerProfileView() {
  return (
    <div className='App'>
      <Header></Header>
      <EmployerProfile></EmployerProfile>
    </div>
  )
}

function CourseApplicationsView() {
  return (
    <div className='App'>
      <Header></Header>
      <CourseApplications></CourseApplications>
    </div>
  )
}

function CertificateIframeView() {
  return (
    <div className='App'>
      <Header></Header>
      <CertificateIframe></CertificateIframe>
    </div>
  )
}

function AdminDashboardView(){
  return (
    <div className='App'>
      <Header></Header>
      <AdminDashboard></AdminDashboard>
    </div>
  )
}

function AdminCertificationsView(){
  return (
    <div className='App'>
      <Header></Header>
      <AdminCertifications></AdminCertifications>
    </div>
  )
}

function AdminJobsView(){
  return (
    <div className='App'>
      <Header></Header>
      <AdminJobs></AdminJobs>
    </div>
  )
}

function  AdminPlacementDrivesView(){
  return (
    <div className='App'>
      <Header></Header>
      <AdminPlacementDrives></AdminPlacementDrives>
    </div>
  )
}

function AdminUsersView(){
  return (
    <div className='App'>
      <Header></Header>
      <AdminUsers></AdminUsers>
    </div>
  )
}

function EmployerJobsview(){
  return(
    <div className='App'>
      <Header></Header>
      <EmployerJobs></EmployerJobs>
    </div>
  )
}

function EmpApplicantsListView(){
  return(
    <div className='App'>
      <Header></Header>
      <EmpApplicantsList></EmpApplicantsList>
    </div>
  )
}

const mapStateToProps = ({ auth }) => {
  const {
    authToken,
    role,
    showRegistrationStep
  } = auth

  return {
    authToken,
    role,
    showRegistrationStep
  }
}

const mapDispatchToProps = {
  setToken
}

export default connect(mapStateToProps, mapDispatchToProps)(App)