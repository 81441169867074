import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from "react-router-dom";
import { Row, Col, Card, Form, Button, Modal, InputGroup } from 'react-bootstrap'
import ShimmerEmpPd from '../Pages/Shimmer/ShimmerEmpPd';
import job5 from '../images/jobs/comp5.webp'
import moment from 'moment';
import { fetchJobData, fetchPlacementDrives, getAllJobs } from '../../features/constants/apis'
// import EmpPlacementDrivesDetails from './EmpPlacementDrivesDetail';
import { connect } from 'react-redux';
import { GoDotFill } from 'react-icons/go';
import PlacementDriveCard from './Cards/PlacementDriveCard';
import { FaArrowLeft } from 'react-icons/fa';

const EmpPlacementDrives = ({ authToken, userData, role, fullName, ID }) => {

    // const [show, setShow] = useState(false);
    // const handleClose = () => setShow(false);
    // const handleShow = () => setShow(true);

    // console.log(ID)

    const [placementDrives, setPlacementDrives] = useState([]);
    const [pdJobs, setPdJobs] = useState([])
    const [filteredPD, setFilteredPD] = useState([])

    const navigate = useNavigate();
    // const [selectedDrive, setSelectedDrive] = useState(null);

    const handleViewDriveDetails = (drive) => {
        // setSelectedDrive(drive);
        navigate(`/emp-placement-drive-details?drive=${drive}`)
    }

    const handleViewNewDriveForm = () => {
        navigate(`/new-placement-drive-form`)
    }

    useEffect(() => {
        const fetchData = async () => {
            const data = await fetchPlacementDrives(authToken);
            console.log(authToken)
            setPlacementDrives(data.data);
            setFilteredPD(data.data.filter(drive => drive.EmployerID === ID))
            console.log(data.data.filter(drive => drive.EmployerID === ID))
        }
        fetchData();
    }, [authToken])

    console.log(filteredPD)
    console.log(placementDrives)


    // const fromDates = placementDrives.map(drive => drive.FromDate);
    // console.log(fromDates)

    // console.log(placementDrives[1]?.Jobs[0]?.minCtcFrom)

    return (

        <div id="appCapsule">
            {/* Placement Drive Card Starts */}
            <div className="section mb-2 mt-2 full">
                <div className="container pb-2">
                    <Link to="/" type="submit" className='my-2 text-white'>
                        <Button variant="primary mb-2">
                            <FaArrowLeft style={{ width: '15px', height: '15px', marginRight: "7px" }} />Go to Dashboard
                        </Button>
                    </Link>
                    <div className='d-flex justify-content-between'>
                        <h1 className='text-primary'>Current Placement Drives</h1>
                        {(role === 'Employer' || role === 'Admin') &&
                            <>
                                <Button className='add-btn btn-secondary' variant="primary" onClick={handleViewNewDriveForm}>Add</Button>
                            </>
                        }
                    </div>

                    <div className="ms-05">
                        {(role === 'Employer' || role === 'Admin') &&
                            filteredPD.length === 0 ? (
                            filteredPD !== null ? (
                                <ShimmerEmpPd />
                            ) : (
                                <div> No Placement Drive Found</div>
                            )
                        ) : (
                            <Row xs={1} md={2} className="g-4">
                                {(role === 'Employer' || role === 'Admin') && filteredPD.length > 0 && (
                                    filteredPD.map((drive, idx) => (
                                        drive.length === 0 ? <p>No Placement Drive Found</p> :
                                            <Col key={idx} xs={12} md={6} lg={6}>
                                                <PlacementDriveCard drive={drive} handleViewDriveDetails={handleViewDriveDetails} />
                                            </Col>
                                    ))
                                )}
                            </Row>
                        )
                        }
                        {role === 'Candidate' &&
                            placementDrives.length === 0 ? (
                            placementDrives !== null ? (
                                <ShimmerEmpPd />
                            ) : (
                                <div></div>
                            )
                        ) : (
                            <Row xs={1} md={2} className="g-4 mt-2">
                                {role === 'Candidate' && placementDrives.length > 0 &&
                                    placementDrives.map((drive, idx) => (
                                        <Col key={idx} xs={12} md={6} lg={6}>
                                            <PlacementDriveCard drive={drive} handleViewDriveDetails={handleViewDriveDetails} />
                                        </Col>
                                    ))
                                }
                            </Row>
                        )
                        }
                        {/* <Row xs={1} md={2} className="g-4">
                        {role === 'Candidate' && placementDrives.length > 0 &&
                                        placementDrives.map((drive, idx) => (
                                            <Col key={idx} xs={12} md={6} lg={6}>
                                                <PlacementDriveCard drive={drive} handleViewDriveDetails={handleViewDriveDetails} />
                                            </Col>
                                        ))
                                    }
                                    </Row> */}
                    </div>

                    {/* condition for shimmer effect */}
                    {/* {placementDrives.length === null && <ShimmerEmpPd />} */}
                </div>
            </div>
            {/* Placement Drive Card Ends */}

        </div>
    )
}

const mapStateToProps = ({ auth }) => {
    const { userData, role, fullName, ID, authToken } = auth

    return {
        userData,
        role,
        fullName,
        ID,
        authToken
    }
}

export default connect(mapStateToProps)(EmpPlacementDrives)
