import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Modal, Button, Form } from 'react-bootstrap'
import { hideLocationModal, setSelectedCity } from '../../../features/popup/locationModalSlice'

const LocationModal = ({
    locationModalVisible,
    hideLocationModal,
    setSelectedCity,
    allCities
}) => {
    const [citySelect,setCitySelect] = useState("")

    const handleDropdownCityChange = (city) => {
        setSelectedCity({selectedCity:city})
        setCitySelect(city)
        hideLocationModal()
        console.log(city)
    }
    return (
        <div>
            <Modal show={locationModalVisible} onHide={() => {hideLocationModal()}}>
                <Modal.Header closeButton>
                <Modal.Title>Select Location</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Select className='selectLocation' defaultValue={citySelect} onChange={(e) => {handleDropdownCityChange(e.target.value)}}>
                        {allCities.map((item) => {
                            return <option value={item.city} selected={item.city}>{item.city}</option>
                        })}
                        {/* <option value="Mumbai">Mumbai</option>
                        <option value="Delhi">Delhi</option>
                        <option value="Bangalore">Bangalore</option>
                        <option value="Kolkata">Kolkata</option>
                        <option value="Chennai">Chennai</option>
                        <option value="Hyderabad">Hyderabad</option> */}
                    </Form.Select>
                </Modal.Body>
                <Modal.Footer>
                {/* <Button variant="primary" onClick={() => {hideLocationModal()}}>
                    Save Changes
                </Button> */}
                </Modal.Footer>
            </Modal>
        </div>
    )
}

const mapStateToProps = ({locationModal}) => {
    const {locationModalVisible, allCities} = locationModal
    return {
        locationModalVisible,
        allCities
    }
  }
  
  const mapDispatchToProps = {
    hideLocationModal,
    setSelectedCity
  }  

export default connect(mapStateToProps, mapDispatchToProps) (LocationModal)
