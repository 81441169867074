import React, { useState, useEffect } from 'react'
import { GetAllUsers } from '../../features/constants/apis'
import { Row, Col, Card, Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { FaArrowLeft } from 'react-icons/fa'
import DataTable, { createTheme } from 'react-data-table-component';
import { connect } from 'react-redux'

const AdminUsers = ({authToken}) => {

    const [allUsers, setAllUsers] = useState([]);

    useEffect(()=>{
        const fetchData = async () => {
            const data = await GetAllUsers(authToken);
            setAllUsers(data.data);
            console.log(data.data)
        }
        fetchData();
    }, [authToken])

    const desiredColumns = [
        {
            name: 'ID',
            selector: (row) => row.ID,
            width: '100px',
            sortable: true,
        },
        {
            name: 'Created On',
            selector: (row) => {
                const createdOnString = row.CreatedOn;
                const dateObject = new Date(createdOnString);
                return dateObject.toLocaleDateString('en-GB');
            },
            width: '120px',
            sortable: true
        },
        {
            name: 'Role',
            selector: (row) => row.RoleID,
            width: '100px',
            sortable: true
        },
        // {
        //     name: 'Image',
        //     selector: (row) => <img src={row.Image} alt={row.FullName} width={50} />,
        //     sortable: false,
        //     width: 'auto',
        // },
        {
            name: 'Name',
            selector: (row) => row.FullName,
            width: '180px',
            wrap: true,
            sortable: true
        },
        {
            name: 'Phone',
            selector: (row) => row.Phone,
            width: '150px',
            // sortable: true
        },
        {
            name: 'Email',
            selector: (row) => row.Email,
            width: '200px',
            wrap: true,
            // sortable: true
        },
        {
            name: 'Experience Level',
            selector: (row) => row.ExperienceLevel,
            width: '150px',
            // sortable: true
        },
        {
            name: 'Education',
            selector: (row) => [row.CareerProfile.education].join(', '),
            width: '150px',
            wrap: true
        },
        {
            name: 'Degree',
            selector: (row) => row.CareerProfile.degree,
            width: '150px',
            wrap: true
        },
        {
            name: 'General Skills',
            selector: (row) => [row.SkillProfile.GeneralSkills].join(', '),
            width: '400px',
            wrap: true
        },
        {
            name: 'Professional Skills',
            selector: (row) => [row.SkillProfile.ProfessionalSkills].join(', '),
            width: '400px',
            wrap: true
        },
        {
            name: 'Certifications',
            selector: (row) => <img src={row.SkillProfile.Certificate} width={50} />,
            sortable: false,
            width: 'auto',
        },
    ];

    createTheme('solarized', {
        // text: {
        //   primary: '#45A994',
        //   secondary: '#F7BD4E',
        // },
        background: {
          default: 'transparent',
        },
        context: {
          background: '#CDFFD8',
          text: '#FFFFFF',
        },
        divider: {
          default: '#45A994',
        },
        action: {
          button: 'rgba(0,0,0,.54)',
          hover: 'rgba(0,0,0,.08)',
          disabled: 'rgba(0,0,0,.12)',
        },
      }, 'light');

  return (
    <div id="appCapsule">
        <div className='section full py-4'>
            <div className='container'>
                <Button variant="primary mb-2">
                    <Link to="/" type="submit" className='my-2 text-white'><FaArrowLeft style={{width: '15px', height: '15px', marginRight: "7px"}}/>Go to Dashboard</Link>
                </Button>
                <h1 className="title text-dark">All Users</h1>
                {/* <div className="section-title text-dark ps-0">Recommended training and placement programs</div> */}
            </div>
        </div>
        <div className='section full mt-2 mb-4'>
            <div className='container'>
                <Row className='justify-content-between my-3'>
                    <div>
                        {allUsers.length > 0 && (
                        <DataTable
                            columns={desiredColumns}
                            data={allUsers}
                            striped={true}
                            theme='solarized'
                        />
                        )}
                    </div>
                </Row>
            </div>
        </div>
    </div>
  )
}

const mapStateToProps = ({auth}) =>{
    const {authToken } = auth
    return{
        authToken
    }
}

export default connect(mapStateToProps)(AdminUsers)