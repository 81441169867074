import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from '../../AxiosConfig'
import config from '../../config'
import jwt_decode from 'jwt-decode'
import { getContrastRatio } from '@mui/material'

export const requestOtp = createAsyncThunk(
  'auth/requestOtp',
  async (params) => {
    const { mobileNumber } = params
    const response = await axios.get(config.apiUrl + '/user/login?mobile='+ mobileNumber)
    const { data } = response
    return data
  }
)

export const validateOtp = createAsyncThunk(
  'auth/validateOtp',
  async (params) => {
    const { mobileNumber, otp } = params
    const response = await axios.get(config.apiUrl + '/user/login?mobile='+ mobileNumber +'&otp=' + otp)
    const { data } = response
    return data
  }
)

export const registerUser = createAsyncThunk(
  'auth/registerUser',
  async (params) => {
    const response = await axios.post(config.apiUrl + '/user/register', params)
    const { data } = response
    return data
  }
)

export const submitFormStep1 = createAsyncThunk(
  'auth/submitFormStep1',
  async (params) => {
    const { selectEduLevels, selectEduDegrees, selectXp, selectIncome, selectDomain, stepNo, completeProfileStep } = params;
    const careerData = {
      'education': selectEduLevels,
      'degree': selectEduDegrees,
      'experience': selectXp,
      // 'jobProfile': selectProfile,
      'incomeCTC': selectIncome,
      'domain': selectDomain
    }
    const response = await axios.put(config.apiUrl + '/user/register/' + stepNo + '/' + completeProfileStep.userInfo.ID , {careerData})
    const { data } = response
    return data
  }
)

export const submitFormStep2 = createAsyncThunk(
  'auth/submitFormStep2',
  async (params) => {
    const { selectGSkills, selectPSkills, uploadedFile, stepNo, completeProfileStep, selectNotification } = params;
    const skillsData = {
      'GeneralSkills': selectGSkills,
      'ProfessionalSkills': selectPSkills,
      'Certificate': uploadedFile
    };
    const notificationData = {
      'notifications': selectNotification
    };
    const response = await axios.put(config.apiUrl + '/user/register/' + stepNo + '/' + completeProfileStep.userInfo.ID , {skillsData, notificationData})
    const { data } = response
    return data
  }
)

export const submitFormStep3 = createAsyncThunk(
  'auth/submitFormStep3',
  async (params) => {
    const { selectNotification, stepNo, completeProfileStep } = params;
    const notificationData = {
      'notifications': selectNotification
    }
    const response = await axios.put(config.apiUrl + '/user/register/' + stepNo + '/' + completeProfileStep.userInfo.ID , {notificationData})
    const { data } = response
    return data
  }
)

const initialState = {
  authToken: null,
  hideSendOtpButton: false,
  hideSendOtpButton1 : false,
  showVerifyOtpForm: false,
  showLoginForm: true,
  showRegistrationForm: false,
  otpRequestSuccess: false,
  otpRequestError: false,
  otpValidateSuccess: false,
  otpValidateError: false,
  role: null,
  fullName: null,
  showRegistrationStep: null,
  completeProfileStep: null,
  ID: null,
  appUrlCourseID: null,
  xpLevel: null,
  themeValues: {
    primary:"#45A994",
    secondary:"#F7BD4E",
    tertiary:"#CDFFD8",
    quaternary:"#FEF6D8",
    body:"#4e4e4e",
    alternate:"#7c7c7c",
    lightText:"#fff",
    warning:"#ebb71a",
    danger:"#cf2637",
    success:"#439b38",
    info:"#279aac",
    font:"'League Spartan', sans-serif",
    fontHeading:"'League Spartan', sans-serif",
    background:"#f9f9f9",
    foreground:"#ffffff",
    separator:"#dddddd",
    separatorLight:"#f1f1f1",
    primaryrgb:"30, 168, 231",
    secondaryrgb:"108, 219, 239",
    tertiaryrgb:"93, 227, 200",
    quaternaryrgb:"85, 141, 243",
    transitionTimeShort:"200",
    transitionTime:"400",
    navSizeSlim:"5rem",
    borderRadiusXl:"50px",
    borderRadiusLg:"16px",
    borderRadiusMd:"10px",
    borderRadiusSm:"6px",
    spacingHorizontal:"2.5rem",
    sm:"576px",
    md:"768px",
    lg:"992px",
    xl:"1200px",
    xxl:"1400px",
    direction:"ltr"
  }
}

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logout: (state, action) => {
      console.log('Logout auth')
      localStorage.clear()
      state.hideSendOtpButton = false
      state.hideSendOtpButton1 = false
      state.showVerifyOtpForm = false
      state.showLoginForm = true
      state.showRegistrationForm = false
      state.otpRequestSuccess = false
      state.otpRequestError = false
      state.otpValidateSuccess = false
      state.otpValidateError = false
      state.authToken = null
      state.role = null
      state.fullName = null
      state.ID = null
    },
    backToLogin: (state) => {
      state.hideSendOtpButton = false
      state.hideSendOtpButton1 = false
      state.showVerifyOtpForm = false
      state.showLoginForm = true
      state.showRegistrationForm = false
      state.otpRequestSuccess = false
      state.otpRequestError = false
      state.otpValidateSuccess = false
      state.otpValidateError = false
      state.role = null
      state.fullName = null
      state.ID = null
      console.log('Back To Login')
      localStorage.clear()
    },
    setToken: (state, action) => {
      const tokenData = jwt_decode(action.payload.authToken);
      if (tokenData.userInfo.registrationStep === 0 || tokenData.userInfo.registrationStep === 3) {
        state.showRegistrationStep = tokenData.userInfo.registrationStep
        console.log(tokenData.userInfo.registrationStep)
        state.authToken = action.payload.authToken
        state.role = tokenData.userInfo.RoleID;
        state.fullName = tokenData.userInfo.FullName;
        state.ID = tokenData.userInfo.ID;
      }
      else {
        state.showRegistrationStep = tokenData.userInfo.registrationStep
        state.completeProfileStep = tokenData
        state.ID = tokenData.userInfo.ID;
        console.log(tokenData)
      }
    },
    setCity: (state, action) => {
      state.citySelect = action.payload.City
    },
    setCourseID: (state, action) => {
      state.appUrlCourseID = action.payload.appUrlCourseID
    },
    setXpLevel: (state, action) => {
      state.xpLevel = action.payload.xpLevel;
    }
  },
  extraReducers: {
    [requestOtp.fulfilled]: (state, action) => {
      const {message, data}  = action.payload
      console.log(action.payload)
      if (message === 'Success') {
        if (data === 'OTP Sent') {
          state.hideSendOtpButton = true
          state.showVerifyOtpForm = true
          state.otpRequestSuccess = true
        } else {
          state.otpRequestError = true
        }
      } else if (message === 'User not found') {
        state.showVerifyOtpForm = false
        state.showRegistrationForm = true
        state.hideSendOtpButton = true
      } else {
        state.hideSendOtpButton = false
        state.hideSendOtpButton1 = false
        state.showVerifyOtpForm = false
        state.showRegistrationForm = false
      }
    },
    // [requestOtp.pending]: (state, action) => {
    //   state.someValue += action.payload
    // },
    // [requestOtp.rejected]: (state, action) => {
    //   state.someValue += action.payload
    // },

    [validateOtp.fulfilled]: (state, action) => {
      console.log(action)
      const { data } = action.payload
      if (data !== 'Incorrect OTP') {
        const tokenData = jwt_decode(data);
        if (tokenData.userInfo.registrationStep === 0 || tokenData.userInfo.registrationStep === 3){
          localStorage.setItem('token', data)
          state.authToken = data
          state.otpValidateSuccess = true
          state.role = tokenData.userInfo.RoleID
          state.fullName = tokenData.userInfo.FullName
          state.showRegistrationStep = tokenData.userInfo.registrationStep
          console.log(tokenData.userInfo.registrationStep)
          state.ID = tokenData.userInfo.ID;
        }
        else {
          state.showRegistrationStep = tokenData.userInfo.registrationStep
          state.completeProfileStep = tokenData
          state.ID = tokenData.userInfo.ID;
          console.log(tokenData)
        }
      } else {
        state.otpValidateError = true
        state.authToken = null
      }
    },

    [registerUser.fulfilled]: (state, action) => {
      const { data, message, status } = action.payload
      console.log(action.payload)
      if(data === 'OTP Sent') {
        state.showVerifyOtpForm = true
        state.otpRequestSuccess = true
      } else {
        state.otpRequestError = true
      }
    },

    [submitFormStep1.fulfilled]: (state, action) => {
      console.log(action)
      const { data, status, message } = action.payload
      console.log(data, typeof status, message)
      if (message === 'Success') {
        console.log('ifSuccess')
        const tokenData = jwt_decode(data);
        if (tokenData.userInfo.registrationStep === 0 || tokenData.userInfo.registrationStep === 3){
          console.log('ififSuccess')
          localStorage.setItem('token', data)
          state.authToken = data
          state.otpValidateSuccess = true
          state.role = tokenData.userInfo.RoleID
          state.fullName = tokenData.userInfo.FullName
          state.showRegistrationStep = tokenData.userInfo.registrationStep
          console.log(tokenData.userInfo.registrationStep)
          state.ID = tokenData.userInfo.ID;
        }
        else {
          state.showRegistrationStep = tokenData.userInfo.registrationStep
          state.completeProfileStep = tokenData
          state.ID = tokenData.userInfo.ID;
          console.log(tokenData)
        }
      } else {
        console.log('elseSuccess')
        state.otpValidateError = true
        state.authToken = null
      }
    },

    [submitFormStep2.fulfilled]: (state, action) => {
      console.log(action)
      const { data, status, message } = action.payload
      console.log(data, typeof status, message)
      if (message === 'Success') {
        console.log('ifSuccess')
        const tokenData = jwt_decode(data);
        if (tokenData.userInfo.registrationStep === 0 || tokenData.userInfo.registrationStep === 3){
          console.log('ififSuccess')
          localStorage.setItem('token', data)
          state.authToken = data
          state.otpValidateSuccess = true
          state.role = tokenData.userInfo.RoleID
          state.fullName = tokenData.userInfo.FullName
          state.showRegistrationStep = tokenData.userInfo.registrationStep
          console.log(tokenData.userInfo.registrationStep)
          state.ID = tokenData.userInfo.ID;
        }
        else {
          state.showRegistrationStep = tokenData.userInfo.registrationStep
          state.completeProfileStep = tokenData
          state.ID = tokenData.userInfo.ID;
          console.log(tokenData)
        }
      } else {
        console.log('elseSuccess')
        state.otpValidateError = true
        state.authToken = null
      }
    },

    [submitFormStep3.fulfilled]: (state, action) => {
      console.log(action)
      const { data, status, message } = action.payload
      console.log(data, typeof status, message)
      if (message === 'Success') {
        console.log('ifSuccess')
        const tokenData = jwt_decode(data);
        if (tokenData.userInfo.registrationStep === 0 || tokenData.userInfo.registrationStep === 3){
          console.log('ififSuccess')
          localStorage.setItem('token', data)
          state.authToken = data
          state.otpValidateSuccess = true
          state.role = tokenData.userInfo.RoleID
          state.showRegistrationStep = tokenData.userInfo.registrationStep
          console.log(tokenData.userInfo.registrationStep)
          state.fullName = tokenData.userInfo.FullName
          state.ID = tokenData.userInfo.ID;
        }
        else {
          state.showRegistrationStep = tokenData.userInfo.registrationStep
          state.completeProfileStep = tokenData
          state.ID = tokenData.userInfo.ID;
          console.log(tokenData)
        }
      } else {
        console.log('elseSuccess')
        state.otpValidateError = true
        state.authToken = null
      }
    }

    // [validateOtp.pending]: (state, action) => {
    //   state.someValue += action.payload
    // },
    // [validateOtp.rejected]: (state, action) => {
    //   state.someValue += action.payload
    // }
  }
})

export const { logout, backToLogin, setToken, setCity, setCourseID, setXpLevel } = authSlice.actions;


export default authSlice.reducer
