import React from 'react'
import { Row, Col, Card} from 'react-bootstrap'
import { ShimmerCircularImage } from "react-shimmer-effects";
import { ShimmerSubTitle } from "react-shimmer-effects";
import { ShimmerButton } from "react-shimmer-effects";
import { ShimmerText } from "react-shimmer-effects";
import { ShimmerCategoryList } from "react-shimmer-effects";

const ShimmerJobs = () => {
  return (
        <div>
            <ShimmerCategoryList title items={6} categoryStyle="STYLE_SEVEN" />
        </div>
    
  )
}

export default ShimmerJobs