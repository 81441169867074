import React, { useState, useEffect } from 'react'
import { Button, Card, Col, Row } from 'react-bootstrap'
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { fetchCertificationDetailsData } from '../../features/constants/apis';
import { connect } from 'react-redux';

const CertificationDetails = ({authToken}) => {

    const [certificationData, setCertificationData] = useState([]);
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const getCourseID = searchParams.get('id');

    useEffect(()=>{
        const fetchData = async () => {
            const data = await fetchCertificationDetailsData(getCourseID, authToken);
            setCertificationData(data);
        }
        fetchData();
    }, [authToken, getCourseID]);


    return (
        <div id="appCapsule">
            <div className='section full py-4'>
                <div className='container'>
                    <h1 className="title text-dark">Certification Details</h1>
                    <Row className="row cert-details-ID mt-20">
                        <h2 className="post-title">{certificationData?.Name}</h2>
                        <Col className="col-xxl-9 col-xl-8">
                            <div className="details-post-data me-xxl-5 pe-xxl-4">
                                <div className="img-meta mb-15">
                                    <img src={certificationData?.Image} data-src="images/blog/blog_img_32.jpg" alt="" className="lazy-img mb-2" style={{aspectRatio: '3 / 2', objectFit: 'fill', borderRadius: '15px', width: '85%' }} />
                                </div>
                                <div className="post-block border-style mt-50 lg-mt-30">
                                    <div className="d-flex align-items-center">
                                        <h3 className="block-title">Course Overview</h3>
                                    </div>
                                    <p>{certificationData?.Description}</p>
                                </div>
                            </div>
                        </Col>
                        <Col className="col-xxl-3 col-xl-4">
                            <div className="job-company-info ms-xl-5 ms-xxl-0 lg-mt-50 xs-mt-10">
                                <div>
                                    <ul className="job-meta-data row style-none ps-0">
                                        <li className="col-xl-6 col-md-4 col-sm-6">
                                            <span>Price</span>
                                            <div>₹{certificationData?.Price}/-</div>
                                        </li>
                                        <li className="col-xl-6 col-md-4 col-sm-6">
                                            <span>Duration</span>
                                            <div>2 Months</div>
                                        </li>
                                        <li className="col-xl-12 col-md-4 col-sm-6">
                                            <span>Skills</span>
                                            <div className="job-tags d-flex flex-wrap pt-15">
                                                <a>{certificationData?.Stat1}</a>
                                                <a>{certificationData?.Stat2}</a>
                                                <a>{certificationData?.Stat3}</a>
                                                <a>{certificationData?.Stat4}</a>
                                            </div>
                                        </li>
                                    </ul>
                                    <Button href="#" className="btn-one w-100 mt-25" data-bs-toggle="modal" data-bs-target="#loginModal">Apply Now</Button>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = ({CertificationDetails}) => {
    return {}
}

const mapDispatchToProps = {
  }

export default connect(mapStateToProps, mapDispatchToProps) (CertificationDetails)
