import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './AnimatedLoader.css';
import { connect } from 'react-redux';

const AnimatedLoader = ({ fullName }) => {
  const [currentText, setCurrentText] = useState('Creating your skill profile...');
  const [currentIndex, setCurrentIndex] = useState(1); // Start from the second text
  const textArray = [
    'Creating your skill profile...',
    'Curating the right Job openings for you...',
    'Setting up your dashboard...'
  ];
  const totalDuration = 8000;
  const individualDuration = totalDuration / (textArray.length - 1); 
  console.log(individualDuration)
  console.log(textArray.length - 1)
  const navigate = useNavigate();

  useEffect(() => {
    const interval = setInterval(() => {
      if (currentIndex < textArray.length) {
        setCurrentText(textArray[currentIndex]);
        setCurrentIndex((prevIndex) => prevIndex + 1);
      }
    }, individualDuration);

    const redirectTimeout = setTimeout(() => {
      navigate('/');
    }, totalDuration + individualDuration - 8000);

    return () => {
      clearInterval(interval);
      clearTimeout(redirectTimeout);
    };
  }, [currentIndex, navigate, textArray, individualDuration]);

  return (
    <div className="animated-loader">
      <p key={currentIndex} className="fade-in-out-text">{currentText}</p>
    </div>
  );
};

const mapStateToProps = ({ auth }) => {
  const { fullName } = auth;

  return {
    fullName,
  };
}

export default connect(mapStateToProps)(AnimatedLoader);
