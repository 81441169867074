import React from 'react'
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

const DeletePdModal = (props) => {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
            Confirm Delete Placement Drive
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4>{props.confirmDeleteMessage}</h4>
        {/* <h4>Are you sure you want to delete this Placement Drive?</h4> */}
        {/* <p>
            This action cannot be undone. Once you confirm, the job will be permanently deleted from the system.
        </p> */}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="danger" onClick={props.handleDeleteDrive}>Confirm Delete</Button>
      </Modal.Footer>
    </Modal>
  )
}

export default DeletePdModal