import React, { useEffect } from 'react'
import Toast from 'react-bootstrap/Toast'
import { connect } from "react-redux"
import { hideAppToast } from '../../../features/utilities/appToastSlice'

const AppToast = ({
  background,
  message,
  show,
  icon,
  hideAppToast,
  position
}) => {
  useEffect(() => {
    const timer = setTimeout(() => hideAppToast(), 2000);

    return () => clearTimeout(timer);
  }, [show, hideAppToast])

  return (
    <div>
      <Toast show={false} autohide delay={2000} bg={background} className={(position === 'toast-center') ? "toast-box toast-center" : "toast-box rounded-0 p-0 toast-center"} style={{display: 'inherit', zIndex: 9999}}>
        <Toast.Body className="in">
          <ion-icon name={icon} className="bg-success"></ion-icon>
          <div className="text">
              {message}
          </div>
          <button type="button" className="btn btn-sm btn-text-light close-button" onClick={hideAppToast}>CLOSE</button>
        </Toast.Body>
      </Toast>
    </div>
  )
}

const mapStateToProps = () => {
  return {}
}

const mapDispatchToProps = {
  hideAppToast
}

export default connect(mapStateToProps, mapDispatchToProps)(AppToast)
