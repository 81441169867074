import { React, useState, useEffect } from 'react'
import { connect } from 'react-redux';
import Select from 'react-select';
import { Badge, Button, Form, InputGroup } from 'react-bootstrap'
import { setXpLevel, setToken } from '../../../features/auth/authSlice';
import { fetchAllEduDegrees, fetchAllEduLevels, GetCandidateByID, fetchAllJobRoles } from '../../../features/constants/apis';

const RegFormStep1 = ({changeForm, xpLevel, submitFormStep1, completeProfileStep, authToken, ID}) => {
    const [validated, setValidated] = useState(false);
    const [selectEduLevels, setSelectEduLevels] = useState(null);
    const [selectEduDegrees, setSelectEduDegrees] = useState(null);
    const [selectXp, setSelectXp] = useState(null);
    const [selectProfile, setSelectProfile] = useState(null);
    const [selectIncome, setSelectIncome] = useState(null);
    const [selectDomain, setSelectDomain] = useState(null);
    const [getEduLevels, setGetEduLevels] = useState([]);
    const [getEduDegrees, setGetEduDegrees] = useState([]);
    const [candidateData, setCandidateData] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
          const data = await fetchAllEduLevels(authToken);
          setGetEduLevels(data);
        };
        fetchData();
    }, [authToken]);

    useEffect(() => {
        const fetchData = async() => {
            const data = await fetchAllEduDegrees(authToken);
            setGetEduDegrees(data);
        };
        fetchData();
    }, [authToken]);

    useEffect(() => {
        const fetchData = async () => {
            const data = await GetCandidateByID(ID, authToken);
            setCandidateData(data.data);
            console.log(data.data, ID);
            console.log(candidateData);
        };
        fetchData();
    }, [ID, authToken]);
    
    console.log(xpLevel, ID, authToken)

    const eduLevelOptions = getEduLevels.map(level => ({
        value: level.Level,
        label: level.Level,
    }));

    const handleSelectedEduLevels = (selectedOptions) => {
        console.log(selectedOptions)
        const selectEduLevels = selectedOptions.value;
        setSelectEduLevels(selectEduLevels);
    };

    const eduDegreeOptions = getEduDegrees.map(level => ({
        value: level.Education,
        label: level.Education,
    }));

    const handleSelectedEduDegrees = (selectedOptions) => { 
        const selectEduDegrees = selectedOptions.value;
        setSelectEduDegrees(selectEduDegrees);
    };

    console.log(ID)

    const [jobRoles, setJobRoles] = useState([])

    useEffect(() => {
        const fetchJobRoles = async() =>{
            const data = await fetchAllJobRoles(authToken);
            setJobRoles(data.data)
            console.log(data.data)
        }
        fetchJobRoles()
    }, [authToken])

    const handleDomain = () =>{
        console.log('options')
    }

    const [hasError, setHasError] = useState({
        'selectEduLevels': false,
        'selectEduDegrees': false,
        'selectXp': false,
        'selectProfile': false,
        'selectIncome': false,
        'selectDomain': false
    })
    
    const showErrorMsg = (e) => {
        const showError = { ...hasError}
        console.log(showError)
        if ( e === "Select Education") {
            console.log(e)
        }
        if ( e === "Select Experience") {
            console.log(e)
        }
        if ( e === "Select Profile") {
            console.log(e)
        }
        if ( e === "Select CTC") {
            console.log(e)
        }
        if ( e === "Select Domain") {
            console.log(e)
        }
    }

    function submitForm(e) {
        const form = e.currentTarget;
        if (form.checkValidity() === false) {
            e.stopPropagation();
        }
        e.preventDefault();
        
        if (selectEduLevels === null) {
            showErrorMsg("Select Education Level")
            console.log(selectEduLevels)
        }
        else if (selectEduDegrees === null) {
            showErrorMsg("Select Education Degree")
            console.log(selectEduDegrees)
        }
        else if (selectXp === null && candidateData.ExperienceLevel === 'Experienced') {
            showErrorMsg("Select Experience")
            console.log(selectXp)
        }
        // else if (selectProfile === null) {
        //     showErrorMsg("Select Profile")
        //     console.log(selectProfile)
        // }
        else if (selectIncome === null && candidateData.ExperienceLevel === 'Experienced') {
            showErrorMsg("Select CTC")
            console.log(selectIncome)
        }
        else if (selectDomain === null) {
            showErrorMsg("Select Domain")
            console.log(selectDomain)
        }
        else {
            console.log(completeProfileStep)
            const newStep1RegData = {
                'selectEduLevels': selectEduLevels,
                'selectEduDegrees': selectEduDegrees,
                'selectXp': selectXp,
                // 'selectProfile': selectProfile,
                'selectIncome': selectIncome,
                'selectDomain': selectDomain,
                'stepNo': 1,
                'completeProfileStep': completeProfileStep
            }
            console.log(newStep1RegData)
            submitFormStep1(newStep1RegData)
        }
    }


    // filtered out duplicate domains
    const uniqueDomain = Array.from(new Set(jobRoles.map(role => role.Domain)))
    .map(domain => jobRoles.find(role => role.Domain === domain));

  return (
    <div className="section mb-2 mt-5 full">
        <div className="wide-block pt-5 pb-2" style={{borderBottom: "none"}}>
            <Form noValidate validated={validated} onSubmit={submitForm}>
                <Form.Label htmlFor="eduLevel1">Select Education Level</Form.Label>
                <InputGroup className="boxed mb-2 position-relative">
                    <InputGroup.Text id="basic-addon1"><i className="fa fa-graduation-cap"></i></InputGroup.Text>
                    <Select
                        required
                        id="eduLevel1"
                        options={eduLevelOptions}
                        defaultValue={'Select Education Degree'}
                        onChange={handleSelectedEduLevels}
                    />
                        {/* <option selected disabled>Select Education Level</option>
                        <option value="SSC">SSC</option>
                        <option value="HSC">HSC</option>
                        <option value="Graduate">Graduate</option>
                        <option value="Post Graduate">Post Graduate</option> */}
                </InputGroup>

                <Form.Label htmlFor="eduDegrees1">Select Education Degree</Form.Label>
                <InputGroup className="boxed mb-2 position-relative">
                    <InputGroup.Text id="basic-addon1"><i className="fa fa-graduation-cap"></i></InputGroup.Text>
                    <Select
                        aria-label="Default select example"
                        id="eduDegrees1"
                        required
                        options={eduDegreeOptions}
                        onChange={handleSelectedEduDegrees}
                    />
                        {/* <option selected disabled>Select Education Degree</option>
                        {getEduDegrees.map((level) => (
                            <option key={level.ID} value={level.Education}>{level.Education}</option>
                        ))} */}
                </InputGroup>

                {candidateData.ExperienceLevel === 'Experienced' && (
                    <Form.Group className="boxed mb-2">
                        <Form.Label for="xpLevel1">Select Years of experience</Form.Label>
                        <InputGroup className="boxed mb-2 position-relative">
                            <InputGroup.Text id="basic-addon1"><i className="fa fa-business-time"></i></InputGroup.Text>
                            <Form.Select
                                aria-label="Default select example"
                                id="xpLevel1"
                                required
                                onChange={(e) => setSelectXp(e.target.value)}
                            >
                                <option selected disabled>Select Years of experience</option>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="5">5</option>
                                <option value="6">6</option>
                                <option value="7">7</option>
                                <option value="8">8</option>
                                <option value="9">9</option>
                                <option value="10">10</option>
                                <option value="11">11</option>
                                <option value="12">12</option>
                                <option value="13">13</option>
                                <option value="14">14</option>
                                <option value="15">15</option>
                                <option value="16">16</option>
                                <option value="17">17</option>
                                <option value="18">18</option>
                                <option value="19">19</option>
                                <option value="20">20</option>
                            </Form.Select>
                        </InputGroup>
                    </Form.Group>
                )}

                {/* {candidateData.ExperienceLevel === 'Experienced' && (
                    <Form.Group className="boxed mb-2">
                        <Form.Label for="profileLevel1">Working as</Form.Label>
                        <InputGroup className="boxed mb-2 position-relative">
                            <InputGroup.Text id="basic-addon1"><i className="fa fa-user"></i></InputGroup.Text>
                            <Form.Select
                                aria-label="Default select example"
                                id="profileLevel1"
                                required
                                onChange={(e) => setSelectProfile(e.target.value)}
                            >
                                <option selected disabled>Select Job Profile</option>
                                <option value="Freelance">Freelance</option>
                                <option value="Profile 1">Profile 1</option>
                                <option value="Profile 2">Profile 2</option>
                            </Form.Select>
                        </InputGroup>
                    </Form.Group>
                )} */}

                {candidateData.ExperienceLevel === 'Experienced' && (
                    <Form.Group className="boxed mb-2">
                        <Form.Label for="incomeLevel1">Current Yearly Income</Form.Label>
                        <InputGroup className="boxed mb-2 position-relative">
                            <InputGroup.Text id="basic-addon1"><i className="fa fa-rupee-sign"></i></InputGroup.Text>
                            <Form.Select
                                aria-label="Default select example"
                                id="incomeLevel1"
                                required
                                onChange={(e) => setSelectIncome(e.target.value)}
                            >
                                <option selected disabled>Current Monthly Income</option>
                                <option value="1">1 Lakh</option>
                                <option value="2">2 Lakh</option>
                                <option value="3">3 Lakh</option>
                                <option value="4">4 Lakh</option>
                                <option value="5">5 Lakh</option>
                                <option value="6">6 Lakh</option>
                                <option value="7">7 Lakh</option>
                                <option value="8">8 Lakh</option>
                                <option value="9">9 Lakh</option>
                                <option value="10">10 Lakh</option>
                                <option value="11">11 Lakh</option>
                                <option value="12">12 Lakh</option>
                                <option value="13">13 Lakh</option>
                                <option value="14">14 Lakh</option>
                                <option value="15">15 Lakh</option>
                                <option value="16">16 Lakh</option>
                                <option value="17">17 Lakh</option>
                                <option value="18">18 Lakh</option>
                                <option value="19">19 Lakh</option>
                                <option value="20">20 Lakh</option>
                            </Form.Select>
                        </InputGroup>
                    </Form.Group>
                )}

                <Form.Group className="boxed mb-2">
                    <Form.Label for="domainLevel1">Select Domain of your interest</Form.Label>
                    <InputGroup className="boxed mb-2 position-relative">
                        <InputGroup.Text id="basic-addon1"><i className="fa fa-list"></i></InputGroup.Text>
                        <Form.Select
                            aria-label="Default select example"
                            id="domainLevel1"
                            required
                            onChange={(e) => setSelectDomain(e.target.value)}
                        >
                            <option selected disabled>Select Domain</option>
                            {jobRoles.map((role, index) => (
                                <option key={index} value={role.ID}>{role.Title}</option>
                            ))}

                            {/* <option value="Computer Science">Computer Science</option>
                            <option value="Biology">Biology</option>
                            <option value="Mathematics">Mathematics</option>
                            <option value="Physics">Physics</option>
                            <option value="Chemistry">Chemistry</option>
                            <option value="Psychology">Psychology</option> */}
                        </Form.Select>
                    </InputGroup>
                </Form.Group>

                <Button type="submit" className="btn btn-primary btn-lg btn-block">Next<i className="fa fa-chevron-right" style={{fontSize: "14px", paddingLeft: "5px"}}></i></Button>

            </Form>
        </div>
    </div>
  )
}

const mapStateToProps = ( { auth } ) => {
    const { xpLevel, ID } = auth
  
    return {
        xpLevel, ID
    }
}
const mapDispatchToProps = {
    setXpLevel,
    setToken
}
export default connect(mapStateToProps, mapDispatchToProps) (RegFormStep1)
