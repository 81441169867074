import React, { useEffect, useState, useRef } from 'react'
import { connect } from 'react-redux'
import { Card, Button, Form, InputGroup, Modal, Image } from "react-bootstrap";
import Select from 'react-select';
import { generalSkillOptions, professionalSkillOptions } from '../../features/constants/skillsData';
import { Navigate, useLocation, useNavigate } from 'react-router';
// import userPhoto from '../images/user-profile.webp';
import logoPhoto from '../images/Favicon_large-removebg-preview.png';
import userPhoto from '../images/profile-placeholder.jpeg'
import { GetAllApplicationsOfDrive, GetCandidateByID, UpdateUserProfile, deleteCandidateEducationRecord, deleteCandidateExperienceRecord, fetchAllJobRoles, fetchAllJobRolesSkills, postUserImage, shortlistApplicant } from '../../features/constants/apis';
import AddNewEducation from './Modals/AddNewEducation';
import { FaArrowLeft, FaPen, FaPlus } from 'react-icons/fa';
import AddNewExperience from './Modals/AddNewExperience';
import axios from 'axios';
import { RiDeleteBin6Line } from 'react-icons/ri';
import UpdateEducationModal from './Modals/UpdateEducationModal';
import UpdateExperienceModal from './Modals/UpdateExperienceModal';
import { FaUpload } from 'react-icons/fa';
import FileUploadWithProgress from './FileUploadWithProgress';
import tick from '../images/icon/tick-image.png';
import { Link } from 'react-router-dom';

const CandidateProfile = ({ fullName, role, authToken, ID }) => {

    // console.log(role)
    // console.log(ID)

    // photo upload
    const [uploadedImage, setUploadedImage] = useState('');
    const [fileUploaded, setFileUploaded] = useState(false);
    const [previewImage, setPreviewImage] = useState(null);
    const [imageSet, setImageSet] = useState(false);
    const fileInputRef = useRef(null);


    const [userInfo, setUserInfo] = useState([])
    const [isEditingProfile, setIsEditingProfile] = useState(false)

    const [userDomain, setUserDomain] = useState('')

    const [userEduction, setUserEducation] = useState([]);
    const [userExperience, setUserExperience] = useState([])

    const [jobRoles, setJobRoles] = useState([])

    // Add new Education modal
    const [eduModalShow, setEduModalShow] = useState(false);
    const [expModalShow, setExpModalShow] = useState(false);

    // Delete modals
    const [modalShow, setModalShow] = useState(false);
    const [itemToDelete, setItemToDelete] = useState(null);
    const [eduIdToDeleted, setEduIdToDeleted] = useState(null)
    const [expIdToDeleted, setExpIdToDeleted] = useState(null)
    const [modalTitle, setModalTitle] = useState('');
    const [modalMessage, setModalMessage] = useState('');

    // Update Education Modal
    const [updateEduModalShow, setUpdateEduModalShow] = useState(false)
    const [editabelEducation, setEditableEducation] = useState([])

    // Update Experience Modal
    const [updateExpModalShow, setUpdateExpModalShow] = useState(false)
    const [editableExperience, setEditableExperience] = useState([])


    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const candidateID = searchParams.get('candidate');
    const jobID = searchParams.get('job');


    // Edit Bio
    const [editableUserFullName, setEditableUserFullName] = useState('');
    const [editableUserDomain, setEditableUserDomain] = useState('');
    const [editableDescription, setEditableDescription] = useState('');
    const [editableGenSkills, setEditableGenSkills] = useState([]);
    const [editableProSkills, setEditableProSkills] = useState([]);

    // shortlist candidate index
    const [currentApplicantIndex, setCurrentApplicantIndex] = useState(0);

    // job applicants
    const [jobApplicants, setJobApplicants] = useState([])
    const [perticularApplication, setPerticularApplication] = useState([])


    // console.log(candidateID)

    useEffect(() => {
        const UserData = async () => {
            const data = await GetCandidateByID((candidateID ? candidateID : ID), authToken)
            // if (role === 'Candidate') {
                console.log(data?.data);
                setUserInfo(data.data);
                setUserEducation(data.data?.Education);
                setUserExperience(data.data?.Experience);
            }
        // };
        UserData();
    }, [ID, authToken, role, candidateID]);



    // jobRole

    useEffect(() => {
        const fetchJobRoles = async () => {
            const data = await fetchAllJobRoles(authToken);
            setJobRoles(data.data)
            // console.log(data.data)
        }
        fetchJobRoles()
    }, [authToken])

    useEffect(() => {
        if (userInfo.CareerProfile && jobRoles.length > 0) {
            const domainValue = userInfo.CareerProfile.domain;
            // console.log(domainValue);
            // console.log(jobRoles);
            const matchingJobRoles = jobRoles.find(jobRole => jobRole.ID == domainValue);
            // console.log(matchingJobRoles);
            // setUserDomain(matchingJobRoles.Title)
        }
    }, [userInfo.CareerProfile, jobRoles]);


    // skills from job roles
    const [jobRolesSkills, setJobRolesSkills] = useState({ GeneralSkills: [], ProfessionalSkills: [] })

    useEffect(() => {
        const fetchData = async () => {
            const data = await fetchAllJobRolesSkills(authToken);
            setJobRolesSkills(data.data)
            // console.log(authToken)
            // console.log(data?.data)
        }
        fetchData()
    }, [authToken]);

    const generalSkillOptions = jobRolesSkills?.GeneralSkills.map(skill => ({
        value: skill,
        label: skill,
    }));

    const professionalSkillOptions = jobRolesSkills?.ProfessionalSkills.map(skill => ({
        value: skill,
        label: skill,
    }));

    const refetchUserData = async () => {
        const data = await GetCandidateByID(ID, authToken);
        setUserEducation(data?.data?.Education);
        setUserExperience(data?.data?.Experience);
    };

    // Edit user profile
    const handleProfileEdit = () => {

        setEditableUserFullName(userInfo?.FullName || '');
        setEditableUserDomain(userInfo?.CareerProfile?.domain || '');
        setEditableDescription(userInfo?.Description || '');

        const defaultGenSkills = generalSkillOptions.filter(option =>
            userInfo?.SkillProfile?.GeneralSkills.includes(option.value)
        );
        setEditableGenSkills(defaultGenSkills);

        const defaultProSkills = professionalSkillOptions.filter(option =>
            userInfo?.SkillProfile?.ProfessionalSkills.includes(option.value)
        );
        setEditableProSkills(defaultProSkills);

        if (role === 'Candidate') {
            setIsEditingProfile(true)
        }
    }

    const hadnleSaveEditedProfile = () => {

        const editedUserInfo = {
            'name': editableUserFullName,
            'domain': editableUserDomain,
            'generalSkills': editableGenSkills.map(skill => skill.value),
            'professionalSkills': editableProSkills.map(skill => skill.value),
            'description': editableDescription,
        }
        // console.log(editedUserInfo)
        // console.log(editableUserDomain)

        const EditUserProfile = async () => {
            const updateProfile = await UpdateUserProfile(ID, editedUserInfo, authToken)
            // console.log(updateProfile)

            const data = await GetCandidateByID(ID, authToken)
            if (role === 'Candidate') {
                setUserInfo(data.data);
                // console.log(data.data);
                setUserEducation(data.data?.Education);
                setUserExperience(data.data?.Experience);
            }
        }
        EditUserProfile()

        setIsEditingProfile(false)
    }

    const handleProfileCancelEdit = () => {
        setEditableUserFullName(userInfo?.FullName)
        setEditableUserDomain(userInfo?.CareerProfile?.domain)
        setEditableDescription(userInfo?.Description)
        setIsEditingProfile(false)
    }

    const uploadRefImage = async (filePath) => {
        setFileUploaded(true);
        const formData = new FormData();
        formData.append('inputFile', filePath);
        try {
            const res = await axios.post('https://api.allpos.in/applications/upload/scopehai', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
            });
            // console.log(res.data.data);
            setUploadedImage(res.data.data);
            setFileUploaded(false);
            return res.data.data; // Return the uploaded image URL
        } catch (err) {
            console.error('Error uploading file:', err);
            setFileUploaded(false);
            throw err;
        }
    };

    // console.log(uploadedImage)

    const handleImageChange = async (e) => {
        const file = e.target.files[0];
        if (file) {
            setPreviewImage(URL.createObjectURL(file));
            try {
                const imageUrl = await uploadRefImage(file); // Wait for the image URL
                await handleSetImage(imageUrl); // Call handleSetImage with the image URL
            } catch (error) {
                console.error('Error handling image change:', error);
            }
        }
    };

    const handleSetImage = async (imageUrl) => {
        // console.log('image uploaded');

        const imageUrlObject = {
            image: imageUrl
        };

        await postUserImage(ID, imageUrlObject, authToken);

        setUserInfo(prevUserInfo => ({
            ...prevUserInfo,
            Image: imageUrl // Use the provided image URL
        }));

        setImageSet(true);
        setPreviewImage(null);

        fileInputRef.current.value = '';
    };


    // Deleting Education and Experience
    const handleShowDeleteModal = (item, eduID = null, expID = null) => {

        if (item === 'education') {
            setModalTitle("Delete Education");
            setModalMessage("Are you sure you want to delete this record? This action can not be undone.")
            setItemToDelete(item);
            setEduIdToDeleted(eduID);
            // console.log('eduID:', eduID)
        }
        if (item === 'experience') {
            setModalTitle("Delete Experience");
            setModalMessage("Are you sure you want to delete this record? This action can not be undone.")
            setItemToDelete(item);
            setExpIdToDeleted(expID);
            // console.log('expID:', expID);
        }
        setModalShow(true);
    }

    const fetchUserData = async () => {
        const data = await GetCandidateByID(ID, authToken);
        if (role === 'Candidate') {
            setUserInfo(data.data);
            setUserEducation(data.data?.Education);
            setUserExperience(data.data?.Experience);
        }
    };

    const handleDelete = async () => {
        if (itemToDelete === 'education') {
            // console.log("education deleted")
            await deleteCandidateEducationRecord(authToken, ID, eduIdToDeleted)
        }
        else if (itemToDelete === 'experience') {
            // console.log("experience deleted")
            await deleteCandidateExperienceRecord(authToken, ID, expIdToDeleted)
        }

        setModalShow(false)
        fetchUserData()
    }


    // Edit Education
    const handleShowEduUpdateModal = (education) => {
        setUpdateEduModalShow(true)
        setEditableEducation(education);
    }

    // Edit Experience
    const handleShowExpUpdateModal = (experience) => {
        setUpdateExpModalShow(true)
        setEditableExperience(experience)
    }


    // upload CV
    const [uploadProgress, setUploadProgress] = useState(0);

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onprogress = (event) => {
                if (event.loaded && event.total) {
                    const progress = (event.loaded / event.total) * 100;
                    setUploadProgress(progress);
                }
            };
            reader.onloadend = () => {
                setUploadProgress(100);
                setTimeout(() => setUploadProgress(0), 2000); // Reset after 2 seconds
            };
            reader.readAsDataURL(file);
        }
    };

    // candidates
    useEffect(() => {
        const fetchPDApplications = async () => {
            const data = await GetAllApplicationsOfDrive(authToken)
            // console.log(data)
            setJobApplicants(data.filter(application => application?.EmployerID === ID))
            // console.log(data.filter(application => application?.EmployerID === ID))

            //get perticular application
            const filteredPerticularApplication = data.filter(application => (application?.JobID === parseInt(jobID)) && (application?.CandidateID === parseInt(candidateID)) && (application?.EmployerID === ID))

            setPerticularApplication(filteredPerticularApplication);

        }
        fetchPDApplications();
    }, [authToken, ID, candidateID, jobID]);



    const navigate = useNavigate();
    
    // console.log(perticularApplication)
    // console.log(jobApplicants)

    // shortlist and next
    const handlleShortlistAndNext = async () => {
        setCurrentApplicantIndex(prevIndex => {
            const newIndex = prevIndex + 1;
            if (newIndex < jobApplicants.length) {
                const nextCandidateID = jobApplicants[newIndex].CandidateID;
                const nextJobID = jobApplicants[newIndex].JobID;
                // console.log(nextCandidateID);
                navigate(`/candidate-profile?candidate=${nextCandidateID}&job=${nextJobID}`);
            } else {
                setCurrentApplicantIndex(0);
            }
            return newIndex;
        });
        await shortlistApplicant(authToken, jobID, candidateID)
    };
    const viewNextApplicant = async () => {
        setCurrentApplicantIndex(prevIndex => {
            const newIndex = prevIndex + 1;
            if (newIndex < jobApplicants.length) {
                const nextCandidateID = jobApplicants[newIndex].CandidateID;
                const nextJobID = jobApplicants[newIndex].JobID;
                // console.log(nextCandidateID);
                navigate(`/candidate-profile?candidate=${nextCandidateID}&job=${nextJobID}`);
            } else {
                setCurrentApplicantIndex(0);
            }
            return newIndex;
        });
    };

    console.log(perticularApplication)
    // console.log(perticularApplication.length > 0 ? perticularApplication[0].Shortlist : null);

    

    return (
        <div id="appCapsule">
            <div className='container candidate-profile'>
                { (role === 'Employer' || role === 'Admin') &&
                    
                        <Link to="/all-emp-applications" type="submit" className='my-2 text-white'><FaArrowLeft style={{width: '15px', height: '15px', marginRight: "7px"}}/><Button variant="primary mb-2">View All Applicants</Button></Link>
                    
                }
                <div className="section mt-2">
                    <div className="profile-head">
                        <div className="avatar d-flex flex-column align-items-center gap-1">
                            <img
                                src={previewImage || userInfo.Image || userPhoto}
                                alt="avatar"
                                className="imaged w64 rounded profile-pic"
                            />
                            {role === 'Candidate' &&
                                <>
                                    <Form.Control
                                        id='fileUpload'
                                        type="file"
                                        accept='image/*'
                                        custom
                                        label='Choose Image'
                                        onChange={handleImageChange}
                                        className="btn btn-outline-primary btn-sm cursor-pointer "
                                        style={{ padding: '0.2rem 0.2rem', lineHeight: '1.5', display: 'none' }}
                                        ref={fileInputRef}
                                    />


                                    {fileUploaded ? (
                                        <span className='text-nowrap'>uploading...</span>
                                    ) : (
                                        <label htmlFor="fileUpload" className='cursor-pointer profile-image-upload' style={{ whiteSpace: 'nowrap' }}>Choose Image</label>
                                    )}

                                </>
                            }
                        </div>
                        <div className="in w-100">
                            {isEditingProfile ?
                                <>
                                    <div className='d-flex name-title justify-content-between'>
                                        <Form.Group className="w-50">
                                            <Form.Control
                                                required
                                                type="text"
                                                onChange={(e) => setEditableUserFullName(e.target.value)}
                                                value={editableUserFullName}
                                                placeholder="Enter Your Full Name"
                                            />
                                            <Form.Control.Feedback type="invalid" style={{ fontSize: '.8rem' }}>
                                                Please provide a valid Title.
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <div className="head-buttons d-flex gap-2">
                                            <Button className='' variant='primary' onClick={hadnleSaveEditedProfile}>Save</Button>
                                            <Button className='' variant='outline-primary' onClick={handleProfileCancelEdit}>Cancel</Button>
                                        </div>
                                    </div>
                                    <Card.Text className='d-flex align-items-center py-2'>
                                        <InputGroup.Text id="inputGroup-sizing-default" style={{ height: '37px' }}>
                                            <ion-icon name="briefcase-outline" style={{ color: '#45a994' }}></ion-icon>
                                            {/* <IoLocationOutline style={{color: '#45a994'}}/> */}
                                        </InputGroup.Text>
                                        <Form.Select
                                            className='w-50'
                                            aria-label="Default select example"
                                            onChange={(e) => setEditableUserDomain(e.target.value)}
                                            value={editableUserDomain}
                                        >
                                            <option selected disabled value={userDomain}>{userDomain}</option>
                                            {jobRoles.map((role, idx) => (
                                                <option key={idx} value={role.ID}>{role.Title}</option>
                                            ))
                                            }
                                            {/* <option value="12th Pass">12th Pass</option>
                                        <option value="Diploma">Diploma</option>
                                        <option value="ITI">ITI</option>
                                        <option value="Graduate">Graduation</option>
                                        <option value="Post Graduate">Post Graduation</option>
                                        <option value="PHD">PHD</option> */}
                                        </Form.Select>
                                    </Card.Text>

                                </>
                                :
                                <>
                                    <div className='d-flex justify-content-between'>
                                        <div className="user-name">
                                            <h3 className="name">{userInfo?.FullName} <span className="text-muted ps-1">{perticularApplication[0]?.Shortlist === true ? 'shortlisted' : ''}</span></h3>
                                            <h5 className="subtext">{userInfo?.CareerProfile?.degree}</h5>
                                        </div>
                                        {/* <span className="badge bg-primary ms-1 text-white">Edit</span> */}
                                        {role === 'Candidate' && <Button onClick={handleProfileEdit}>Edit Profile</Button>}
                                    </div>

                                </>
                            }
                        </div>
                    </div>
                </div>

                {/* User Statistics Start | Display None */}
                <div className="section full mt-2 d-none">
                    <div className="row">
                        <div className="col-lg-3 col-6 mb-2">
                            <div className="card">
                                <div className="card-body d-flex justify-content-between align-items-center flex-sm-col">
                                    <div className='db-bg-icon order-sm-1'>
                                        <ion-icon size="large" name="person-outline"></ion-icon>
                                    </div>
                                    <div className='order-sm-0'>
                                        <h5 className="card-title">1.7k+</h5>
                                        <h6 className="card-subtitle">Total Visitors</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-6 mb-2">
                            <div className="card">
                                <div className="card-body d-flex justify-content-between align-items-center flex-sm-col">
                                    <div className='db-bg-icon order-sm-1'>
                                        <ion-icon size="large" name="bookmarks-outline"></ion-icon>
                                    </div>
                                    <div className='order-sm-0'>
                                        <h5 className="card-title">03</h5>
                                        <h6 className="card-subtitle">Shortlisted</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-6 mb-2">
                            <div className="card">
                                <div className="card-body d-flex justify-content-between align-items-center flex-sm-col">
                                    <div className='db-bg-icon order-sm-1'>
                                        <ion-icon size="large" name="eye-outline"></ion-icon>
                                    </div>
                                    <div className='order-sm-0'>
                                        <h5 className="card-title">2.1k</h5>
                                        <h6 className="card-subtitle">Views</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-6 mb-2">
                            <div className="card">
                                <div className="card-body d-flex justify-content-between align-items-center flex-sm-col">
                                    <div className='db-bg-icon order-sm-1'>
                                        <ion-icon size="large" name="checkmark-done-outline"></ion-icon>
                                    </div>
                                    <div className='order-sm-0'>
                                        <h5 className="card-title">07</h5>
                                        <h6 className="card-subtitle">Applied Jobs</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* User Statistics End */}

                <div className="section mt-1 mb-2">
                    <div className="profile-info">
                        <div className=" bio">
                            {isEditingProfile ?
                                <>
                                    <Form.Group className='my-3'>
                                        <Form.Label>General Skills</Form.Label>
                                        <Select
                                            required
                                            onChange={(selectedOptions) => setEditableGenSkills(selectedOptions)}
                                            // defaultValue={defaultGeneralSkillOptions}
                                            value={editableGenSkills}
                                            isMulti
                                            name="skills"
                                            options={generalSkillOptions}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                        />
                                    </Form.Group>
                                    <Form.Group className='my-3'>
                                        <Form.Label>Professional Skills</Form.Label>
                                        <Select
                                            required
                                            onChange={(selectedOptions) => setEditableProSkills(selectedOptions)}
                                            // defaultValue={defaultProfessionalSkillOptions}
                                            value={editableProSkills}
                                            isMulti
                                            name="skills"
                                            options={professionalSkillOptions}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                        />
                                    </Form.Group>
                                    <div className="pb-2">
                                        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                            <Form.Label>Description</Form.Label>
                                            <Form.Control
                                                as="textarea"
                                                rows={2}
                                                maxLength={150}
                                                onChange={(e) => setEditableDescription(e.target.value)}
                                                value={editableDescription}
                                            />
                                        </Form.Group>
                                    </div>
                                </>
                                :
                                <Card.Text>
                                    <div className="pb-2">
                                        <h5>General Skills:</h5>
                                        <div>
                                            {userInfo?.SkillProfile?.GeneralSkills ?
                                                userInfo?.SkillProfile?.GeneralSkills.map((skill, index) => (
                                                    <div key={index} className="chip bg-transparent text-black ms-05 mb-1 skill-chip">
                                                        <span className="chip-label d-flex justify-content-center align-items-center gap-1"><Image className='tick-outlined' src={tick}/>{skill}</span>
                                                    </div>
                                                )) : <span className="text-muted">No General Skill Found</span>
                                            }
                                        </div>
                                    </div>
                                    <div className="pb-2">
                                        <h5>Professional Skills:</h5>
                                        <div>
                                            {userInfo?.SkillProfile?.ProfessionalSkills ?
                                                userInfo?.SkillProfile?.ProfessionalSkills.map((skill, index) => (
                                                    <div key={index} className="chip bg-transparent text-black ms-05 mb-1 skill-chip">
                                                    <span className="chip-label d-flex justify-content-center align-items-center gap-1"><Image className='tick-outlined' src={tick}/>{skill}</span>
                                                </div>
                                                )) : <span className="text-muted">No Professioanl Skill Found</span>
                                            }
                                        </div>
                                    </div>
                                    <div className="pb-2">
                                        <h5>Description:</h5>
                                        <div className='description'>
                                            {role === "Candidate" &&
                                                <>
                                                    {userInfo?.Description ? userInfo.Description : <p>Edit your profile to add description</p>}
                                                </>
                                            }
                                            {(role === "Admin" || role === "Employer") &&
                                                <>
                                                    {userInfo?.Description ? userInfo.Description : <p>No description found.</p>}
                                                </>
                                            }
                                        </div>
                                    </div>

                                    <div className="pb-2">
                                            <div className='description'>
                                            {role === "Candidate" &&
                                                <>
                                                    {
                                                        userInfo?.CV
                                                            ? <Button
                                                                variant="primary"
                                                                href={userInfo.CV}
                                                                download="User_CV"
                                                                target="_blank"
                                                            >
                                                                Download CV
                                                            </Button> : <p>You have not uploaded your CV yet.</p>
                                                    }
                                                </>
                                            }
                                            {(role === "Admin" || role === "Employer") &&
                                                <>
                                                    {
                                                        userInfo?.CV ? 
                                                        <Button
                                                            variant="primary"
                                                            href={userInfo.CV}
                                                            download="User_CV"
                                                            target="_blank"
                                                        > Download CV </Button>: 
                                                        <p>No CV found.</p>}
                                                </>
                                            }
                                        </div>
                                    </div>
                                    {/* upload CV */}
                                    { role === 'Candidate' &&
                                    <FileUploadWithProgress />
                                }
                                </Card.Text>
                            }
                        </div>
                    </div>
                </div>

                <div className="section full">
                    <div className="wide-block transparent p-0">
                        <ul className="nav nav-tabs lined iconed candidateProfile" role="tablist">
                            <li className="nav-item">
                                <a className="nav-link active" data-bs-toggle="tab" href="#education" role="tab">
                                    <i className="fa fa-graduation-cap me-1"></i>Education
                                    {/* <ion-icon name="grid-outline" role="img" className="md hydrated" aria-label="grid outline"></ion-icon> */}
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" data-bs-toggle="tab" href="#experience" role="tab">
                                    <i className="fa fa-briefcase me-1"></i>Experience
                                    {/* <ion-icon name="people-outline" role="img" className="md hydrated" aria-label="people outline"></ion-icon> */}
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>

                <div className="section full mb-2">
                    <div className="tab-content">
                        <div className="tab-pane fade show active" id="education" role="tabpanel">
                            <div className="add-education-btn position-relative pb-3 pt-2">
                                { role === 'Candidate' && <Button className='position-absolute z-index-10' variant="primary" onClick={() => setEduModalShow(true)} style={{ left: '3.4rem' }}>
                                    <FaPlus />
                                </Button>}
                            </div>
                            <div className="timeline timed">
                                {(userEduction && userEduction.length > 0) ?
                                    userEduction && userEduction
                                        .slice()
                                        .sort((a, b) => b.endYear - a.endYear)
                                        .map((education, idx) => {
                                            let startYear = parseInt(education.startYear);
                                            let endYear = parseInt(education.endYear);

                                            // Check if endYear is NaN, then set it to the current year
                                            if (isNaN(endYear)) {
                                                endYear = new Date().getFullYear();
                                            }

                                            const yearsDifference = endYear - startYear;
                                            const duration = `(${yearsDifference} Years)`;

                                            return (
                                                <div className="item" id={idx}>
                                                    <span className="time">{education?.startYear} - {education?.pursuing === true ? "pursuing" : education?.endYear} <br /><div className="text">{duration}</div></span>
                                                    <div className="dot"></div>
                                                    <div className="content">
                                                        <div className='d-flex align-items-center'>
                                                            <img src={logoPhoto} alt="avatar" className="imaged w48 rounded" />
                                                            <h4 className="title m-0">{education?.institution}</h4>
                                                        </div>
                                                        <div className="text">{education?.qualification}</div>
                                                        <div className="text">{education?.degree}</div>
                                                        {/* <div className="text">{duration}</div> */}
                                                        {role === 'Candidate' &&
                                                            <div className="d-flex gap-2">
                                                                <Button style={{ padding: "3px 10px", fontSize: "16px" }} className="PD-action-btn" onClick={() => handleShowEduUpdateModal(education)}><FaPen /></Button>
                                                                <Button style={{ padding: "3px 10px", fontSize: "16px" }} className="PD-action-btn" variant="danger" onClick={() => handleShowDeleteModal('education', education?.eduID)}><RiDeleteBin6Line /></Button>
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            )
                                        })
                                    : <div className="item">
                                        <div className="content">
                                            <div className='d-flex align-items-center'>
                                                <img src={logoPhoto} alt="avatar" className="imaged w48 rounded" />
                                                {role === "Candidate" &&
                                                    <h4 className="title m-0">Add your Education</h4>
                                                }
                                                {(role === "Admin" || role === "Employer") &&
                                                    <h4 className="title m-0">No Education Found</h4>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                }


                                <AddNewEducation
                                    refetchUserData={refetchUserData}
                                    show={eduModalShow}
                                    onHide={() => setEduModalShow(false)}
                                />

                                <DeleteEducation
                                    show={modalShow}
                                    onHide={() => setModalShow(false)}
                                    titleMessage={modalTitle}
                                    confirmDeleteMessage={modalMessage}
                                    handleDelete={handleDelete}
                                />

                                <UpdateEducationModal
                                    show={updateEduModalShow}
                                    onHide={() => setUpdateEduModalShow(false)}
                                    education={editabelEducation}
                                    refetchUserData={refetchUserData}
                                />


                            </div>
                        </div>

                        <div className="tab-pane fade" id="experience" role="tabpanel">
                            <div className="add-education-btn position-relative pb-3 pt-2">
                                {role === 'Candidate' && <Button className='position-absolute z-index-10' variant="primary" onClick={() => setExpModalShow(true)} style={{ left: '3.4rem' }}>
                                    <FaPlus />
                                </Button>}
                            </div>
                            <div className="timeline timed">
                                {(userExperience && userExperience.length > 0) ?
                                    userExperience && userExperience
                                        .slice()
                                        .sort((a, b) => {
                                            if (a.working && !b.working) return -1;
                                            if (!a.working && b.working) return 1;

                                            // Parse the end dates for sorting
                                            const endDateA = a.working ? new Date() : new Date(a.endYear);
                                            const endDateB = b.working ? new Date() : new Date(b.endYear);

                                            // Sort by end date (year and month)
                                            if (endDateA > endDateB) return -1;
                                            if (endDateA < endDateB) return 1;
                                            return 0;
                                        })
                                        .map((experience, idx) => {
                                            //// Parse the start date
                                            const startDate = new Date(experience.startYear);

                                            if (isNaN(startDate)) {
                                                console.error(`Invalid StartYear: ${experience.startYear}`);
                                                return null;
                                            }
                                            //// Get the month name and year
                                            const startMonth = new Intl.DateTimeFormat('en', { month: 'long' }).format(startDate);
                                            const startYear = startDate.getFullYear();

                                            //// Parse the end date or set it to the current year if undefined
                                            let endDate = new Date();
                                            if (experience.endYear) {
                                                endDate = new Date(experience.endYear);
                                                if (isNaN(endDate)) {
                                                    console.error(`Invalid EndYear: ${experience.endYear}`);
                                                    return null;
                                                }
                                            }

                                            //// Calculate the difference in years and months
                                            const yearsDifference = endDate.getFullYear() - startDate.getFullYear();
                                            const monthsDifference = endDate.getMonth() - startDate.getMonth();
                                            const totalMonths = yearsDifference * 12 + monthsDifference;

                                            //// Format the duration
                                            const years = Math.floor(totalMonths / 12);
                                            const months = totalMonths % 12;
                                            const duration = `(${years} Year${years !== 1 ? 's' : ''} ${months} Month${months !== 1 ? 's' : ''})`;

                                            return (
                                                <div className="item" id={idx} key={idx}>
                                                    <span className="time">{startMonth} {startYear} - {experience.working ? 'Present' : experience.endYear}</span>
                                                    <div className="dot"></div>
                                                    <div className="content">
                                                        <div className='d-flex align-items-center'>
                                                            <img src={logoPhoto} alt="avatar" className="imaged w48 rounded" />
                                                            <h4 className="title m-0">{experience.company}</h4>
                                                        </div>
                                                        <div className="text">{duration}</div>
                                                        <div className="text">{experience.designation}</div>
                                                        {role === 'Candidate' &&
                                                            <div className="d-flex gap-2">
                                                                <Button style={{ padding: "3px 10px", fontSize: "16px" }} className="PD-action-btn" onClick={() => handleShowExpUpdateModal(experience)}><FaPen /></Button>
                                                                <Button style={{ padding: "3px 10px", fontSize: "16px" }} className="PD-action-btn" variant="danger" onClick={() => handleShowDeleteModal('experience', null, experience.expID)}><RiDeleteBin6Line /></Button>
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            );
                                        })
                                    : <div className="item">
                                        <div className="content">
                                            <div className='d-flex align-items-center'>
                                                <img src={logoPhoto} alt="avatar" className="imaged w48 rounded" />
                                                {(role === "Candidate") &&
                                                    <h4 className="title m-0">Add your Experience</h4>
                                                }
                                                {(role === "Admin" || role === "Employer") &&
                                                    <h4 className="title m-0">No Experience found</h4>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                }

                                <AddNewExperience
                                    refetchUserData={refetchUserData}
                                    show={expModalShow}
                                    onHide={() => setExpModalShow(false)}
                                />


                                <UpdateExperienceModal
                                    show={updateExpModalShow}
                                    onHide={() => setUpdateExpModalShow(false)}
                                    experience={editableExperience}
                                    refetchUserData={refetchUserData}
                                />

                            </div>
                        </div>

                        <div className="d-flex justify-content-center mt-5">
                            <div className="">
                                {role === 'Candidate' &&
                                    <Button variant="primary" href='/'>
                                        Save & Exit
                                    </Button>
                                }
                            </div>
                            <div className="">
                                {(role === 'Employer') &&
                                    (perticularApplication.length > 0 ? 
                                        ((perticularApplication[0]?.Shortlist === true) ?
                                        <Button variant="primary" onClick={viewNextApplicant}>
                                            View Next Applicant
                                        </Button>
                                        : 
                                        <Button variant="primary" onClick={handlleShortlistAndNext}>
                                            Shortlist & View Next Applicant
                                        </Button>)
                                    :
                                    <Button variant="primary" onClick={handlleShortlistAndNext}>
                                            Shortlist & View Next Applicant
                                        </Button>
                                    )
                                }
                            </div>
                        </div>

                    </div>
                </div>

            </div>
        </div>
    )
}

const mapStateToProps = ({ auth }) => {
    const { userData, allCities, role, fullName, ID, authToken } = auth

    return {
        userData,
        allCities,
        role,
        fullName,
        ID,
        authToken
    }
}

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(CandidateProfile)


// Delete modal component
function DeleteEducation(props) {
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    {props.titleMessage}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h4>{props.confirmDeleteMessage}</h4>
                {/* <h4>Are you sure you want to delete this Placement Drive?</h4> */}
                {/* <p>
            This action cannot be undone. Once you confirm, the job will be permanently deleted from the system.
        </p> */}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="danger" onClick={props.handleDelete}>Confirm Delete</Button>
            </Modal.Footer>
        </Modal>
    )

}
