import React, {useEffect, useState} from 'react'
import { Link, useNavigate } from "react-router-dom";
import {Form, InputGroup, Button, Row, Col} from 'react-bootstrap'
import { connect } from 'react-redux';
import { setToken } from '../../../features/auth/authSlice';
import { fetchAllEduDegrees, fetchAllJobRoles, fetchAllSkills, getAllJobs, getSkillsFromJobRole, postNewJob } from '../../../features/constants/apis';
import Select from 'react-select';
// import { skillsOptions, generalSkillOptions, professionalSkillOptions } from '../../../features/constants/skillsData';
import { IoArrowBackCircleSharp } from 'react-icons/io5';
import { FaArrowLeft } from 'react-icons/fa';

const NewJobForm = ({ID, authToken, placementDriveId, refreshJobs, ...props}) =>{

    // Validation
    const [validated, setValidated] = useState(false);

    const [isSubmitting, setIsSubmitting] = useState(false);

    const [employerJob, setEmployerJob] = useState([]);

    const navigate = useNavigate();

    const [numValueOpenings, setNumValueOpenings] = useState('');
    const [numValueExperience, setNumValueExperience] = useState('');
    const [jobTitle, setJobTitle] = useState('');
    // const [jobCompanyName, setJobCompanyName] = useState('');
    const [jobEducation, setJobEducation] = useState('');
    const [jobEduDegree, setJobEduDegree] = useState([])
    const [jobExperience, setJobExperience] = useState('')
    const [jobType, setJobType] = useState('');
    const [jobCtc, setJobCtc] = useState('');
    const [selectLocation, setSelectLocation] = useState('')
    const [selectedGenSkills, setSelectedGenSkills] = useState(null);
    const [selectedProSkills, setSelectedProSkills] = useState(null);
    const [jobDescription, setJobDescription] = useState('')
    const [selectedJobRole, setSelectedJobRole] = useState('')

    const [jobRoles, setJobRoles] = useState('')
    const [selectedSkills, setSelectedSkills] = useState(null)

    // degree 
    const [degrees, setDegrees] = useState([])

    //Work model
    const [selectModel, setSelectModel] = useState('')

    const handleWorkModelChange = (e) =>{
        setSelectModel(e.target.value)
    }
    // console.log(selectModel)

    // fetching jobRoles
    useEffect(() => {
        const fetchJobRoles = async()=>{
            const data = await fetchAllJobRoles()
            setJobRoles(data.data)
        }
        fetchJobRoles()
    }, [])
    
    // fetching skills from jobroles
    // console.log(selectedJobRole)
    useEffect(()=>{
        const fetchskills = async()=>{
            if(selectedJobRole){
                const data = await getSkillsFromJobRole(authToken, selectedJobRole)
                // console.log(data)
                setSelectedSkills(data.data)
            }
        }
        fetchskills()
    }, [selectedJobRole])


    // skills
    const [getSkills, setGetSkills] = useState([]);


    const handleInputOpenings = (event) => {
        const newNumValue = event.target.value.replace(/\D/g, '');
        setNumValueOpenings(newNumValue);
    };

    const handleInputExperience = (event) => {
        const newNumValue = event.target.value.replace(/\D/g, '');
        setNumValueExperience(newNumValue);
    };

    // fetching degree from database
    useEffect(() => {
        const fetchDegree = async() =>{
            const data = await fetchAllEduDegrees(authToken)
            setDegrees(data)
            // console.log(data)
        }
        fetchDegree()
    }, [authToken])

    const degreeOptions = degrees.map((degree) => ({value: degree.Education, label: degree.Education}))

    const handleDegreeChange =(selectedOptions)=>{
        const selectedDegree = selectedOptions.map(option => option.value)
        setJobEduDegree(selectedDegree)
        console.log(selectedDegree)
    }

    // fetching skills from database
    useEffect(() => {
        const fetchData = async () => {
            const data = await fetchAllSkills(authToken);
            setGetSkills(data);
        }
        fetchData();
    }, [authToken])

    const allGeneralSkillOptions = getSkills.filter(skill => skill.Type === 0).map(skill => ({
        value: skill.Title,
        label: skill.Title,
    }));
    // console.log(allGeneralSkillOptions)

    const allProfessionalSkillOptions = getSkills.filter(skill => skill.Type === 1).map(skill => ({
        value: skill.Title,
        label: skill.Title,
    }))

    const generalSkillOptions = selectedSkills ? selectedSkills.GeneralSkills.map(skill => ({value:skill, label: skill})) : []

    const professionalSkillOptions = selectedSkills ? selectedSkills.ProfessionalSkills.map(skill => ({value:skill, label: skill})) : []

    // selectedSkills && selectedSkills.GeneralSkills.map((skill) => console.log(skill))


    const handleGeneralSkillsChange = (selectedOptions) => {
        // Extract the values of the selected options
        const selectedGenSkills = selectedOptions.map(option => option.value);
        // Update the state with the selected skills
        setSelectedGenSkills(selectedGenSkills);
    };
    const handleProfessionalSkillsChange = (selectedOptions) => {
        // Extract the values of the selected options
        const selectedProSkills = selectedOptions.map(option => option.value);
        // Update the state with the selected skills
        setSelectedProSkills(selectedProSkills);
    };


    const skillsData = {
        'GeneralSkills': selectedGenSkills,
        'ProfessionalSkills': selectedProSkills
    }



    const handleSubmit = async (event) => {
        event.preventDefault();
        const form = event.currentTarget;
    
        if (form.checkValidity() === false) {
            event.stopPropagation();
            setValidated(true);
            return;
        }
    
        const newJobFormData = {
            'JobTitle': jobTitle,
            "Role": selectedJobRole,
            'JobType': jobType,
            'OpenPositions': numValueOpenings,
            'EducationRequirement': jobEducation,
            'EducationDegree': jobEduDegree,
            'JobCTC': jobCtc,
            'JobLocation': selectLocation,
            'jobWorkModel': selectModel,
            'JobExperience': jobExperience,
            'JobSkills': skillsData,
            'PlacementDriveID': placementDriveId,
            'tokenUserID': ID
        };
    
        console.log("Submitting Job Data:", newJobFormData);
    
        setIsSubmitting(true);
    
        try {
            await postNewJob(newJobFormData, authToken);
    
            const updatedJobsData = await getAllJobs(authToken);
            const employerJobs = updatedJobsData.data.filter((item) => item.EmployerID === ID);
            console.log('All jobs after adding the new job:', employerJobs);
            
            // Sort and find the last added job
            employerJobs.sort((a, b) => a.ID - b.ID);
            const lastAddedJob = employerJobs[employerJobs.length - 1];
            const lastAddedJobID = lastAddedJob?.ID;
            console.log('ID of the last added job:', lastAddedJobID);
    
            if (placementDriveId) {
                console.log('PlacementDriveID present:', placementDriveId);
                props.onHide(); 
                refreshJobs();   
            } else {
                console.log('No PlacementDriveID, navigating to job details:', lastAddedJobID);
                navigate(`/job-details?Job=${lastAddedJobID}`);
            }
        } catch (error) {
            console.error("Error during job submission:", error);
        } finally {
            setIsSubmitting(false);
        }
    
        setValidated(true);
    };
    

    const handleHideForm = () => {
        if (!placementDriveId) navigate(`/jobs`)
            else props.onHide()
    }
    
    return(
        <div className={`section mb-2 full ${!placementDriveId ? 'mt-5' : 'mt-0'}`}>
            <div className="container pb-2 py-3">
                { !placementDriveId && 
                    <div className="d-flex align-items-center">
                        <Link to="/jobs" type="submit" className='my-2 pe-2' ><FaArrowLeft style={{width: '23px', height: '23px'}}/></Link> 
                        <h1 className='text-primary mb-0'>Post new job</h1>
                    </div>
                }
                {/* <Button to="" type="submit" className='my-2' onClick={sendBack}><IoArrowBackCircleSharp style={{width: '35px', height: '35px'}}/></Button>
                <h1 className='text-primary'>Add New Job</h1> */}
                <Form  noValidate validated={validated} onSubmit={handleSubmit} className={`${!placementDriveId ? 'my-5' : 'my-0'}`}>
                    <Form.Group className="mb-3" controlId="validationCustom01">
                        <Form.Label>Job Title</Form.Label>
                        <Form.Control
                        required
                        type="text"
                        onChange={(e) => setJobTitle(e.target.value)}
                        placeholder="Enter Job Title"
                        />
                        <Form.Control.Feedback type="invalid">
                            Please provide Job Title.
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className='my-3'>
                        <Form.Label>Job Role</Form.Label>
                        <Form.Select aria-label="Default select example" 
                            required 
                            onChange={(e) => setSelectedJobRole(e.target.value)}
                            >
                            <option selected disabled value="">Select Job Role</option>
                            {jobRoles && jobRoles.map((role, idx)=> 
                                <option value={role.Title}>{role.Title}</option>
                            )}
                        </Form.Select>
                        <Form.Control.Feedback type="invalid">
                            Please provide a Education qualification.
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Job Openings</Form.Label>
                        <Form.Control
                        required
                        type="text"
                        value={numValueOpenings}
                        onChange={handleInputOpenings}
                        placeholder="Enter number of Openings"
                        />
                        <Form.Control.Feedback type="invalid">
                            Please provide a valid number of Openings.
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className='my-3'>
                        <Form.Label>Experience</Form.Label>
                        <Form.Select aria-label="Default select example" 
                            required 
                            onChange={(e) => setJobExperience(e.target.value)}>
                            <option selected disabled value="">Select your Experience Required</option>
                            <option value="Fresher">Fresher</option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>
                            <option value="More than 6">More than 6</option>
                        </Form.Select>
                        <Form.Control.Feedback type="invalid">
                            Please provide a Education qualification.
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className='my-3'>
                        <Form.Label>Education Qualification</Form.Label>
                        <Form.Select aria-label="Default select example" 
                            required 
                            onChange={(e) => setJobEducation(e.target.value)}>
                            <option disabled selected value="">Select your educational qualification</option>
                            <option value="12th Pass">12th Pass</option>
                            <option value="Diploma">Diploma</option>
                            <option value="ITI">ITI</option>
                            <option value="Graduation">Graduate</option>
                            <option value="Post Graduatino">Post graduate</option>
                            <option value="PHD">PHD</option>
                        </Form.Select>
                        <Form.Control.Feedback type="invalid">
                            Please provide a Education qualification.
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className='my-3'>
                        <Form.Label>Select Required Degrees</Form.Label>
                        <Select
                            required 
                            onChange={handleDegreeChange}
                            // defaultValue={[generalSkillOptions[2], generalSkillOptions[3]]}
                            isMulti
                            name="degrees"
                            options={degreeOptions}
                            className="basic-multi-select"
                            classNamePrefix="select"
                        />
                    </Form.Group>
                    <Form.Group className='my-3'>
                        <Form.Label>General Skills</Form.Label>
                        <Select
                            required 
                            onChange={handleGeneralSkillsChange}
                            // defaultValue={[generalSkillOptions[2], generalSkillOptions[3]]}
                            isMulti
                            name="skills"
                            options={selectedSkills ? generalSkillOptions : allGeneralSkillOptions}
                            className="basic-multi-select"
                            classNamePrefix="select"
                        />
                    </Form.Group>
                    <Form.Group className='my-3'>
                        <Form.Label>Professional Skills</Form.Label>
                        <Select
                            required 
                            onChange={handleProfessionalSkillsChange}
                            // defaultValue={[professionalSkillOptions[2], professionalSkillOptions[3]]}
                            isMulti
                            name="skills"
                            options={selectedSkills ? professionalSkillOptions : allProfessionalSkillOptions}
                            className="basic-multi-select"
                            classNamePrefix="select"
                        />
                    </Form.Group>
                    <Form.Group className='my-3'>
                        <Form.Label>Type of Job</Form.Label>
                        <Form.Select aria-label="Default select example"
                            required 
                            onChange={(e) => setJobType(e.target.value)}>
                            <option disabled selected value="">Select required type of job</option>
                            <option value="Full Time">Full Time</option>
                            <option value="Part Time">Part Time</option>
                            <option value="Internship">Internship</option>
                        </Form.Select>
                        <Form.Control.Feedback type="invalid">
                            Please provide a job type.
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className='my-3'>
                        <Form.Label>Offered CTC</Form.Label>
                        <Form.Select aria-label="Default select example"
                            required 
                            onChange={(e) => setJobCtc(e.target.value)}>
                            <option disabled selected value="">Select range from drop-down</option>
                            <option value="1.2 to 2.4">1.2 to 2.4 LPA</option>
                            <option value="2.5 to 3">2.5 to 3 LPA</option>
                            <option value="3 to 4.2">3 to 4.2 LPA</option>
                            <option value="4.3 to 5">4.3 to 5 LPA</option>
                            <option value="5 to 7">5 to 7 LPA</option>
                            <option value="7 to 9">7 to 9 LPA</option>
                            <option value="9 to 12">9 to 12 LPA</option>
                            <option value="12 to 15">12 to 15 LPA</option>
                            <option value="15 to 18">15 to 18 LPA</option>
                            <option value="18 to 21">18 to 21 LPA</option>
                            <option value="21 to 24">21 to 24 LPA</option>
                            <option value="24 to 30">24 to 30 LPA</option>
                            <option value="30+">30+ LPA</option>
                        </Form.Select>
                    </Form.Group>
                    <Form.Group className='my-3'>
                        <Form.Label>Job Description</Form.Label>
                        <Form.Control 
                            as="textarea"  
                            rows={3}
                            required 
                            placeholder="Enter Job Description"
                            onChange={(e) => setJobDescription(e.target.value)}>
                        </Form.Control>
                    </Form.Group>
                    <Form.Group className='my-3'>
                        {/* <Form.Label>Location</Form.Label> */}
                        <div className="w-25 d-flex" style={{height: '37px'}}>
                            <InputGroup.Text id="inputGroup-sizing-default" style={{height: '37px'}}>
                                <ion-icon name="location-outline" style={{color: '#45a994'}}></ion-icon>
                                {/* <IoLocationOutline style={{color: '#45a994'}}/> */}
                            </InputGroup.Text>
                        
                            <Form.Select 
                                required 
                                aria-label="Default select example"
                                onChange={(e) => setSelectLocation(e.target.value)}
                                >
                                <option selected disabled value="">Select location</option>
                                <option value="Mumbai">Mumbai</option>
                                <option value="Navi Mumbai">Navi Mumbai</option>
                                <option value="Panvel">Panvel</option>
                                <option value="Pune">Pune</option>
                            </Form.Select>
                        </div>
                    </Form.Group>
                    <Form.Group className='my-3'>
                        <Form.Label>Choose Work Model</Form.Label>
                        <Form.Check
                            id='1'
                            required
                            value="Remote"
                            type="radio"
                            label="Remote"
                            name="test" 
                            onChange={handleWorkModelChange}
                        />
                        <Form.Check
                            id='2'
                            required
                            value="OnSite"
                            type="radio"
                            label="OnSite"
                            name="test" 
                            onChange={handleWorkModelChange}
                        />
                        <Form.Check
                            id='3'
                            required
                            value="Hybrid"
                            type="radio"
                            label="Hybrid"
                            name="test" 
                            onChange={handleWorkModelChange}
                        />
                    </Form.Group>
                    <div className="d-flex gap-2">
                    <Button type="submit" variant="primary" disabled={isSubmitting}>
                        {isSubmitting ? 'Submitting...' : (placementDriveId ? 'Submit' : 'Submit and search for candidates')}
                    </Button>
                        <Button type="button" variant="outline-primary" onClick={handleHideForm}>Cancel</Button>
                    </div>
                </Form>
            </div>
        </div>
    )
    
}


const mapStateToProps = ({auth}) => {
    const {ID, authToken} = auth

    return {
        ID,
        authToken
    }
}

const mapDispatchToProps = {
    setToken
}

export default connect(mapStateToProps, mapDispatchToProps)(NewJobForm);