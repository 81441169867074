import React from 'react'
import { Row, Col, Card} from 'react-bootstrap'
import { ShimmerCircularImage } from "react-shimmer-effects";
import { ShimmerTitle } from "react-shimmer-effects";
import { ShimmerText } from "react-shimmer-effects";

const ShimmerJobDetails = () => {
  return (
    <div>
                {/* <Row xs={1} md={1} className="g-4">
                    <Col>
                        <Card className='placement-drive-card'>
                            <Card.Body>
                            <Row className='pb-2 border-bottom border-gray pd-card-head'>
                                <Col lg="3" md='3' xs='3'><ShimmerCircularImage size={95} /></Col>
                                <Col lg="9" md='9' xs='9'>
                                    <Card.Title><ShimmerTitle line={2} gap={10} variant="primary" /></Card.Title>
                                </Col>
                            </Row>
                            <Card.Text className='py-2'><ShimmerTitle line={2} gap={10} variant="primary" /></Card.Text>
                            <div className='d-flex justify-content-between align-items-center gap-3' style={{height: "50px"}}>
                                    <ShimmerText line={2} gap={10} variant="primary" />
                                </div> 
                            </Card.Body>
                        </Card>
                    </Col>                        
                    
                </Row> */}
                <Row>
                <Col>
                    <Card className='placement-drive-card'>
                        <Card.Body>
                        <Row className='pb-2 border-bottom border-gray pd-card-head'>
                            <Col lg="1"><ShimmerCircularImage size={70} /></Col>
                            <Col>
                            <ShimmerTitle line={1} gap={10} variant="primary" />
                            <ShimmerText line={1} gap={3}  variant="primary" width="200px" />
                            </Col>
                        </Row>
                        {/* <Card.Text className='py-3 min-h-6'>{jobData?.Criteria}</Card.Text> */}
                        <div className='py-4'>   
                            <ShimmerText line={2} gap={10} variant="primary" /> 
                        </div>
                        </Card.Body>
                    </Card>
                </Col>
                </Row>
                </div>
    
  )
}

export default ShimmerJobDetails