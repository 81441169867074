import React, { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import { Form, Button, InputGroup } from 'react-bootstrap'
import DatePicker from "react-datepicker";
import { fetchAllJobRoles, postUserExperience } from '../../../features/constants/apis';
import { connect } from "react-redux";

const AddNewExperience = ({ID,authToken,refetchUserData, ...props}) => {

    const [validated, setValidated] = useState(false)
    const [jobRoles, setJobRoles] = useState([])

    const [expID, setExpID] = useState(1)
    const [selectedStartYear, setSelectedStartYear] = useState(new Date().getFullYear());
    const [selectedEndYear, setSelectedEndYear] = useState(new Date().getFullYear());
    const [working, setWorking] = useState(false);
    const [comapnyName, setComapnyName] = useState('')
    const [designation, setDesignation] = useState('')

    // Date picker
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());

    const startMonthYear = startDate.toLocaleDateString('en-US', { month: 'short', year: 'numeric' });
    const endMonthYear = endDate.toLocaleDateString('en-US', { month: 'short', year: 'numeric' });
    // console.log(startMonthYear)
    // console.log(endMonthYear)

    useEffect(()=> {
        const fetchJobRoles = async() =>{
            const data = await fetchAllJobRoles(authToken);
            setJobRoles(data.data)
        }
        fetchJobRoles()
    }, [authToken])


    const handleWorkingChange = (e) =>{
        setWorking(e.target.checked)
        setSelectedEndYear(e.target.checked && null)
    }   

    const handleSubmit = async(e) =>{
        e.preventDefault();
        const form = e.currentTarget;
        if (form.checkValidity() === false) {
            e.stopPropagation();
            setValidated(true);
        } else{
            // console.log('form is submitting...')

            const experienceData = {
                'expID': expID,
                'startYear': startMonthYear,
                'endYear': working ? '' : endMonthYear,
                'company': comapnyName,
                'designation': designation,
                'working': working
            }

            // console.log(experienceData)

            await postUserExperience(ID, experienceData, authToken)
            // console.log(ID)

            refetchUserData()
            props.onHide()

            setComapnyName('')
            setValidated(false);
        }
    }
    // console.log(startDate)

    const handleCloseModal = () => {
        props.onHide()
        setComapnyName('')
        setValidated(false)
    };

    

  return (
    <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Add Experience
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                    <div className="d-flex gap-3 my-2">
                        <div className="start-date d-flex flex-column">
                            <Form.Label>Start Date</Form.Label>
                            <div className="d-flex">
                                <InputGroup.Text id="inputGroup-sizing-default">
                                    <ion-icon name="calendar-outline" style={{color: '#45a994'}}></ion-icon>
                                </InputGroup.Text>
                                <DatePicker
                                    style={{height: '100%'}}
                                    showMonthYearPicker
                                    selected={startDate}
                                    onChange={(date) => setStartDate(date)}
                                    icon="fa fa-calendar" 
                                    dateFormat= "MM/yyyy"
                                    maxDate={new Date()}
                                />
                            </div>
                        </div>
                        <div className="end-date d-flex flex-column">
                            <Form.Label>End Date</Form.Label>
                            <div className="d-flex">
                                <InputGroup.Text id="inputGroup-sizing-default">
                                    <ion-icon name="calendar-outline" style={{color: '#45a994'}}></ion-icon>
                                </InputGroup.Text>
                                <DatePicker
                                    disabled={working}
                                    style={{height: '100%'}}
                                    showMonthYearPicker
                                    selected={endDate}
                                    onChange={(date) => setEndDate(date)}
                                    icon="fa fa-calendar" 
                                    dateFormat="MM/yyyy" 
                                    minDate={startDate}
                                />
                            </div>
                        </div>
                        <div className="end-date d-flex flex-column">
                            <Form.Label>Currently Working</Form.Label>
                            <InputGroup className="mb-3">
                                <InputGroup.Checkbox aria-label="Checkbox for following text input" checked={working} 
                                onChange={handleWorkingChange}
                                />
                                {/* <Form.Control aria-label="Text input with checkbox" /> */}
                            </InputGroup>
                        </div>
                    </div>
                    <div className="my-2">
                        <Form.Group className="mb-3">
                            <Form.Label>Company Name</Form.Label>
                            <Form.Control
                                required
                                type="text"
                                onChange={(e)=>setComapnyName(e.target.value)}
                                value={comapnyName}
                                placeholder="Enter Company Name"
                            />
                            <Form.Control.Feedback type="invalid">
                                Please provide a valid Title.
                            </Form.Control.Feedback>
                        </Form.Group>
                    </div>
                    <div className="my-2">
                        <Form.Group className='my-3'>
                            <Form.Label>Designation</Form.Label>
                            <Form.Select 
                                required
                                onChange={(e)=>setDesignation(e.target.value)}
                                aria-label="Default select example">
                                <option selected disabled value="">Select Your Designation</option>
                                {
                                    jobRoles && jobRoles.map((role, idx) => (
                                        <option key={role.ID} value={role.Title}>{role.Title}</option>
                                    ))
                                }
                            </Form.Select>
                        </Form.Group>
                    </div>
                    {/* <div className="my-2">
                        <Form.Group className='my-3'>
                            <Form.Label>Education Degree</Form.Label>
                            <Form.Select 
                                required
                                onChange={(e)=>setEduDegree(e.target.value)}
                                aria-label="Default select example">
                                <option selected disabled value="">Select your educational degree</option>
                                {
                                    eduDegrees.map((degree, idx) => (
                                        <option key={degree.ID} value={degree.Education}>{degree.Education}</option>
                                    ))
                                }
                            </Form.Select>
                        </Form.Group>
                    </div> */}
                    <Modal.Footer>
                        <Button type="submit">Add</Button>
                        <Button variant='outline-primary' onClick={handleCloseModal}>Close</Button>
                    </Modal.Footer>
                </Form>

            </Modal.Body>
        </Modal>
  )
}

const mapStateToProps = ( { auth } ) => {
    const { userData, role, ID, authToken } = auth
  
    return {
        userData,
        role,
        ID,
        authToken
    }
  }


export default connect(mapStateToProps)(AddNewExperience)