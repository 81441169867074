import React from 'react'
import { Row, Col, Form, Button, InputGroup } from 'react-bootstrap'

function Hiring() {
  return (
    <div id="appCapsule">
        <div className="section mb-2 mt-2 full">
            <div className="container pb-2">
                <h1 className='text-primary'>New Hirings</h1>
                <Form>
                    <Form.Group className='my-3'>
                        <Form.Label>Select Opening</Form.Label>
                        <Form.Select aria-label="Default select example">
                            <option>Select Opening</option>
                            <option value="1">Opening 1 </option>
                            <option value="2">Opening 2</option>
                            <option value="3">Opening 3</option>
                        </Form.Select>
                    </Form.Group>
                    <Form.Group className='my-3'>
                        <Form.Label>Number of openings for the role</Form.Label>
                        <Form.Control type="number"/>
                    </Form.Group>
                    <Form.Group className='my-3'>
                        <Form.Label>Education Qualification</Form.Label>
                        <Form.Select aria-label="Default select example">
                            <option>Select your educational qualification</option>
                            <option value="1">12th Pass</option>
                            <option value="2">Diploma</option>
                            <option value="3">ITI</option>
                            <option value="4">Graduate</option>
                            <option value="5">Post graduate</option>
                            <option value="6">PHD</option>
                        </Form.Select>
                    </Form.Group>
                    <Form.Group className='my-3'>
                        <Form.Label>Experience in years</Form.Label>
                        <Form.Control type="number"/>
                    </Form.Group>
                    <Form.Group className='my-3'>
                        <Form.Label>Education stream</Form.Label>
                        <Form.Check // prettier-ignore
                            type="checkbox"
                            id={`HTML`}
                            label={`HTML`}
                        />
                        <Form.Check // prettier-ignore
                            type="checkbox"
                            id={`CSS`}
                            label={`CSS`}
                        />
                        <Form.Check // prettier-ignore
                            type="checkbox"
                            id={`JS`}
                            label={`JS`}
                        />
                    </Form.Group>
                    <Form.Group className='my-3'>
                        <Form.Label>Type of Job</Form.Label>
                        <Form.Select aria-label="Default select example">
                            <option>Select required type of job</option>
                            <option value="1">Full Time</option>
                            <option value="2">Part Time</option>
                            <option value="3">Internship</option>
                        </Form.Select>
                    </Form.Group>
                    <Form.Group className='my-3'>
                        <Form.Label>Offered CTC</Form.Label>
                        <Form.Select aria-label="Default select example">
                            <option>Select range from drop-down</option>
                            <option value="1">1 Lakh to 5 Lakh</option>
                            <option value="2">6 Lakh to 10 Lakh</option>
                            <option value="3">11 Lakh to 15 Lakh</option>
                            <option value="4">15 Lakh and above</option>
                        </Form.Select>
                    </Form.Group>
                </Form>
                <Button as="input" type="submit" value="Submit" />
            </div>
        </div>
    </div>
  )
}

export default Hiring
