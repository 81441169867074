import { configureStore } from '@reduxjs/toolkit'
import appToastReducer from '../features/utilities/appToastSlice'
import authReducer from '../features/auth/authSlice'
import locationModalReducer from '../features/popup/locationModalSlice';
import selectedDriveReducer from '../features/data/selectedDriveSlice'

export const store = configureStore({
  reducer: {
    appToast: appToastReducer,
    auth: authReducer,
    locationModal: locationModalReducer,
    selectedDrive: selectedDriveReducer, 
  },
});