import { useEffect } from "react";
import { useState } from "react";
import { Accordion, Badge, Button, Col, Form, Row } from "react-bootstrap"
import { FaArrowLeft } from "react-icons/fa"
import { IoFunnel, IoFunnelOutline } from "react-icons/io5"
import { connect } from "react-redux";
import { Link } from "react-router-dom"
import { getAllJobs } from "../../features/constants/apis";
import ShimmerJobs from "./Shimmer/ShimmerJobs";
import JobListCard from "./JobListCard";


function EmployerJobs({ID, role, authToken}) {

    const [activeKey, setActiveKey] = useState(null);

    const [allJobs, setAllJobs] = useState([]);

    useEffect(() => {
        const fetchAllJobs = async () => {
            const data = await getAllJobs(authToken);
            // const filteredJobs = data.data.filter(job => job.PlacementDriveID === null)
            setAllJobs(data.data);
            // console.log(ID)
        }
        fetchAllJobs();
    }, [authToken]);

    const handleToggleAccordion = () => {
        setActiveKey(activeKey === '0' ? null : '0');
    };

    return(
        <div id="appCapsule">
            <section className="section full pt-4">
                <div className='container'>
                    <Link to="/" type="submit" className='my-2 text-white'>
                        <Button variant="primary mb-2"><FaArrowLeft style={{ width: '15px', height: '15px', marginRight: "7px" }} />Go to Dashboard
                        </Button>
                    </Link>
                    <Row>
                        <Col className='d-flex justify-content-between'>
                            <div>
                                <div className="d-flex align-items-center gap-2">
                                    <h1 className="title center text-dark" style={{ margin: "0" }}>Job Listing</h1>
                                    <div>
                                        <Link className='d-flex' to="" onClick={handleToggleAccordion}>
                                            {activeKey === '0' ?
                                                <>
                                                    <Badge style={{ color: "#45a994" }} bg="none"><IoFunnel style={{ height: 'auto', width: '25px' }} /> </Badge>
                                                </>
                                                :
                                                <>
                                                    <Badge style={{ color: "#45a994" }} bg="none"><IoFunnelOutline style={{ height: 'auto', width: '25px' }} /> </Badge>
                                                </>

                                            }
                                        </Link>
                                    </div>

                                </div>
                                {role === "Candidate" ? <div className="section-title ps-0 text-dark">Top jobs matching your profile</div> : <div className="section-title ps-0 text-dark">Your Jobs</div>}
                                {/* <p className='text-dark'>We have considered your profi  le and the inputs given by you and have pulled some of the jobs from our recruitement partners.</p> */}
                            </div>
                        </Col>
                    </Row>
                </div>
            </section>

            <section className="container section full mt-2 mb-4">
                <div className='container'>
                    <Row>
                        <Col md="12">
                            {/* <div className='top-sec-heading'>
                            <h4>Filter By</h4>
                        </div> */}
                            <Accordion activeKey={activeKey}>
                                <Accordion.Item eventKey="0">
                                    {/* <Accordion.Header><h2>Filter By</h2></Accordion.Header> */}
                                    <Accordion.Body>
                                        <Row>
                                            <Col md="4">
                                                <Accordion style={{ borderTop: 'none' }}>
                                                    <Accordion.Item eventKey="0">
                                                        <Accordion.Header>Location</Accordion.Header>
                                                        <Accordion.Body>
                                                            <Form.Select aria-label="Default select example">
                                                                <option>Select your location</option>
                                                                <option value="1">Mumbai</option>
                                                                <option value="2">Navi Mumbai</option>
                                                                <option value="3">Pune</option>
                                                            </Form.Select>
                                                        </Accordion.Body>
                                                    </Accordion.Item>
                                                </Accordion>
                                            </Col>
                                            <Col md="4">
                                                <Accordion>
                                                    <Accordion.Item eventKey="1">
                                                        <Accordion.Header>Job Type</Accordion.Header>
                                                        <Accordion.Body>
                                                            <Form className='wide-block py-3'>
                                                                <Form.Check
                                                                    label="Full time"
                                                                    name="group1"
                                                                    type='radio'
                                                                    id={`job-type-1`}
                                                                />
                                                                <Form.Check
                                                                    label="Part time"
                                                                    name="group1"
                                                                    type='radio'
                                                                    id={`job-type-2`}
                                                                />
                                                                <Form.Check
                                                                    label="Freelance"
                                                                    name="group1"
                                                                    type='radio'
                                                                    id={`job-type-3`}
                                                                />
                                                            </Form>
                                                        </Accordion.Body>
                                                    </Accordion.Item>
                                                </Accordion>
                                            </Col>
                                            <Col md="4">
                                                <Accordion>
                                                    <Accordion.Item eventKey="2">
                                                        <Accordion.Header>Experience</Accordion.Header>
                                                        <Accordion.Body>
                                                            <Form className='wide-block py-3'>
                                                                <Form.Check
                                                                    label="Fresher"
                                                                    name="group2"
                                                                    type='radio'
                                                                    id={`xp-1`}
                                                                />
                                                                <Form.Check
                                                                    label="Intermediate"
                                                                    name="group2"
                                                                    type='radio'
                                                                    id={`xp-2`}
                                                                />
                                                                <Form.Check
                                                                    label="Expert"
                                                                    name="group2"
                                                                    type='radio'
                                                                    id={`xp-3`}
                                                                />
                                                            </Form>
                                                        </Accordion.Body>
                                                    </Accordion.Item>
                                                </Accordion>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md="4">
                                                <Accordion>
                                                    <Accordion.Item eventKey="3">
                                                        <Accordion.Header>Salary</Accordion.Header>
                                                        <Accordion.Body>
                                                            <Form.Label>Range</Form.Label>
                                                            <Form.Range />
                                                            <Form className='wide-block py-3'>
                                                                <Form.Check
                                                                    label="3-5 LPA"
                                                                    name="group3"
                                                                    type='radio'
                                                                    id={`salary-1`}
                                                                />
                                                                <Form.Check
                                                                    label="5-7 LPA"
                                                                    name="group3"
                                                                    type='radio'
                                                                    id={`salary-2`}
                                                                />
                                                                <Form.Check
                                                                    label="7-10 LPA"
                                                                    name="group3"
                                                                    type='radio'
                                                                    id={`salary-3`}
                                                                />
                                                            </Form>
                                                        </Accordion.Body>
                                                    </Accordion.Item>
                                                </Accordion>
                                            </Col>
                                            <Col md="4">
                                                <Accordion>
                                                    <Accordion.Item eventKey="4">
                                                        <Accordion.Header>Category</Accordion.Header>
                                                        <Accordion.Body>
                                                            <Form className='wide-block py-3'>
                                                                <Form.Check
                                                                    label="Developer"
                                                                    name="group3"
                                                                    type='checkbox'
                                                                    id={`category-1`}
                                                                />
                                                                <Form.Check
                                                                    label="Finance"
                                                                    name="group3"
                                                                    type='checkbox'
                                                                    id={`category-2`}
                                                                />
                                                                <Form.Check
                                                                    label="Design"
                                                                    name="group3"
                                                                    type='checkbox'
                                                                    id={`category-3`}
                                                                />
                                                            </Form>
                                                        </Accordion.Body>
                                                    </Accordion.Item>
                                                </Accordion>
                                            </Col>
                                            <Col md="4">
                                                <Accordion>
                                                    <Accordion.Item eventKey="5">
                                                        <Accordion.Header>Tags</Accordion.Header>
                                                        <Accordion.Body>
                                                            <Form className='wide-block py-3'>
                                                                <Form.Check
                                                                    label="Developer"
                                                                    name="group3"
                                                                    type='checkbox'
                                                                    id={`Tags-1`}
                                                                />
                                                                <Form.Check
                                                                    label="Finance"
                                                                    name="group3"
                                                                    type='checkbox'
                                                                    id={`Tags-2`}
                                                                />
                                                                <Form.Check
                                                                    label="Design"
                                                                    name="group3"
                                                                    type='checkbox'
                                                                    id={`Tags-3`}
                                                                />
                                                            </Form>
                                                        </Accordion.Body>
                                                    </Accordion.Item>
                                                </Accordion>
                                            </Col>
                                        </Row>
                                        <div className='job-filter-box'>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="12 pt-4">
                            <div className='top-sec-heading'>
                                {allJobs.filter(item => item.EmployerID === ID).length >= 0 ? 
                                    (allJobs.filter(item => item.EmployerID === ID).length > 0 && <h4> {allJobs.filter(item => item.EmployerID === ID).length} jobs found</h4>) : ' No Job Found'
                                } 
                            </div>

                            {allJobs.length === null ? <ShimmerJobs/> 
                            :
                            <JobListCard
                                allJobs={allJobs}
                            />
                            }
                            

                        </Col>
                    </Row>
                </div>
            </section>
            
        </div>
    )

}

const mapStateToProps = ({auth}) =>{
    const {role, fullName, userData, ID, authToken} = auth;
    return {
        role,
        fullName,
        userData,
        ID,
        authToken
    }
}



export default connect(mapStateToProps)(EmployerJobs)