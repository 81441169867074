import React, { useEffect, useState, useRef } from 'react'
import { connect } from 'react-redux'
import { Card, Button, Form, InputGroup, Modal } from "react-bootstrap";
import { useLocation } from 'react-router';
// import userPhoto from '../images/user-profile.webp';
import userPhoto from '../images/profile-placeholder.jpeg'
import { GetCandidateByID, UpdateUserProfile, postUserImage, updateEmployerProfile } from '../../features/constants/apis';
import axios from 'axios';
import { Link } from 'react-router-dom';

const EmployerProfile = ({ fullName, role, authToken, ID }) => {

    console.log(ID)

    // photo upload
    const [uploadedImage, setUploadedImage] = useState('');
    const [fileUploaded, setFileUploaded] = useState(false);
    const [previewImage, setPreviewImage] = useState(null);
    const [imageSet, setImageSet] = useState(false);
    const fileInputRef = useRef(null);


    const [userInfo, setUserInfo] = useState([])
    const [isEditingProfile, setIsEditingProfile] = useState(false)

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const candidateID = searchParams.get('candidate');


    // Edit Bio
    const [editableUserFullName, setEditableUserFullName] = useState('');
    const [editableUserDomain, setEditableUserDomain] = useState('');
    const [editableDescription, setEditableDescription] = useState('');
    const [editableCompanyAddress, setEditableCompanyAddress] = useState('');
    const [editableCompanyWebsite, setEditableCompanyWebsite] = useState('');

    useEffect(() => {
        const EmployerData = async () => {
            if (role === 'Employer') {
                const data = await GetCandidateByID(ID, authToken);
                setUserInfo(data.data);
                console.log(data.data)
            }
        }
        EmployerData()
    }, [ID, authToken, role])

    // Edit user profile
    const handleProfileEdit = () => {

        setEditableUserFullName(userInfo?.FullName || '');
        setEditableDescription(userInfo?.Description || '');
        setEditableCompanyAddress(userInfo?.Address || '');
        setEditableCompanyWebsite(userInfo?.Website || '');

        if (role === 'Employer') {
            setIsEditingProfile(true)
        }
    }

    console.log(editableUserFullName)

    const hadnleSaveEditedProfile = async() => {

        const editedUserInfo = {
            'employerName': editableUserFullName,
            'companyDescription': editableDescription,
            'companyAddress': editableCompanyAddress,
            'companyWebsite': editableCompanyWebsite,
        }
        console.log(editedUserInfo)

        const EditUserProfile = async () => {
            console.log(ID)
            const updateProfile = await updateEmployerProfile(authToken, ID, editedUserInfo)
            console.log(updateProfile)

            const data =await GetCandidateByID(ID, authToken);
            setUserInfo(data.data);
        }
        EditUserProfile()

        

        setIsEditingProfile(false)
    }

    const handleProfileCancelEdit = () => {
        setEditableUserFullName(userInfo?.FullName)
        setEditableDescription(userInfo?.Description)
        setEditableDescription(userInfo?.Address)
        setEditableDescription(userInfo?.Website)
        setIsEditingProfile(false)
    }

    const uploadRefImage = async (filePath) => {
        setFileUploaded(true);
        const formData = new FormData();
        formData.append('inputFile', filePath);
        try {
            const res = await axios.post('https://api.allpos.in/applications/upload/scopehai', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
            });
            console.log(res.data.data);
            setUploadedImage(res.data.data);
            setFileUploaded(false);
            return res.data.data; // Return the uploaded image URL
        } catch (err) {
            console.error('Error uploading file:', err);
            setFileUploaded(false);
            throw err;
        }
    };

    console.log(uploadedImage)

    const handleImageChange = async (e) => {
        const file = e.target.files[0];
        if (file) {
            setPreviewImage(URL.createObjectURL(file));
            try {
                const imageUrl = await uploadRefImage(file); // Wait for the image URL
                await handleSetImage(imageUrl); // Call handleSetImage with the image URL
            } catch (error) {
                console.error('Error handling image change:', error);
            }
        }
    };

    const handleSetImage = async (imageUrl) => {
        console.log('image uploaded');

        const imageUrlObject = {
            image: imageUrl
        };

        await postUserImage(ID, imageUrlObject, authToken);

        setUserInfo(prevUserInfo => ({
            ...prevUserInfo,
            Image: imageUrl // Use the provided image URL
        }));

        setImageSet(true);
        setPreviewImage(null);

        fileInputRef.current.value = '';
    }



    return (
        <div id="appCapsule">
            <div className='container candidate-profile'>

                <div className="section mt-2">
                    <div className="profile-head">
                        <div className="avatar d-flex flex-column align-items-center gap-1">
                            <img
                                src={userInfo.Image || userPhoto}
                                alt="avatar"
                                className="imaged w64 rounded profile-pic"
                            />
                            {role === 'Employer' &&
                                <>
                                    <Form.Control
                                        id='fileUpload'
                                        type="file"
                                        accept='image/*'
                                        custom
                                        label='Choose Image'
                                        onChange={handleImageChange}
                                        className="btn btn-outline-primary btn-sm cursor-pointer "
                                        style={{ padding: '0.2rem 0.2rem', lineHeight: '1.5', display: 'none' }}
                                        ref={fileInputRef}
                                    />


                                    {fileUploaded ? (
                                        <span className='text-nowrap'>uploading...</span>
                                    ) : (
                                        <label htmlFor="fileUpload" className='cursor-pointer profile-image-upload' style={{ whiteSpace: 'nowrap' }}>Choose Logo</label>
                                    )}

                                </>
                            }
                        </div>
                        <div className="in w-100">
                            {isEditingProfile ?
                                <>
                                    <div className='d-flex name-title justify-content-between'>
                                        <Form.Group className="w-50">
                                            <Form.Control
                                                required
                                                type="text"
                                                onChange={(e) => setEditableUserFullName(e.target.value)}
                                                value={editableUserFullName}
                                                placeholder="Enter Your Full Name"
                                            />
                                            <Form.Control.Feedback type="invalid" style={{ fontSize: '.8rem' }}>
                                                Please provide a valid Title.
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <div className="head-buttons d-flex gap-2">
                                            <Button className='' variant='primary' onClick={hadnleSaveEditedProfile}>Save</Button>
                                            <Button className='' variant='outline-primary' onClick={handleProfileCancelEdit}>Cancel</Button>
                                        </div>
                                    </div>

                                </>
                                :
                                <>
                                    <div className='d-flex justify-content-between'>
                                        <div className="user-name">
                                            <h3 className="name">{userInfo?.FullName}</h3>
                                        </div>
                                        {/* <span className="badge bg-primary ms-1 text-white">Edit</span> */}
                                        {role === 'Employer' && <Button onClick={handleProfileEdit}>Edit Profile</Button>}
                                    </div>

                                </>
                            }
                        </div>
                    </div>
                </div>

                {/* User Statistics Start | Display None */}
                <div className="section full mt-2 d-none">
                    <div className="row">
                        <div className="col-lg-3 col-6 mb-2">
                            <div className="card">
                                <div className="card-body d-flex justify-content-between align-items-center flex-sm-col">
                                    <div className='db-bg-icon order-sm-1'>
                                        <ion-icon size="large" name="person-outline"></ion-icon>
                                    </div>
                                    <div className='order-sm-0'>
                                        <h5 className="card-title">1.7k+</h5>
                                        <h6 className="card-subtitle">Total Visitors</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-6 mb-2">
                            <div className="card">
                                <div className="card-body d-flex justify-content-between align-items-center flex-sm-col">
                                    <div className='db-bg-icon order-sm-1'>
                                        <ion-icon size="large" name="bookmarks-outline"></ion-icon>
                                    </div>
                                    <div className='order-sm-0'>
                                        <h5 className="card-title">03</h5>
                                        <h6 className="card-subtitle">Shortlisted</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-6 mb-2">
                            <div className="card">
                                <div className="card-body d-flex justify-content-between align-items-center flex-sm-col">
                                    <div className='db-bg-icon order-sm-1'>
                                        <ion-icon size="large" name="eye-outline"></ion-icon>
                                    </div>
                                    <div className='order-sm-0'>
                                        <h5 className="card-title">2.1k</h5>
                                        <h6 className="card-subtitle">Views</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-6 mb-2">
                            <div className="card">
                                <div className="card-body d-flex justify-content-between align-items-center flex-sm-col">
                                    <div className='db-bg-icon order-sm-1'>
                                        <ion-icon size="large" name="checkmark-done-outline"></ion-icon>
                                    </div>
                                    <div className='order-sm-0'>
                                        <h5 className="card-title">07</h5>
                                        <h6 className="card-subtitle">Applied Jobs</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* User Statistics End */}

                <div className="section mt-1 mb-2">
                    <div className="profile-info">
                        <div className=" bio">
                            {isEditingProfile ?
                                <>
                                    <div className="pb-2">
                                        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                            <Form.Label>Compnay Description</Form.Label>
                                            <Form.Control
                                                as="textarea"
                                                rows={2}
                                                maxLength={150}
                                                onChange={(e) => setEditableDescription(e.target.value)}
                                                value={editableDescription}
                                            />
                                        </Form.Group>
                                        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                            <Form.Label>Add Company Address</Form.Label>
                                            <Form.Control
                                                as="textarea"
                                                rows={2}
                                                maxLength={150}
                                                onChange={(e) => setEditableCompanyAddress(e.target.value)}
                                                value={editableCompanyAddress}
                                            />
                                        </Form.Group>
                                        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                            <Form.Label>Add Website</Form.Label>
                                            <Form.Control
                                                as="textarea"
                                                rows={2}
                                                maxLength={150}
                                                onChange={(e) => setEditableCompanyWebsite(e.target.value)}
                                                value={editableCompanyWebsite}
                                            />
                                        </Form.Group>
                                    </div>
                                </>
                                :
                                <>
                                    <Card.Text>
                                        <div className="pt-2">
                                            <h5>Description:</h5>
                                            <div className='description'>
                                                {role === "Employer" &&
                                                    <>
                                                        {userInfo?.Description ? userInfo.Description : <p>Edit your profile to add description</p>}
                                                    </>
                                                }
                                            </div>
                                        </div>
                                    </Card.Text>
                                    <Card.Text>
                                        <div className="pb-2">
                                            <h5>Company Address:</h5>
                                            <div className='address'>
                                                {role === "Employer" &&
                                                    <>
                                                        {userInfo?.Address ? userInfo.Address : <p>Edit your profile to add Adddress</p>}
                                                    </>
                                                }
                                            </div>
                                        </div>
                                    </Card.Text>
                                    <Card.Text>
                                        <div className="pb-2">
                                            <h5>Company Website:</h5>
                                            <div className='address'>
                                                {role === "Employer" &&
                                                    <>
                                                        {userInfo?.Website ? userInfo.Website : <p>Edit your profile to add company website</p>}
                                                    </>
                                                }
                                            </div>
                                        </div>
                                    </Card.Text>
                                </>
                            }
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}

const mapStateToProps = ({ auth }) => {
    const { userData, allCities, role, fullName, ID, authToken } = auth

    return {
        userData,
        allCities,
        role,
        fullName,
        ID,
        authToken
    }
}

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(EmployerProfile)
