import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router';
import { Navigate, useLocation } from 'react-router'
import { Row, Col, Card, Button, Form, InputGroup, Badge, Tabs, Tab, Image  } from "react-bootstrap";
import { GoDotFill } from "react-icons/go";
// import job5 from '../images/jobs/job6.png';
import job5 from '../images/Favicon_large-removebg-preview.png';
import tick from '../images/icon/tick-image.png';
import applicantIcon from '../images/icon/applicants-icon.svg';
import jobTypeeIcon from '../images/icon/job-type-icon.svg';
import userPhoto from '../images/user-profile.webp';
import locationIcon from '../images/icon/location-svg.svg';
import { connect } from "react-redux";
import { setToken } from "../../features/auth/authSlice";
import { GetAllApplicationsOfJob, GetCandidates, deleteJobByID, fetchJobData, updateExistingJob } from '../../features/constants/apis';
import ShimmerEmpPdDetails from './Shimmer/ShimmerEmpPdDetails';
import ShimmerJobDetails from './Shimmer/ShimmerJobDetails';
import { Link } from 'react-router-dom';
import { FaArrowLeft } from 'react-icons/fa';
import ConfirmJobDelete from './Modals/ConfirmJobDelete';


const JobDetails = ({job, authToken, ID, role, selectedJob, JobID }) => {

    const [jobData, setJobData] = useState([]);

    const [applicationsOfUser, setApplicationsOfUser] = useState([])

    const [candidates, setCandidates] = useState([])

    // delete modal
    const [modalShow, setModalShow] = React.useState(false);

    console.log(jobData?.ID)


    // Editable states
    
    const [isJobEditing, setIsJobEditing] = useState(false);

    const [editableJobTitle, setEditableJobTitle] = useState(jobData?.Title || '')
    const [editableJobType, setEditableJobType] = useState(jobData?.Type || null)
    const [editableJobCtc, setEditableJobCtc] = useState(jobData?.Ctc || null)
    const [editableJobOpenings, setEditableJobOpenings] = useState(jobData?.Openings || null)
    const [editableJobEducation, setEditableJobEduction] = useState(jobData?.Education || null)
    const [editableJobLocation, setEditableJobLocaiton] = useState(jobData?.Location || null)

    const navigate = useNavigate();

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const getJobID = searchParams.get('Job');
    // console.log(getJobID)

    // Tabs
    const [key, setKey] = useState('home');


    // fetch job

    useEffect(()=>{
        const fetchData = async () =>{
            const data = await fetchJobData(getJobID, authToken);
            setJobData(data.data)
            console.log(data)
        }
        fetchData()
    }, [authToken, getJobID])




    // Suggested Candidates 

    useEffect(()=>{
        const fetchCandidates = async () => {
            const candidateData = await GetCandidates(authToken);
            setCandidates(candidateData)
            console.log(candidateData)
            console.log(candidateData?.data[8]?.SkillProfile?.GeneralSkills)
        }
        fetchCandidates()
    }, [authToken])


    console.log(candidates)
    console.log(jobData)

    const filteredCandidates = candidates?.data?.filter(candidate => {

        if (!jobData || (!candidate.SkillProfile.GeneralSkills && !candidate.SkillProfile.ProfessionalSkills)) {
            return false;
        }

        // const generalSkills = jobData.Skills.GeneralSkills || [];
        // const professionalSkills = jobData.Skills.ProfessionalSkills || [];

        // console.log(jobData?.Skills?.GeneralSkills)
        const generalSkillsMatch = jobData?.Skills?.GeneralSkills.length > 0 && 
        jobData?.Skills?.GeneralSkills.every(skill => candidate?.SkillProfile?.GeneralSkills?.includes(skill));
        // console.log(generalSkillsMatch)

        // Check if ProfessionalSkills exist and match
        const professionalSkillsMatch = jobData?.Skills?.ProfessionalSkills.length > 0 &&
        jobData?.Skills?.ProfessionalSkills.every(skill => candidate?.SkillProfile?.ProfessionalSkills?.includes(skill));

    
        return generalSkillsMatch && professionalSkillsMatch;
    });
    
    // console.log(typeof filteredCandidates);
    // console.log(Object.keys(filteredCandidates).length);
    const filteredCandidateLength = filteredCandidates ? Object.keys(filteredCandidates).length : 0;
    console.log(filteredCandidateLength)
    



    useEffect(()=>{
        const fetchData = async () =>{
            const sendData = {
                employerID: ID,
                jobID: getJobID
            }
            const data = await GetAllApplicationsOfJob(sendData, authToken);
            setApplicationsOfUser(data)
            console.log(data)
        }
        fetchData()
    }, [authToken, ID, getJobID])

    // console.log(selectedJob)
    
    // Editable Job

    
    useEffect(()=>{
        if(jobData){
            setEditableJobTitle(jobData.Title)
            setEditableJobType(jobData?.Type)
            setEditableJobCtc(jobData?.Ctc)
            setEditableJobOpenings(jobData?.Openings)
            setEditableJobEduction(jobData?.Education)
            setEditableJobLocaiton(jobData?.Location)
        }
    }, [jobData])

    const handleEditJob = () =>{
        setIsJobEditing(true)
    }

    const handleSaveEditedJob = () =>{
        const  editedJobData = {
            'JobTitle' : editableJobTitle,
            'JobType' : editableJobType,
            'JobCTC' : editableJobCtc,
            'OpenPositions' : parseInt(editableJobOpenings),
            'EducationRequirement' : editableJobEducation,
            'JobLocation' : editableJobLocation
        }

        console.log(editedJobData)

        const updateJob = async () =>{
            const updatedJobData = await updateExistingJob(getJobID, editedJobData, authToken)
            console.log(updatedJobData)

            const updatedNewJobData = await fetchJobData(getJobID, authToken)
            setJobData(updatedNewJobData.data)
        }

        updateJob()

        setIsJobEditing(false)
    }

    const handleCancelJob = () =>{
        setEditableJobTitle(jobData.Title || '')
        setEditableJobType(jobData?.Type || '')
        setEditableJobCtc(jobData?.Ctc || '')
        setEditableJobOpenings(jobData?.Openings || '')
        setEditableJobEduction(jobData?.Education || '')
        setEditableJobLocaiton(jobData?.Location || '')

        setIsJobEditing(false)
    }

    const handleDeleteJob = async() =>{
        await deleteJobByID(getJobID, authToken);
        console.log(getJobID)
        navigate('/jobs')
    }

    const handleViewUserProfile = (candidateID) => {
        navigate(`/candidate-profile?candidate=${candidateID}`)
    }

  return (
    <div id="appCapsule">
        <div className="section mb-2 mt-2 full job-detail-section">
            <div className="container pb-2">
            {jobData.PlacementDriveID === null ?
                <Link to="/jobs" type="submit" className='my-2 pe-2' ><FaArrowLeft style={{width: '23px', height: '23px'}}/></Link> 
                : <Link to={`/emp-placement-drive-details?drive=${jobData.PlacementDriveID}`} type="submit" className='my-2 pe-2' ><FaArrowLeft style={{width: '23px', height: '23px'}}/></Link> 
            }
                { jobData.length === 0 ? <ShimmerJobDetails/> : 
                (role === 'Employer' || role === 'Admin') && (
                    <Row xs={1} md={1} className="g-4">
                    {/* Job card */}

                        <Col>
                            <Card className='placement-drive-card'>
                                <Card.Body>
                                {isJobEditing ? 
                                <>
                                    <Row className='pb-2 border-bottom border-gray pd-card-head'>
                                        <Col lg="1"><Card.Img variant="top" src={jobData?.Image ? jobData?.Image : job5} /></Col>
                                        <Col>
                                        <div className="d-flex justify-content-between">
                                            <Card.Title style={{width: '80%'}}>
                                                <Form.Group className="">
                                                    <Form.Control
                                                    type="text"
                                                    onChange={(e) => setEditableJobTitle(e.target.value)}
                                                    value={editableJobTitle}
                                                    placeholder="Enter Placement Drive Title"
                                                    />
                                                </Form.Group>
                                            </Card.Title>
                                            <div className="d-flex gap-2">
                                                <Button onClick={handleSaveEditedJob}>Save</Button>
                                                <Button variant="outline-primary" onClick={handleCancelJob}>Cancel</Button>
                                            </div>
                                        </div>
                                            <Card.Text className='drive-details dot-icon'> 
                                                <div className="d-md-flex align-items-center gap-2 mb-2">
                                                <Form.Select 
                                                    aria-label="Default select example" 
                                                    style={{width: '120px', padding: '3px 5px'}}
                                                    onChange={(e)=> setEditableJobType(e.target.value)}
                                                    value={editableJobType}
                                                    >
                                                        <option disabled selected value=''>Type</option>
                                                        <option value="Full Time">Full Time </option>
                                                        <option value="Part Time">Part Time</option>
                                                        <option value="Internship">Internship</option>
                                                    </Form.Select> Job Type <GoDotFill /> 
                                                    <Form.Select 
                                                    aria-label="Default select example" 
                                                    style={{width: '150px', padding: '3px 8px'}}
                                                    onChange ={(e)=> setEditableJobCtc(e.target.value)}
                                                    value={editableJobCtc}
                                                    >
                                                        <option disabled selected value="">Select range</option>
                                                        <option value="1 to 5 ">1 to 5</option>
                                                        <option value="6 to 10 ">6 to 10 </option>
                                                        <option value="11 to 15 ">11 to 15</option>
                                                        <option value="15 and above">15 and above</option>
                                                    </Form.Select> LPA <GoDotFill /> 
                                                    <Form.Select 
                                                        aria-label="Default select example" 
                                                        style={{width: '60px', padding: '3px 5px'}}
                                                        onChange={(e)=> setEditableJobOpenings(e.target.value)}
                                                        value={editableJobOpenings}
                                                    >
                                                        <option disabled selected value=''>0</option>
                                                        <option value="1">1 </option>
                                                        <option value="2">2</option>
                                                        <option value="3">3</option>
                                                        <option value="4">4</option>
                                                        <option value="5">5</option>
                                                        <option value="6">6</option>
                                                    </Form.Select> openings
                                                </div>
                                            </Card.Text>
                                        </Col>
                                    </Row>
                                    {/* <Card.Text className='py-3 min-h-6'>{jobData?.Criteria}</Card.Text> */}
                                    <div className='d-flex justify-content-between align-items-center gap-3 my-2'>
                                        <div className='w-100'>
                                            <Card.Text className='d-flex align-items-center'>
                                                <InputGroup.Text id="inputGroup-sizing-default" style={{height: '37px'}}>
                                                    <ion-icon name="briefcase-outline" style={{color: '#45a994'}}></ion-icon>
                                                    {/* <IoLocationOutline style={{color: '#45a994'}}/> */}
                                                </InputGroup.Text> 
                                                <Form.Select 
                                                className='w-md-30'
                                                aria-label="Default select example"
                                                onChange={(e) => setEditableJobEduction(e.target.value)}
                                                value={editableJobEducation}
                                                >
                                                    <option selected disabled  value="">Select educational qualification</option>
                                                    <option value="12th Pass">12th Pass</option>
                                                    <option value="Diploma">Diploma</option>
                                                    <option value="ITI">ITI</option>
                                                    <option value="Graduation">Graduation</option>
                                                    <option value="Post Graduation">Post Graduation</option>
                                                    <option value="PHD">PHD</option>
                                                </Form.Select>
                                                <span className="text-muted ms-1">required</span>
                                            </Card.Text> 
                                            <Card.Text className='mb-0 location d-flex align-items-center'>
                                                <InputGroup.Text id="inputGroup-sizing-default" style={{height: '37px'}}>
                                                    <ion-icon name="location-outline" style={{color: '#45a994'}}></ion-icon>
                                                    {/* <IoLocationOutline style={{color: '#45a994'}}/> */}
                                                </InputGroup.Text>
                                                <Form.Select 
                                                    className='w-md-30'
                                                    required 
                                                    aria-label="Default select example"
                                                    onChange = {(e) => setEditableJobLocaiton(e.target.value)}
                                                    value={editableJobLocation}
                                                    >
                                                    <option selected disabled value="">Select location</option>
                                                    <option value="Mumbai">Mumbai</option>
                                                    <option value="Navi Mumbai">Navi Mumbai</option>
                                                    <option value="Panvel">Panvel</option>
                                                    <option value="Pune">Pune</option>
                                                </Form.Select>
                                            </Card.Text>
                                        </div> 
                                    </div>
                                </> : 
                                (
                                    <>{console.log(jobData)}
                                        <Row className='pb-2 border-bottom border-gray pd-card-head'>
                                            <Col lg="2 d-flex justify-content-center"><Card.Img variant="top" className='rounded-image imaged rounded' src={jobData?.Image ? jobData?.Image : job5} /></Col>
                                            <Col lg="10 d-flex flex-column justify-content-center">
                                                <div className="d-flex justify-content-between">
                                                    <Card.Title>{jobData?.Title} <span className='text-muted'>({jobData?.JobRole})</span></Card.Title>
                                                    <div className="d-flex gap-2">
                                                        <Button onClick={handleEditJob}>Edit</Button>
                                                        <Button variant="danger" onClick={() => setModalShow(true)}>Delete</Button>
                                                    </div>
                                                </div>
                                                <Card.Text className='drive-details dot-icon d-flex gap-1'> 
                                                    <span className='d-flex gap-1'><Image className='icons' src={jobTypeeIcon}/>{jobData?.Type}</span>
                                                    <span className='ps-1 d-flex gap-1'> <span className='rupee'>₹</span> {jobData?.Ctc} LPA</span>
                                                    <span className='ps-1 d-flex gap-1'><Image className='icons' src={applicantIcon}/>openings({jobData?.Openings})</span>
                                                </Card.Text>
                                            </Col>
                                        </Row>
                                        {/* <Card.Text className='py-3 min-h-6'>{jobData?.Criteria}</Card.Text> */}
                                        <div className='d-flex justify-content-between align-items-center gap-3'>
                                            <div>
                                                <Card.Text>{jobData?.Education} <span className="text-muted">required</span></Card.Text> 
                                                <Card.Text>{jobData?.Degree.join(', ')}</Card.Text> 
                                                <Card.Text>
                                                    <div className="pb-2">
                                                        <h5>General Skills:</h5>
                                                        <div>
                                                            {jobData?.Skills?.GeneralSkills ?
                                                                jobData?.Skills?.GeneralSkills.map((skill, index) => (
                                                                    <>
                                                                    {/* <div key={index} className="chip chip-primary ms-05 mb-05">
                                                                        <span className="chip-label">{skill}</span>
                                                                    </div> */}
                                                                    <div key={index} className="chip bg-transparent text-black ms-05 mb-1">
                                                                        <span className="chip-label d-flex justify-content-center align-items-center gap-1"><Image className='tick-outlined' src={tick}/>{skill}</span>
                                                                    </div>
                                                                    </>
                                                                )) : <span className="text-muted">No General Skill Found</span>
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="pb-2">
                                                        <h5>Professional Skills:</h5>
                                                        <div>   
                                                            {jobData?.Skills?.ProfessionalSkills ?
                                                                jobData?.Skills?.ProfessionalSkills.map((skill, index) => (
                                                                    <div key={index} className="chip bg-transparent text-black ms-05 mb-1">
                                                                        <span className="chip-label d-flex justify-content-center align-items-center gap-1"><Image className='tick-outlined' src={tick}/>{skill}</span>
                                                                    </div>
                                                                )) : <span className="text-muted">No Professioanl Skill Found</span>
                                                            }
                                                        </div>
                                                    </div>
                                                </Card.Text>
                                                <Card.Text className='mb-0 location'><Image className='icons' src={locationIcon}/> {jobData?.Location} <span className="text-muted">({jobData.WorkModel && jobData.WorkModel})</span> </Card.Text>
                                                <Card.Text>{jobData?.CompanyName}</Card.Text> 
                                            </div> 
                                        </div>

                                        {/* Delete Modal */}
                                        {/* <Button variant="primary" onClick={() => setModalShow(true)}>
                                            Launch vertically centered modal
                                        </Button> */}

                                        <ConfirmJobDelete
                                            show={modalShow}
                                            onHide={() => setModalShow(false)}
                                            handleDeleteJob={handleDeleteJob}
                                        />
                                    </>
                                )
                                }
                                    
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>

                    
                )}
                {/* Applicants List Start */}
                {(role === 'Employer' || role === 'Admin') &&
                    <>
                        <div className="section full mt-4 mb-4">
                            <div className='container job-candidates'>
                                <Tabs
                                    className="mb-3"
                                    defaultActiveKey="suggested"
                                    id="controlled-tab-example"
                                    // id="noanim-tab-example"
                                    // activeKey={key}
                                    onSelect={(k) => setKey(k)}
                                    >
                                    <Tab eventKey="suggested" title="Suggested">
                                   {
                                        <div>
                                            <div className="d-flex gap-2 align-items-center">
                                                <div className="section-title ps-0">All Suggested Candidates</div>
                                                <Badge bg="primary" className="text-white">{filteredCandidates ? Object.keys(filteredCandidates).length : '0'}</Badge>
                                            </div>
                                            <ul className="listview image-listview media">
                                                
                                                {
                                                    (filteredCandidates === null) &&
                                                        <li className='my-2'>
                                                        <div className="item">
                                                            <div className="imageWrapper in">
                                                                <div className="in">
                                                                    <div>Loading...</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </li>
                                                } 
                                                {
                                                    filteredCandidates !== null && filteredCandidateLength === 0 &&
                                                        <li className='my-2'>
                                                        <div className="item">
                                                            <div className="imageWrapper in">
                                                                <div className="in">
                                                                    <div>No Suggestion found</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </li>
                                                }

                                                {filteredCandidates ? (
                                                    filteredCandidates.map((candidate, i) => (
                                                            <li key={i} className='my-2'>
                                                                <div className="item">
                                                                    <div className="imageWrapper in">
                                                                    <img src={candidate?.Image ? candidate?.Image : userPhoto} alt="" className="imaged w64" />
                                                                        <div className="in">
                                                                            <div>{candidate?.FullName}</div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="in">
                                                                        <div>{candidate?.JobName}</div>
                                                                    </div>
                                                                    <div className='d-flex align-items-center'>
                                                                        {/* <Button className='me-2 p-1 rounded' variant="secondary">
                                                                            <ion-icon style={{marginRight: '0px'}} name="bookmark-outline"></ion-icon>
                                                                        </Button> */}
                                                                        <Button variant="primary" onClick={() => handleViewUserProfile(candidate?.ID)}>View</Button>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        ))
                                                    ) : <p>no suggestion</p>
                                                }

                                            </ul>
                                        </div>
                                    }

                                    </Tab>
                                    <Tab eventKey="applied" title="Applied">
                                        <div className="d-flex gap-2 align-items-center job-candidate">
                                            <div className="section-title ps-0">All Applied Candidates</div>
                                            <Badge bg="primary" className="text-white">{applicationsOfUser ? applicationsOfUser.length : '0'}</Badge>
                                        </div>
                                        <ul className="listview image-listview media">
                                            {
                                            applicationsOfUser === null &&
                                                <li className='my-2'>
                                                <div className="item">
                                                    <div className="imageWrapper in">
                                                        <div className="in">
                                                            <div>Loading...</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                            }

                                            {
                                            applicationsOfUser !== null && applicationsOfUser.length === 0 &&
                                                <li className='my-2'>
                                                <div className="item">
                                                    <div className="imageWrapper in">
                                                        <div className="in">
                                                            <div>No Application found</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                            }
                                            {
                                            applicationsOfUser !== null && applicationsOfUser.length > 0 &&
                                            applicationsOfUser.map((item, i) => {
                                                    return (
                                                        <li key={i} className='my-2'>
                                                            <div className="item">
                                                                <div className="imageWrapper in">
                                                                <img src={userPhoto} alt="" className="imaged w64" />
                                                                    <div className="in">
                                                                        <div>{item.CandidateName}</div>
                                                                    </div>
                                                                </div>
                                                                <div className="in">
                                                                    <div>{item.JobName}</div>
                                                                </div>
                                                                <div className='d-flex align-items-center'>
                                                                    {/* <Button className='me-2 p-1 rounded' variant="secondary">
                                                                        <ion-icon style={{marginRight: '0px'}} name="bookmark-outline"></ion-icon>
                                                                    </Button> */}
                                                                    <Button variant="primary" onClick={() => handleViewUserProfile(item?.CandidateID)}>View</Button>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    )
                                                })
                                            }
                                        </ul>
                                    </Tab>
                                </Tabs>
                                
                            </div>
                        </div>
                    </>
                    }
                    {/* Applicants List End */}
                
            </div>
        </div>
    </div>
  )
}

const mapStateToProps = ( { auth, selectedDrive } ) => {
    const { ID, role, authToken } = auth
    const { selectedJob } = selectedDrive
  
    return {
        ID,
        role,
        selectedJob,
        authToken
    }
  }


export default connect(mapStateToProps)(JobDetails);