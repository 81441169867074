import React, { useState, useEffect } from 'react';
import { createCourseApplication, createJobApplication, createPDJobApplication  } from '../../features/constants/apis';
import { connect } from 'react-redux';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { setToken } from '../../features/auth/authSlice';
import Checkmark from '../images/Favicon_large-removebg-preview.png'

const LoaderState = ({ID, authToken, fullName}) => {

  const [loaderState, setLoaderState] = useState(null);
  const navigate = useNavigate();
  const handleGoToRoute = () => {
    window.parent.postMessage({ message: 'redirect-to-dashboard', tokenData: authToken }, '*');
  };

  useEffect(()=>{
    console.log(ID, authToken)
    const fetchData = async () => {
      const retrievedID = localStorage.getItem('certCourseID');
      const retrievedType = localStorage.getItem('applicationType');
      const retrievedJobID = localStorage.getItem('jobId');
      const retrievedUrl = localStorage.getItem('itemUrl');
      if (retrievedType === 'course') {
        const courseApplicationData = {
          'CourseID': retrievedID,
          'Url': retrievedUrl,
          'tokenUserID': ID
        }
        const courseAppData = await createCourseApplication(courseApplicationData, authToken)
        console.log(courseApplicationData, courseAppData)
        if (courseAppData.data.message === 'Application Successful') {
          setLoaderState('success')
        } else if (courseAppData.data === 'Already Applied') {
          setLoaderState('applied')
        }
        console.log(loaderState)
      } else if (retrievedType === 'driveJob') {
        const applicationData = {
          'placementID': retrievedID,
          'JobID': retrievedJobID,
          'Url': retrievedUrl,
          'tokenUserID': ID
        }
        const jobAppData = await createJobApplication(applicationData, authToken)
        console.log(applicationData, jobAppData)
        if (jobAppData.data === 'Job appliation created successfully!') {
          setLoaderState('success')
  
        } else if (jobAppData.data === 'Already Applied') {
          setLoaderState('applied')
        }
        console.log(loaderState)
      }
    }
    fetchData();
  }, [ID])
  
  return (
    <div id="appCapsule" className='p-0'>
      <div className='container d-flex justify-content-center align-items-center' style={{height: "95vh"}}>
        {loaderState === null && (
          <div className='loader'>
          </div>
        )}
        {loaderState === 'success' && (
          <div className='d-flex flex-column align-items-center'>
            <img src={Checkmark} alt='checkmark' className='w-10' />
            <div className='text-center mt-2'>
              <h3>Enrolled successfully</h3>
              <Button onClick={() => handleGoToRoute()} variant='primary'>Go To Dashboard</Button>
            </div>
          </div>
        )}
        {loaderState === 'applied' && (
          <div className='d-flex flex-column align-items-center'>
            <img src={Checkmark} alt='checkmark' className='w-10' />
            <div className='text-center mt-2'>
              <h3>Already enrolled to this course.</h3>
              <Button onClick={() => handleGoToRoute()} variant='primary'>Go To Dashboard</Button>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

const mapStateToProps = ({ auth }) => {
  const { ID, authToken, fullName } = auth
  return {
    ID,
    authToken,
    fullName
  }
}

const mapDispatchToProps = {
  setToken
}

export default connect(mapStateToProps, mapDispatchToProps) (LoaderState)