import React, { useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import axios from 'axios';
import './ChatBot.css';
import config from '../../config'
import { useEffect } from 'react';
import { useRef } from 'react';
import staicLogo from '../images/Favicon_large-removebg-preview.png';

const ChatBot = () => {
    const [messages, setMessages] = useState([
        { user: 'bot', text: "Please enter your phone number:", time: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }) }
    ]);
    const [userInput, setUserInput] = useState('');
    const [otpSent, setOtpSent] = useState(false);
    const [verified, setVerified] = useState(false);
    const [userName, setUserName] = useState('');
    const [nameAsked, setNameAsked] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState('');
    const messagesEndRef = useRef(null);

    const scrollToBottom = () => {
        messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }

    useEffect(() =>{
        scrollToBottom()
    },[messages])

    const getCurrentTime = () => {
        const now = new Date();
        return now.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    };

    const handleUserInput = (e) => {
        setUserInput(e.target.value);
    };

    const handleUserSubmit = async () => {
        const userMessage = {
            user: 'user',
            text: userInput,
            time: getCurrentTime()
        };

        if (!otpSent) {
            const phoneNumber = userInput.trim();
            if (phoneNumber.length === 10 && !isNaN(phoneNumber)) {
                try {
                    const response = await axios.post(config.apiUrl + '/send-otp', { phoneNumber });
                    if (response.status === 200) {
                        setMessages((prevMessages) => [
                            ...prevMessages,
                            userMessage,
                            { user: 'bot', text: "An OTP has been sent to your phone. Please enter the OTP:", time: getCurrentTime() }
                        ]);
                        setOtpSent(true);
                        setPhoneNumber(phoneNumber);  
                    }
                } catch (error) {
                    console.error('Error sending OTP:', error);
                    setMessages((prevMessages) => [
                        ...prevMessages,
                        userMessage,
                        { user: 'bot', text: "Error sending OTP. Please try again.", time: getCurrentTime() }
                    ]);
                }
            } else {
                setMessages((prevMessages) => [
                    ...prevMessages,
                    userMessage,
                    { user: 'bot', text: "Invalid phone number. Please enter a 10-digit number:", time: getCurrentTime() }
                ]);
            }
        } else if (!verified) {
            const otp = userInput.trim();
            try {
                const response = await axios.post(config.apiUrl + '/verify-otp', { phoneNumber, otp });  
                if (response.status === 200) {
                    setMessages((prevMessages) => [
                        ...prevMessages,
                        userMessage,
                        { user: 'bot', text: "OTP Verified. What is your name?", time: getCurrentTime() }
                    ]);
                    setVerified(true);
                    setNameAsked(true);
                }
            } catch (error) {
                console.error('Error verifying OTP:', error);
                setMessages((prevMessages) => [
                    ...prevMessages,
                    userMessage,
                    { user: 'bot', text: "Incorrect OTP. Please try again:", time: getCurrentTime() }
                ]);
            }
        } else if (nameAsked) {
            const name = userInput.trim();
            if (name) {
                setUserName(name);
                setMessages((prevMessages) => [
                    ...prevMessages,
                    userMessage,
                    { user: 'bot', text: `Welcome, ${name}! How can I assist you today?`, time: getCurrentTime() }
                ]);
                setNameAsked(false);
            } else {
                setMessages((prevMessages) => [
                    ...prevMessages,
                    userMessage,
                    { user: 'bot', text: "Please enter a valid name:", time: getCurrentTime() }
                ]);
            }
        }
        setUserInput('');
    };

    return (
        <div id="appCapsule">
            <div className='container chatbot-content'>
            <div className='relative'>
                <div className='message-box'>
                    {messages.map((message, index) => (
                        <div key={index} className={`message-item ${message.user === 'user' ? 'user' : ''}`}>
                            {message.user !== 'user' && 
                                <img src={staicLogo} alt="avatar" className="avatar"/>
                            }
                            <div className="content">
                                <div className="bubble">{message.text}</div>
                                <div className="footer">{message.time}</div>
                            </div>
                        </div>
                    ))}
                    <div ref={messagesEndRef} />
                    </div>
                </div>
                <div className="chatFooter">
                    <Form
                        onSubmit={(e) => {
                            e.preventDefault();
                            handleUserSubmit();
                        }}
                    >
                        <div className="form-group boxed">
                            <div className="input-wrapper">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder={otpSent ? (verified ? "Enter your name" : "Enter OTP") : "Enter your phone number"}
                                    value={userInput}
                                    onChange={handleUserInput}
                                    disabled={verified && !nameAsked} // Disable input after verification until name is asked
                                />
                            </div>
                        </div>
                        <Button type="submit" className="btn btn-icon btn-primary rounded">
                            <ion-icon name="send"></ion-icon>
                        </Button>
                    </Form>
                </div>
            </div>
            <div className='container chatInputContainer'>
                {/* <div className="chatFooter">
                    <Form
                        onSubmit={(e) => {
                            e.preventDefault();
                            handleUserSubmit();
                        }}
                    >
                        <div className="form-group boxed">
                            <div className="input-wrapper">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder={otpSent ? (verified ? "Enter your name" : "Enter OTP") : "Enter your phone number"}
                                    value={userInput}
                                    onChange={handleUserInput}
                                    disabled={verified && !nameAsked} // Disable input after verification until name is asked
                                />
                            </div>
                        </div>
                        <Button type="submit" className="btn btn-icon btn-primary rounded">
                            <ion-icon name="send"></ion-icon>
                        </Button>
                    </Form>
                </div> */}
            </div>
        </div>
    );
};

export default ChatBot;
