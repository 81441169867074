import React, { useState } from 'react'
import { Form, InputGroup, Button, Row, Col, Modal } from 'react-bootstrap'
import { connect } from 'react-redux';
import { setToken } from '../../../features/auth/authSlice';


const NewPlacementDriveJobForm = ({ ID, authToken, pDriveName, ...props }) => {

    // Validation
    const [validated, setValidated] = useState(false);

    const [numValueOpenings, setNumValueOpenings] = useState('');
    const [numValueCTCFrom, setNumValueCTCFrom] = useState('');
    const [numValueCTCTo, setNumValueCTCTo] = useState('');
    const [placementJobTitle, setPlacementJobTitle] = useState('');
    const [placementJobCompanyName, setPlacementJobCompanyName] = useState('');


    const handleInputOpenings = (event) => {
        const newNumValue = event.target.value.replace(/\D/g, '');
        setNumValueOpenings(newNumValue);
    };

    const handleInputCTCFrom = (event) => {
        const newNumValue = event.target.value.replace(/\D/g, '');
        setNumValueCTCFrom(newNumValue);
    };

    const handleInputCTCTo = (event) => {
        const newNumValue = event.target.value.replace(/\D/g, '');
        setNumValueCTCTo(newNumValue);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.stopPropagation();
        }

        const newPlacementDriveJobFromData = {
            'PdJobTitle': placementJobTitle,
            'PdJobComanyName': placementJobCompanyName,
            'PdJobOpening': numValueOpenings,
            'PdJobCtcFrom': numValueCTCFrom,
            'PdJobCtcTo': numValueCTCTo,
            'tokenUserID': ID
        }       
    }

    const handleCloseModal= () =>{
        props.onHide()
    }

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Add New Job at {pDriveName}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="section mb-2 mt-5 full">
                    <div className="container pb-2">
                        {/* <h1 className='text-primary'>Add New Job in the Placement Drive</h1> */}
                        <Form noValidate validated={validated} onSubmit={handleSubmit}>
                            <Form.Group className="mb-3" controlId="validationCustom01">
                                <Form.Label>Job Title</Form.Label>
                                <Form.Control
                                    required
                                    type="text"
                                    onChange={(e) => setPlacementJobTitle(e.target.value)}
                                    placeholder="Enter Job Title"
                                />
                                <Form.Control.Feedback type="invalid">
                                    Please provide Job Title.
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="validationCustom01">
                                <Form.Label>Company Name</Form.Label>
                                <Form.Control
                                    required
                                    type="text"
                                    onCanPlay={(e) => setPlacementJobCompanyName(e.target.value)}
                                    placeholder="Enter Company Name"
                                />
                                <Form.Control.Feedback type="invalid">
                                    Please provide the Company Name.
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>Job Openings</Form.Label>
                                <Form.Control
                                    required
                                    type="text"
                                    value={numValueOpenings}
                                    onChange={handleInputOpenings}
                                    placeholder="Enter number of Openings"
                                />
                                <Form.Control.Feedback type="invalid">
                                    Please provide a valid number of Openings.
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>CTC from (LPA)</Form.Label>
                                <Form.Control
                                    required
                                    type="text"
                                    value={numValueCTCFrom}
                                    onChange={handleInputCTCFrom}
                                    placeholder="Enter CTC from (LPA)"
                                />
                                <Form.Control.Feedback type="invalid">
                                    Please provide CTC from.
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>CTC to (LPA)</Form.Label>
                                <Form.Control
                                    required
                                    type="text"
                                    value={numValueCTCTo}
                                    onChange={handleInputCTCTo}
                                    placeholder="Enter CTC to (LPA)"
                                />
                                <Form.Control.Feedback type="invalid">
                                    Please provide CTC to.
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Button type="submit" variant="primary">Save</Button>
                        </Form>
                    </div>
                </div>
                <Modal.Footer>
                    <Button type="submit">Add</Button>
                    <Button variant='outline-primary' onClick={handleCloseModal}>Close</Button>
                </Modal.Footer>

            </Modal.Body>
        </Modal>
    )

}


const mapStateToProps = ({ auth }) => {
    const { ID, authToken } = auth

    return {
        ID,
        authToken
    }
}

const mapDispatchToProps = {
    setToken
}

export default connect(mapStateToProps, mapDispatchToProps)(NewPlacementDriveJobForm);