import { React, useState, useEffect } from 'react'
import { Badge, Button, Card, Col, Form, InputGroup, Row } from 'react-bootstrap'
import { connect } from 'react-redux'
import axios from 'axios';
import Select from 'react-select';
import { GetCandidateByID, fetchAllJobRoles, fetchAllJobRolesSkills, fetchAllSkills } from '../../../features/constants/apis';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import { FaCheckCircle, FaSearch } from "react-icons/fa";
import { Link } from 'react-router-dom';
import { RxCross2 } from "react-icons/rx";
import tick from '../../images/tick.png'
import tickOutlined from '../../images/tick-outlined.png'
import selectGenSkillModal from '../Modals/selectGenSkillModal';

const RegFormStep2 = ({ authToken, tokenData, changeForm, submitFormStep2, completeProfileStep, ID, fullName, userData, role }) => {

    const [validated, setValidated] = useState(false);
    const [selectGSkills, setSelectGSkills] = useState([]);
    const [selectPSkills, setSelectPSkills] = useState([]);
    const [uploadedFile, setUploadedFile] = useState(null);
    const [fileUploaded, setFileUploaded] = useState(false);
    const [selectNotification, setSelectNotification] = useState(false);

    const [candidateData, setCandidateData] = useState([])
    const [jobRoles, setJobRoles] = useState([])

    const [jobRolesSkills, setJobRolesSkills] = useState({ GeneralSkills: [], ProfessionalSkills: [] })

    const [matchingJobRoleData, setMatchingJobRolesData] = useState([])

    const [checkboxGenStates, setCheckboxGenStates] = useState([]);
    const [checkboxProStates, setCheckboxProStates] = useState([]);

    // Skills modals
    const [showSelectGenSkillModal, setShowSelectGenSkillModal] = useState(false);

    console.log(ID)

    useEffect(() => {
        // Prompt confirmation when reload page is triggered
        window.onbeforeunload = () => { return "Data will be lost" };

        // Unmount the window.onbeforeunload event
        return () => { window.onbeforeunload = null };
    }, []);

    document.onkeydown = function (e) {
        if (e.key === 'r' && (e.ctrlKey || e.metaKey || e.shiftKey)) {
            e.preventDefault();
            return false;
        }
    };


    // console.log(ID)
    // console.log(tokenData)
    // console.log(completeProfileStep)

    // profile skill card
    // const [profileSkills, setProfileSkills] = useState([]);

    const [getSkills, setGetSkills] = useState([]);


    useEffect(() => {
        const fetchData = async () => {
            const data = await fetchAllSkills(authToken);
            setGetSkills(data);
        }
        fetchData();
    }, [authToken])

    // jobroles skills
    useEffect(() => {
        const fetchData = async () => {
            const data = await fetchAllJobRolesSkills(authToken);
            setJobRolesSkills(data.data)
            console.log(data?.data)
        }
        fetchData()
    }, [authToken]);

    // console.log(jobRolesSkills?.GeneralSkills)

    const generalSkillOptions = jobRolesSkills?.GeneralSkills.map(skill => ({
        value: skill,
        label: skill,
    }));

    const professionalSkillOptions = jobRolesSkills?.ProfessionalSkills.map(skill => ({
        value: skill,
        label: skill,
    }));

    // console.log(generalSkillOptions)
    // console.log(professionalSkillOptions)

    // const generalSkillOptions = getSkills.filter(skill => skill.Type === 0).map(skill => ({
    //     value: skill.Title,
    //     label: skill.Title,
    // }));

    // const professionalSkillOptions = getSkills.filter(skill => skill.Type === 1).map(skill => ({
    //     value: skill.Title,
    //     label: skill.Title,
    // }))

    const handleSelectedGeneralSkills = (selectedOptions) => {
        const selectGSkills = selectedOptions.map(option => option.value);
        setSelectGSkills(selectGSkills);
    };
    const handleSelectedProfessionalSkills = (selectedOptions) => {
        const selectPSkills = selectedOptions.map(option => option.value);
        setSelectPSkills(selectPSkills);
    };

    const [hasError, setHasError] = useState({
        'selectGSkills': false,
        'selectPSkills': false,
    })

    const showErrorMsg = (e) => {
        const showError = { ...hasError }
        console.log(showError)
        if (e === "Select General Skills") {
            console.log(e)
        }
        if (e === "Select Professional Skills") {
            console.log(e)
        }
    }

    function submitForm(e) {
        e.preventDefault();
        const form = e.currentTarget;
        if (form.checkValidity() === false) {
            e.stopPropagation();
        }

        if (selectGSkills === null) {
            showErrorMsg("Select General Skills")
        }
        else if (selectPSkills === null) {
            showErrorMsg("Select Professional Skills")
        }
        else {
            const newStep2RegData = {
                'selectGSkills': selectGSkills,
                'selectPSkills': selectPSkills,
                'uploadedFile': uploadedFile,
                'stepNo': 2,
                'completeProfileStep': completeProfileStep,
                'selectNotification': selectNotification,
            }
            submitFormStep2(newStep2RegData)
        }
    }


    useEffect(() => {
        const fetchCandidateByID = async () => {
            const data = await GetCandidateByID(ID, authToken);
            setCandidateData(data.data)
            console.log(data.data)
        }
        fetchCandidateByID()
    }, [ID, authToken])

    useEffect(() => {
        const fetchJobRoles = async () => {
            const data = await fetchAllJobRoles(authToken);
            setJobRoles(data.data)
            console.log(data.data)
        }
        fetchJobRoles()
    }, [authToken]);

    // console.log(candidateData)

    useEffect(() => {
        const domainValue = candidateData?.CareerProfile?.domain;
        console.log(candidateData)
        console.log(domainValue)
        const matchingJobRole = jobRoles.find(jobRole => jobRole?.ID == domainValue)
        console.log(matchingJobRole)

        if (matchingJobRole) {
            const matchingDomain = matchingJobRole?.Domain;
            const jobRoleWithMatchingDomain = jobRoles.filter(jobRole => jobRole?.Domain === matchingDomain);

            console.log(jobRoleWithMatchingDomain)
            setMatchingJobRolesData(jobRoleWithMatchingDomain)
            console.log(matchingJobRoleData)

            setCheckboxGenStates(new Array(jobRoleWithMatchingDomain.length).fill(false));
            setCheckboxProStates(new Array(jobRoleWithMatchingDomain.length).fill(false));
        }
    }, [jobRoles, candidateData])



    // General Skills

    // const [checkboxGenStates, setCheckboxGenStates] = useState(Array(matchingJobRoleData.length).fill(false));
    const handleGenCheckboxChange = (idx) => {
        // Update the checked state for the checkbox at the given index
        setCheckboxGenStates(prevStates => {
            const newState = [...prevStates];
            newState[idx] = !newState[idx];
            return newState;
        });

        // Log the checked state
        console.log(`Checkbox at index ${idx} is now ${!checkboxGenStates[idx] ? 'checked' : 'unchecked'}`);

        if (!checkboxGenStates[idx]) {
            const seletedJobRoleGenSkills = matchingJobRoleData[idx]?.GeneralSkills;
            // Add new skills to selectGSkills
            const updatedSkills = Array.from(new Set([...selectGSkills, ...seletedJobRoleGenSkills]));
            console.log(updatedSkills);
            setSelectGSkills(updatedSkills);
        } else {
            const deselectedJobRoleGenSkills = matchingJobRoleData[idx]?.GeneralSkills;
            // Remove deselected skills from selectGSkills
            const updatedSkills = selectGSkills.filter(skill => !deselectedJobRoleGenSkills.includes(skill));
            console.log(updatedSkills);
            setSelectGSkills(updatedSkills);
        }

    };

    const handleGenSkillSelect = (skill) => {
        console.log('selected skill: ', skill)
        const updatedSkills = Array.from(new Set([...selectGSkills, skill]));
        setSelectGSkills(updatedSkills)
    }
    

    // professional Skills

    // const [checkboxProStates, setCheckboxProStates] = useState(Array(matchingJobRoleData.length).fill(false));
    const handleProCheckboxChange = (idx) => {
        // Set the checked state of the checkbox
        setCheckboxProStates(prevStates => {
            const newStates = [...prevStates];
            newStates[idx] = !newStates[idx];
            return newStates;
        });

        console.log(`Checkbox at index ${idx} is now ${!checkboxProStates[idx] ? 'checked' : 'unchecked'}`);

        if (!checkboxProStates[idx]) {
            const selectedJobRoleProSkills = matchingJobRoleData[idx]?.ProfessionalSkills;
            const updatedSkills = Array.from(new Set([...selectPSkills, ...selectedJobRoleProSkills]));
            console.log(updatedSkills)
            setSelectPSkills(updatedSkills)
        } else {
            const deselectedJobRoleProSkills = matchingJobRoleData[idx]?.ProfessionalSkills;
            const updatedSkills = selectPSkills.filter(skill => !deselectedJobRoleProSkills.includes(skill))
            console.log(updatedSkills);
            setSelectPSkills(updatedSkills);
        }
    }

    const handleProSkillSelect = (skill) => {
        console.log('selected skill: ', skill)
        const updatedSkills = Array.from(new Set([...selectPSkills, skill]));
        setSelectPSkills(updatedSkills)
    }
    

    const verifyGenCheckboxStates = () => {
        const updatedCheckboxGenStates = checkboxGenStates.map((checked, idx) => {
            const jobRoleSkills = matchingJobRoleData[idx]?.GeneralSkills || [];
            const allSkillsPresent = jobRoleSkills.every(skill => selectGSkills.includes(skill));
            return allSkillsPresent;
        });
        setCheckboxGenStates(updatedCheckboxGenStates);
    };

    const verifyProCheckboxStates = () => {
        const updatedCheckboxProStates = checkboxProStates.map((checked, idx) => {
            const jobRoleSkills = matchingJobRoleData[idx]?.ProfessionalSkills || [];
            const allSkillsPresent = jobRoleSkills.every(skill => selectPSkills.includes(skill));
            return allSkillsPresent;
        });
        setCheckboxProStates(updatedCheckboxProStates);
    };

    
    useEffect(() => {
        verifyGenCheckboxStates();
    }, [selectGSkills, matchingJobRoleData]);

    useEffect(() => {
        verifyProCheckboxStates();
    }, [selectPSkills, matchingJobRoleData]);
    

    const handleRemoveGenSkill = (skill) => {
        const updatedSkills = selectGSkills.filter((allSkills) => allSkills !== skill);
        console.log(selectGSkills)
        setSelectGSkills(updatedSkills);
        console.log('skill removed:', skill)
    }

    const handleRemoveProSkill = (skill) => {
        const updatedSkills = selectPSkills.filter((allSkills) => allSkills !== skill);
        console.log(selectPSkills)
        setSelectPSkills(updatedSkills);
        console.log('skill removed:', skill)
    }

    const uploadRefImage = (filePath) => {
        setFileUploaded(true);
        const formData = new FormData();
        formData.append('inputFile', filePath)
        axios.post('https://api.allpos.in/applications/upload/scopehai', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
        }).then(res => {
            console.log(res.data.data);
            setUploadedFile(res.data.data);
            setFileUploaded(false);
        }).catch(err => {
            console.error('Error uploading file:', err);
        })
    }

    


    return (
        <div className="section mb-2 mt-5 full">
            <div className="wide-block pt-5 pb-2" style={{ borderBottom: "none" }}>
                <Form noValidate validated={validated} onSubmit={submitForm}>

                    <Form.Group className="boxed mb-3">
                        <Form.Label htmlFor="generalSkillsLevel2">Select General Skills</Form.Label>

                        <Form.Group className="boxed mb-2 d-none">
                            {/* <Form.Label htmlFor="generalSkillsLevel2">Select General Skills</Form.Label> */}
                            <InputGroup className="boxed mb-2 position-relative">
                                {/* <InputGroup.Text id="basic-addon1"><i classname="fa fa-star"></i></InputGroup.Text> */}
                                <Select
                                    id="generalSkillsLevel2"
                                    isMulti
                                    required
                                    options={generalSkillOptions}
                                    value={selectGSkills.map(skill => ({ value: skill, label: skill }))}
                                    onChange={handleSelectedGeneralSkills}
                                />
                            </InputGroup>
                        </Form.Group>

                        <div className="skills-container" style={{minHeight: '30px'}}>
                            <div className="skills-container-inner d-flex justify-content-between">
                                <div>
                                    {selectGSkills.map((skill, idx) => (
                                        <div key={`selectedGeneralSkills-${idx}`} className="chip chip-primary ms-05 mb-05 px-2">
                                            <span className="chip-label">{skill} <span onClick={() => handleRemoveGenSkill(skill)} style={{cursor: 'pointer'}}>
                                                <RxCross2 />
                                            </span></span>
                                        </div>
                                    ))}
                                </div>
                                <Button onClick={() => {setShowSelectGenSkillModal(true); console.log("modal selected")}}><FaSearch /></Button>
                            </div>
                        </div>
                        <selectGenSkillModal 
                            show={showSelectGenSkillModal}
                            onHide={setShowSelectGenSkillModal}
                        />

                        <Splide
                            options={{
                                rewind: false,
                                gap: '1rem',
                                perMove: 1,
                                perPage: 3,
                                type: 'loop',
                                autoplay: false,
                                interval: 3000,
                                breakpoints: {
                                    875: {
                                        perPage: 2
                                    },
                                    585: {
                                        perPage: 1
                                    }
                                }
                            }}
                            aria-label="React Splide Example">
                            {matchingJobRoleData.map((jobRole, idx) => (
                                jobRole?.GeneralSkills && jobRole?.GeneralSkills.length > 0 &&
                                <SplideSlide key={`jobRoleGen-${idx}`}> {/* Ensure each slide has a unique key */}
                                    <Card key={`genCard-${idx}`} className='cert-card' style={{ width: '18rem', height: '100%' }}>
                                        <Card.Body className='d-flex flex-column justify-content-between'>
                                            <div className="d-flex justify-content-between">
                                                <Card.Title className='text-center'>{jobRole?.Title}</Card.Title>
                                            </div>
                                            <div className=''>
                                                {jobRole?.GeneralSkills.map((skill, skillIdx) => (
                                                    <div key={`genSkill-${skillIdx}`} className="chip chip-outline chip-media chip-success ms-05 mb-05" style={{cursor: 'pointer'}}>
                                                        <span className='d-flex justify-content-center align-items-start' onClick={() => handleGenSkillSelect(skill)}>
                                                            {selectGSkills.includes(skill) ? <span className='pe-1'><img src={tick} alt="avatar" /></span>
                                                                : <span className='pe-1'><img src={tickOutlined} alt="avatar" /></span>}
                                                            <span className="chip-label">{skill}</span>
                                                        </span>
                                                        {selectGSkills.includes(skill) ? <span className='pe-1' onClick={() => handleRemoveGenSkill(skill)}>
                                                            <RxCross2 />
                                                        </span>
                                                            : <span className='pe-3'></span>}
                                                    </div>
                                                ))}


                                            </div>
                                            <div className='d-flex align-items-end justify-content-end'>
                                                {!checkboxGenStates[idx] ?
                                                    <div className="chip chip-outline chip-primary ms-05 mb-05">
                                                        <span className='d-flex justify-content-center align-items-center px-2' onClick={() => handleGenCheckboxChange(idx)} style={{ cursor: 'pointer' }}>
                                                            <span className="chip-label">Select All</span>
                                                        </span>
                                                    </div>
                                                    : <div className="chip chip-outline chip-danger ms-05 mb-05">
                                                        <span className='d-flex justify-content-center align-items-center px-2' onClick={() => handleGenCheckboxChange(idx)} style={{ cursor: 'pointer' }}>
                                                            <span className="chip-label">Remove All</span>
                                                        </span>
                                                    </div>
                                                }
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </SplideSlide>
                            ))}


                        </Splide>

                    </Form.Group>

                    <Form.Group className="boxed mb-3">
                        <Form.Label htmlFor="generalSkillsLevel2">Select Professional Skills</Form.Label>

                        <Form.Group className="boxed mb-2 d-none">
                            {/* <Form.Label htmlFor="profSkillsLevel2">Select Professional Skills</Form.Label> */}
                            <InputGroup className="boxed mb-2 position-relative">
                                <InputGroup.Text id="basic-addon1"><i className="fa fa-certificate"></i></InputGroup.Text>
                                <Select
                                    id="profSkillsLevel2"
                                    isMulti
                                    required
                                    options={professionalSkillOptions}
                                    value={selectPSkills.map(skill => ({ value: skill, label: skill }))}
                                    onChange={handleSelectedProfessionalSkills}
                                />
                            </InputGroup>
                        </Form.Group>

                        <div className="skills-container" style={{minHeight: '30px'}}>
                            <div className="skills-container-inner">
                                {selectPSkills.map((skill, idx) => (
                                    <div key={`selectedProfessionalSkills-${idx}`} className="chip chip-primary ms-05 mb-05 px-2">
                                        <span className="chip-label">{skill} <span onClick={() => handleRemoveProSkill(skill)} style={{cursor: 'pointer'}}>
                                            <RxCross2 />
                                        </span></span>
                                    </div>
                                ))}
                            </div>
                        </div>

                        <Splide
                            options={{
                                rewind: false,
                                gap: '1rem',
                                perMove: 1,
                                perPage: 3,
                                type: 'loop',
                                autoplay: false,
                                interval: 3000,
                                breakpoints: {
                                    875: {
                                        perPage: 2
                                    },
                                    585: {
                                        perPage: 1
                                    }
                                }
                            }}
                            aria-label="React Splide Example">
                            {matchingJobRoleData.map((jobRole, idx) => (
                                jobRole?.ProfessionalSkills && jobRole?.ProfessionalSkills.length > 0 &&
                                <SplideSlide key={`jobRolePro-${idx}`}>
                                    <Card key={`proCard-${idx}`} className='cert-card' style={{ width: '18rem', height: '100%' }}>
                                        <Card.Body className='d-flex flex-column justify-content-between'>
                                            <div className="d-flex justify-content-between">
                                                <Card.Title className='text-center'>{jobRole?.Title}</Card.Title>
                                                {/* <Form.Check
                                                    className='boxed mb-2'
                                                    type="checkbox"
                                                    id={`proCheckbox-${idx}`}
                                                    checked={checkboxProStates[idx]}
                                                    onChange={() => handleProCheckboxChange(idx)}
                                                /> */}
                                            </div>
                                            <div>
                                                {jobRole?.ProfessionalSkills.map((skill, idx) => (
                                                    <div key={`proSkill-${idx}`} className="chip chip-outline chip-media chip-success ms-05 mb-05" style={{cursor: 'pointer'}}>
                                                        <span className='d-flex justify-content-center align-items-start' onClick={() => handleProSkillSelect(skill)} style={{ cursor: 'pointer' }}>
                                                            {selectPSkills.includes(skill) ? <span className='pe-1'><img src={tick} alt="avatar" /></span>
                                                                : <span className='pe-1'><img src={tickOutlined} alt="avatar" /></span>}
                                                            <span className="chip-label">{skill}</span>
                                                        </span>
                                                        {selectPSkills.includes(skill) ? <span className='pe-1' onClick={() => handleRemoveProSkill(skill)}>
                                                            <RxCross2 />
                                                        </span>
                                                            : <span className='pe-3'></span>}
                                                    </div>
                                                ))
                                                }
                                            </div>
                                            <div className='d-flex align-items-end justify-content-end'>
                                                {!checkboxProStates[idx] 
                                                ? <div className="chip chip-outline chip-primary ms-05 mb-05">
                                                        <span className='d-flex justify-content-center align-items-center px-2' onClick={() => handleProCheckboxChange(idx)} style={{ cursor: 'pointer' }}>
                                                            <span className="chip-label">Select All</span>
                                                        </span>
                                                    </div>
                                                    : <div className="chip chip-outline chip-danger ms-05 mb-05">
                                                        <span className='d-flex justify-content-center align-items-center px-2' onClick={() => handleProCheckboxChange(idx)} style={{ cursor: 'pointer' }}>
                                                            <span className="chip-label">Remove All</span>
                                                        </span>
                                                    </div>
                                                }
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </SplideSlide>
                            ))
                            }
                        </Splide>

                    </Form.Group>

                    <Form.Group className="boxed mb-2" controlId="formFile">
                        <Form.Label>Upload Certification for the Professional Skill Mentioned (If Any)</Form.Label>
                        <InputGroup className="boxed mb-2 position-relative">
                            <InputGroup.Text id="basic-addon1"><i className="fa fa-paperclip"></i></InputGroup.Text>
                            <Form.Control type="file" onChange={(e) => { uploadRefImage(e.target.files[0]) }} />
                        </InputGroup>
                    </Form.Group>

                    <Form.Check
                        className='boxed my-4'
                        type="checkbox"
                        id="default-checkbox"
                        label="Choose if you would like to receive Job/work notifications on Whatsapp/Email."
                        onChange={(e) => setSelectNotification(e.target.value)}
                    />

                    <Button type="submit" className="btn btn-primary btn-lg btn-block" disabled={fileUploaded}>
                        Done
                        <i className="fa fa-chevron-right" style={{ fontSize: "14px", paddingLeft: "5px" }}></i>
                    </Button>

                </Form>
            </div>
        </div>
    )
}

const mapStateToProps = ({ auth }) => {
    const { userData, role, fullName, ID, submitFormStep1 } = auth

    return {
        userData,
        role,
        fullName,
        ID,
        submitFormStep1
    }
}

export default connect(mapStateToProps)(RegFormStep2)
