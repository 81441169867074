import React, { useEffect, useState } from 'react';
import { Card, Col, Row, Button, Image } from 'react-bootstrap';
import moment from 'moment';
import job5 from '../../images/jobs/comp5.webp'
import staticLogo from '../../images/Favicon_large-removebg-preview.png';
import location from '../../images/icon/location-svg.svg';
import tick from '../../images/icon/tick-image.png'
import profileIcon from '../../images/icon/suit-case-icon.svg';
import applicantIcon from '../../images/icon/applicants-icon.svg';
import timeIcon from '../../images/icon/time-icon.svg';
import { getAllJobs } from '../../../features/constants/apis';
import { connect } from 'react-redux';


const PlacementDriveCard = ({ drive, handleViewDriveDetails, authToken }) => {

    const [allJobs, setAllJobs] = useState([])
    const [profileCount, setProfileCount] = useState(null)
    const [totalOpenings, setTotalOpenings] = useState(null)
    const [minCtc, setMinCtc] = useState(null)
    const [maxCtc, setMaxCtc] = useState(null)

    const [PDSkills, setPDSkills] = useState([])

    // maximum character to show
    const maxChar = 150;

    useEffect(() =>{
        const fetchJobs = async() =>{
            const data = await getAllJobs(authToken)
            const filteredJobs = data.data.filter(job => job.PlacementDriveID === drive.ID)
            setAllJobs(filteredJobs)
            setProfileCount(filteredJobs.length)

            const totalOpenings = filteredJobs.reduce((sum, job) => {
                return sum + job.Openings
            }, 0)

            setTotalOpenings(totalOpenings)

            if(filteredJobs.length > 0){
                const ctcRanges = filteredJobs.map(job => {
                    const ctcRange = job.Ctc.split(' to ').map(Number)
                    return ctcRange;
                })

                const allCtcValues = ctcRanges.flat()

                const minimumCtc = Math.min(...allCtcValues)
                const maximumCtc = Math.max(...allCtcValues)

                setMinCtc(minimumCtc)
                setMaxCtc(maximumCtc)

            }

            // jobs skills
            console.log(filteredJobs)
            const skills = filteredJobs.map(job => job.Skills.ProfessionalSkills)
            console.log(skills.flat())
            setPDSkills(skills.flat())
        }
        fetchJobs()
    }, [])

    const calculateCountdown = (fromDate, ToDate) => {
        const now = moment();
        const fromDateMoment = moment(fromDate);
        const formattedToDate = fromDateMoment.format('DD MMMM YYYY');
        const diffDays = fromDateMoment.diff(now, 'days');

        let remainingTime;

        if (diffDays < 0) {
            remainingTime = "Placement Drive is no longer available";
        } else if (diffDays === 0) {
            remainingTime = "Placement drive ends today";
        } else {
            remainingTime = `${diffDays} days remaining`;
        }

        return { formattedToDate, remainingTime, diffDays };

    };

    return (
        <Col>
            <Card className='placement-drive-card'>
                <Card.Body>
                    <Row className='pb-2 border-bottom border-gray pd-card-head'>
                        <Col lg="3"><Card.Img variant="top" className="imaged rounded rounded-image" src={drive?.Image ? drive?.Image : staticLogo} /></Col>
                        <Col className='d-flex flex-column justify-content-center'>
                            <Card.Title className='mb-1'>{drive?.Title}</Card.Title>
                            <Card.Text className='drive-details dot-icon d-flex flex-wrap'>
                                <span className='d-flex gap-1 me-1'> <span className='rupee'>₹</span>{minCtc !== undefined ? minCtc : 0} - {maxCtc !== undefined ? maxCtc : 0} LPA</span>
                                <span className='ps-lg-1 d-flex gap-1 me-1'><Image className='icons' src={profileIcon}/>profiles({profileCount})</span>
                                <span className='ps-lg-1 d-flex gap-1 me-1'><Image className='icons' src={applicantIcon}/>openings({totalOpenings})</span>
                            </Card.Text>
                        </Col>
                    </Row>
                    <Card.Text className='my-2'>
                    <div className='ms-05'>
                        {PDSkills.slice(0, 5).map((skill, index) => (
                            <div key={index} className="chip bg-transparent text-black ms-05 mb-1">
                                <span className="chip-label d-flex justify-content-center align-items-center gap-1"><Image className='tick-outlined' src={tick}/>{skill}</span>
                            </div>
                        ))}
                        {PDSkills.length > 5 && (
                            <div className="chip bg-transparent text-black ms-05 mb-1">
                            <span className="chip-label d-flex justify-content-center align-items-center gap-1">
                            +{PDSkills.length - 5}
                            </span>
                            </div>
                        )}
                    </div>
                    </Card.Text>
                    <Card.Text className='py-3 min-h-6 pd-criteria'>{drive?.Criteria.substring(0, maxChar)  + (drive?.Criteria.length > maxChar ? '...' : '')}
                    </Card.Text>
                    <div className='d-flex justify-content-between align-items-center gap-3'>
                        <div>
                            <Card.Text className='mb-0 location'><Image className='pe-1' src={location} />{drive?.Location}</Card.Text>
                            <Card.Text className='date'>
                                {calculateCountdown(drive?.FromDate).formattedToDate} - {calculateCountdown(drive?.ToDate).formattedToDate} 
                                <span className='d-flex gap-1 text-muted text-black'>
                                <Image className='muted-icon' src={timeIcon}/>
                                {calculateCountdown(drive?.ToDate, drive?.ToDate).remainingTime}
                                </span>
                            </Card.Text>
                        </div>
                        <Button onClick={() => handleViewDriveDetails(drive?.ID)} variant="primary">View</Button>
                    </div>
                </Card.Body>
            </Card>
        </Col>
    );
};

const mapStateToProps = ({ auth }) => {
    const { userData, role, fullName, ID, authToken } = auth

    return {
        userData,
        role,
        fullName,
        ID,
        authToken
    }
}

export default connect(mapStateToProps)(PlacementDriveCard);
