import React, { useState } from 'react'
import { Form } from 'react-bootstrap'
import Logo from '../images/ScopeHaiLogo.png'
import StepperForm from './StepperForm'
import RegFormStep1 from './RegistrationFormComponents/RegFormStep1'
import RegFormStep2 from './RegistrationFormComponents/RegFormStep2'
import RegFormStep3 from './RegistrationFormComponents/RegFormStep3'
import { submitFormStep1, submitFormStep2, submitFormStep3 } from '../../features/auth/authSlice'
import CheckStepNumber from './CheckStepNumber'
import { useSearchParams } from 'react-router-dom'
import { connect } from 'react-redux'

function CompleteProfile({submitFormStep1, submitFormStep2, submitFormStep3, completeProfileStep}) {
    const [searchParams, setSearchParams] = useSearchParams({'step':'step1'})
    const currentStep = searchParams.get('step')
    console.log(currentStep)

    function changeForm (formStep){
        setSearchParams ({'step':formStep})
    }
    return (
        <div>
            <div className="appHeader">
                {!localStorage.getItem('certCourseID') && (
                    <div className="row w-100 d-flex justify-content-center">
                        <div className="d-flex justify-content-center">
                            <a href="#!" className="headerButton">
                            <img src={Logo} className="img-fluid" alt="Brand" width="220x220" />
                            </a>
                        </div>
                    </div>
                )}
            </div>
            <div id="appCapsule">
                <div className='container'>
                
                    <div class="extraHeader p-0">
                        
                        <div class="form-wizard-section">
                        {
                        <>
                        { currentStep === 'step1' &&
                            <>
                                <button className='button-item'>
                                    <strong >1</strong>
                                    <p>Step 1</p>
                                </button>
                                <button className='button-item'>
                                    <strong className='step-outlined'>2</strong>
                                    <p>Step 2</p>
                                </button>
                            </>
                        }
                        { currentStep === 'step2' &&
                            <>
                                <button className='button-item'>
                                    <strong className='step-outlined'>1</strong>
                                    <p>Step 1</p>
                                </button>
                                <button className='button-item'>
                                    <strong>2</strong>
                                    <p>Step 2</p>
                                </button>
                            </>
                        }
                        {/* <a href="component-form-wizard.html" class="button-item">
                            <strong>1</strong>
                            <p>Check</p>
                        </a> */}
                        
                        {/* <a href="component-form-wizard.html" class="button-item">
                            <strong>2</strong>
                            <p>Enter Address</p>
                        </a> */}
                        {/* <button className='button-item'>
                            <strong>3</strong>
                            <p>Step 3</p>
                        </button> */}
                        {/* <a href="component-form-wizard.html" class="button-item active">
                            <strong>3</strong>
                            <p>Payment</p>
                        </a> */}

                        </>

                        }
                        </div>
                    </div>
                    {/* <StepperForm stepsConfig={FORM_STEPS} /> */}
                    {currentStep === 'step1' &&
                        <RegFormStep1 changeForm={changeForm} submitFormStep1={submitFormStep1} completeProfileStep={completeProfileStep} ></RegFormStep1>
                    }
                    {currentStep === 'step2' &&
                        <RegFormStep2 changeForm={changeForm} submitFormStep2={submitFormStep2} completeProfileStep={completeProfileStep} ></RegFormStep2>
                    }
                    {currentStep === 'step3' &&
                        <RegFormStep3 changeForm={changeForm} submitFormStep3={submitFormStep3} completeProfileStep={completeProfileStep} ></RegFormStep3>
                    }
                    <CheckStepNumber></CheckStepNumber>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = ({auth}) => {
    const {
        completeProfileStep
    } = auth

    return {
        completeProfileStep
    }
}

const mapDispatchToProps = {
    submitFormStep1,
    submitFormStep2,
    submitFormStep3
}
  
export default connect( mapStateToProps, mapDispatchToProps) (CompleteProfile)