import React, { useState, useEffect } from 'react'
import { fetchCertificationCards, getCertificateAppliationById } from '../../features/constants/apis'
import { Row, Col, Card, Button, Form } from 'react-bootstrap'
import CertificationCardComp from './Cards/CertificationCardComp'
import { Link } from 'react-router-dom'
import { FaArrowLeft } from 'react-icons/fa'
import DataTable, { createTheme } from 'react-data-table-component';
import AdminCertificateModal from './Modals/AdminCertificateModal'

const AdminCertifications = ({authToken}) => {

    const [certificationCards, setCertificationCards] = useState([]);
    const [certificateID, setCertificateID] = useState(null)
    const [selectedCeritificate, setSelectedCertificate] = useState([])

    const[searchedTerm, setSearchedTerm] = useState('');
    
    const [showCertificateModal, setShowCertificateModal] = useState(false);

    useEffect(()=>{
        const fetchData = async () => {
            const data = await fetchCertificationCards(authToken);
            setCertificationCards(data);
            console.log(data)
        }
        fetchData();
    }, [authToken])

    const showCertificateModalHandler = (ID) =>{
        setCertificateID(ID)
        console.log(ID)
        setShowCertificateModal(true)
        const certificate = certificationCards.find(certificate => certificate.ID === ID)
        setSelectedCertificate(certificate)
    }
    console.log(certificateID)

    

    const desiredColumns = [
        {
            name: 'ID',
            selector: (row) => row.ID,
            // selector: (_, index) => index + 1,
            width: '100px',
            sortable: true
        },
        {
            name: 'Image',
            selector: (row) => <img src={row.Image} alt={row.Name} width={50} />,
            sortable: false,
            width: 'auto',
        },
        {
            name: 'Name',
            selector: (row) => <span style={{ color: "initial", cursor: "pointer" }} onClick={() => showCertificateModalHandler(row.ID)}>{row.Name}</span>,
            width: '150px',
            wrap: true,
            sortable: true
        },
        {
            name: 'Title',
            selector: (row) => row.Title,
            width: '300px',
            wrap: true,
            sortable: true
        },
        {
            name: 'Price',
            selector: (row) => row.Price,
            width: '80px',
            // sortable: true
        },
        {
            name: 'Description',
            selector: (row) => row.Description,
            width: '400px',
            wrap: true
        },
        {
          name: 'Stats',
          selector: (row) => [row.Stat1, row.Stat2, row.Stat3, row.Stat4].join(', '),
          width: '250px'
        },
    ];

    const filteredCertificate = certificationCards.filter(certificate => certificate.Name.toLowerCase().includes(searchedTerm.toLocaleLowerCase()));

    createTheme('solarized', {
        // text: {
        //   primary: '#45A994',
        //   secondary: '#F7BD4E',
        // },
        background: {
          default: 'transparent',
        },
        context: {
          background: '#CDFFD8',
          text: '#FFFFFF',
        },
        divider: {
          default: '#45A994',
        },
        action: {
          button: 'rgba(0,0,0,.54)',
          hover: 'rgba(0,0,0,.08)',
          disabled: 'rgba(0,0,0,.12)',
        },
      }, 'light');

    // Function to convert the table data to CSV
    const convertToCSV = (data) => {
        const headers = Object.keys(data[0]);
        const rows = data.map(row => headers.map(header => `"${row[header]}"`).join(','));
        return [headers.join(','), ...rows].join('\n');
    };

    // Function to trigger the CSV download
    const downloadCSV = (csvData, filename) => {
        const blob = new Blob([csvData], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.setAttribute('hidden', '');
        a.setAttribute('href', url);
        a.setAttribute('download', filename);
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    };

    // Export table data to CSV
    const exportToCSV = () => {
        // Prepare the data for CSV export
        const dataToExport = certificationCards.map((row, index) => ({
            "ID": index + 1,
            "Image": row.Image,
            "Name": row.Name,
            "Title": row.Title,
            "Price": row.Price,
            "Description": row.Description,
            "Stats": [row.Stat1, row.Stat2, row.Stat3, row.Stat4].join(', '),
        }));
        const csv = convertToCSV(dataToExport);
        downloadCSV(csv, 'Certifications.csv');
    };

  return (
    <div id="appCapsule">
        <div className='section full py-4'>
            <div className='container'>
                <Button variant="primary mb-2">
                    <Link to="/" type="submit" className='my-2 text-white'><FaArrowLeft style={{width: '15px', height: '15px', marginRight: "7px"}}/>Go to Dashboard</Link>
                </Button>
                <h1 className="title text-dark">Certifications</h1>
                <div className="section-title text-dark ps-0">Recommended training and placement programs</div>
            </div>
        </div>
        <div className='section full mt-2 mb-4'>
            <div className='container'>
                <div className="d-flex justify-content-end">
                    <Row>
                        <Col xs="auto">
                            <Form.Control
                            type="text"
                            placeholder="Search"
                            className=" mr-sm-2"
                            onChange={(e)=> setSearchedTerm(e.target.value)}
                            />
                        </Col>
                        <Col xs="auto">
                            <Button variant="outline-warning" onClick={exportToCSV}>Export to CSV</Button>
                        </Col>
                    </Row>
                </div>
                <Row className='justify-content-between my-3'>
                    <div>
                        {certificationCards.length > 0 && (
                        <DataTable
                            columns={desiredColumns}
                            data={filteredCertificate}
                            striped={true}
                            theme='solarized'
                        />
                        )}
                    </div>
                </Row>

                <AdminCertificateModal 
                    show={showCertificateModal}
                    certificationData={selectedCeritificate}
                    onHide={()=> setShowCertificateModal(false)}
                    // applicationsOfUser={applicationsOfUser}
                    certificateID={certificateID}
                />
            </div>
        </div>
    </div>
  )
}

export default AdminCertifications