import React, { useEffect, useRef } from "react";
import Iframe from 'react-iframe';
import { Button } from "react-bootstrap";

const CertificateLandingPage = () => {

    const url = 'https://ecampus.yourerpcoach.com/'

    return (
        <div id="appCapsule p-0 Iframe-section">
            <div className="section mt-2 full">
                <div className="">
                    <Iframe 
                        url={url}
                        styles={{width: "100%", height: "100vh"}}
                        id="iframe"
                        className=""
                        display="block"
                    />
                    <Button variant="primary" className="btn-fixed mb-2">Enroll Now</Button>
                </div>
            </div>
        </div>
    );
};

export default CertificateLandingPage;
