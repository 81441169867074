import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { fetchJobData, getCertificateAppliationById } from "../../../features/constants/apis";
import { Badge, Button, Card, Col, Modal, NavItem, Row, Tab, Tabs } from "react-bootstrap";
import { GoDotFill } from "react-icons/go";
import job5 from "../../images/jobs/job6.png"
import userPhoto from '../../images/user-profile.webp'
import { Link, useNavigate } from "react-router-dom";

const AdminCertificateModal = ({ show, onHide, certificationData,certificateID, authToken }) => {

    const [key, setKey] = useState('home');
    const [applicationsOfUser, setApplicationsOfUser] = useState([])

    const navigate = useNavigate();

    // // // console.log(certificationData)
    // // console.log(certificateID)

    useEffect(()=>{
        const fetchData = async() =>{
            // // console.log(certificateID)
            const data = await getCertificateAppliationById(authToken, certificateID)
            // // console.log(data)
            setApplicationsOfUser(data.data)
        }
        fetchData()
    },[certificateID, authToken])

    // Open user profile on click
    const handleOpenUserProfile = (applicantID) => {
        // // console.log(applicantID)
        navigate(`/candidate-profile?candidate=${applicantID}`)
    }

    // Function to convert the table data to CSV
    const convertToCSV = (data) => {
        const headers = Object.keys(data[0]);
        const rows = data.map(row => headers.map(header => `"${row[header]}"`).join(','));
        return [headers.join(','), ...rows].join('\n');
    };

    // Function to trigger the CSV download
    const downloadCSV = (csvData, filename) => {
        const blob = new Blob([csvData], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.setAttribute('hidden', '');
        a.setAttribute('href', url);
        a.setAttribute('download', filename);
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    };

    // Export table data to CSV
    const exportToCSV = () => {
        // Prepare the data for CSV export
        const dataToExport = applicationsOfUser.map((row, index) => ({
            "ID": row.ID,
            "Name": row.CandidateName,
            "Phone": row.CandidatePhone,
            "CourseID": row.CourseID,
            "CourseTitle": row.CourseTitle,
        }));
        const csv = convertToCSV(dataToExport);
        downloadCSV(csv, 'CourseAppliations.csv');
    };

    return (
        <Modal
            show={show}
            onHide={onHide}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >

            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    <h1 className="title text-dark">Certification Details</h1>
                </Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <Col>
                    <div className='container'>
                        <Row className="row cert-details-ID mt-20">
                            <h2 className="post-title">{certificationData?.Name}</h2>
                            <Col className="col-xxl-9 col-xl-8">
                                <div className="details-post-data me-xxl-5 pe-xxl-4">
                                    <div className="img-meta mb-15">
                                        <img src={certificationData?.Image} data-src="images/blog/blog_img_32.jpg" alt="" className="lazy-img mb-2" style={{ aspectRatio: '3 / 2', objectFit: 'fill', borderRadius: '15px', width: '85%' }} />
                                    </div>
                                    <div className="post-block border-style mt-50 lg-mt-30">
                                        <div className="d-flex align-items-center">
                                            <h3 className="block-title">Course Overview</h3>
                                        </div>
                                        <p>{certificationData?.Description}</p>
                                    </div>
                                </div>
                            </Col>
                            <Col className="col-xxl-3 col-xl-4">
                                <div className="job-company-info ms-xl-5 ms-xxl-0 lg-mt-50 xs-mt-10">
                                    <div>
                                        <ul className="job-meta-data row style-none ps-0">
                                            <li className="col-xl-6 col-md-4 col-sm-6">
                                                <span>Price</span>
                                                <div>₹{certificationData?.Price}/-</div>
                                            </li>
                                            <li className="col-xl-6 col-md-4 col-sm-6">
                                                <span>Duration</span>
                                                <div>2 Months</div>
                                            </li>
                                            <li className="col-xl-12 col-md-4 col-sm-6">
                                                <span>Skills</span>
                                                <div className="job-tags d-flex flex-wrap pt-15">
                                                    <span>{certificationData?.Stat1}</span>
                                                    <span>{certificationData?.Stat2}</span>
                                                    <span>{certificationData?.Stat3}</span>
                                                    <span>{certificationData?.Stat4}</span>
                                                </div>
                                            </li>
                                        </ul>
                                        {/* <Button href="#" className="btn-one w-100 mt-25" data-bs-toggle="modal" data-bs-target="#loginModal">Apply Now</Button> */}
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <div className="d-flex gap-2 align-items-center justify-content-between pb-2">
                            <div className="d-flex align-items-center gap-2">
                                <div className="section-title ps-0">All Applied Candidates</div>
                                <Badge bg="secondary">{applicationsOfUser ? applicationsOfUser.length : '0'}</Badge>
                            </div>
                            <div>
                                <Col xs="auto">
                                    <Button variant="outline-warning" onClick={exportToCSV}>Export applicatinos to CSV</Button>
                                </Col>
                            </div>
                        </div>
                        <ul className="listview image-listview media">
                            {
                            applicationsOfUser === null &&
                                <li className='my-2'>
                                <div className="item">
                                    <div className="imageWrapper in">
                                        <div className="in">
                                            <div>Loading...</div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            }

                            {
                            applicationsOfUser !== null && applicationsOfUser.length === 0 &&
                                <li className='my-2'>
                                <div className="item">
                                    <div className="imageWrapper in">
                                        <div className="in">
                                            <div>No Application found</div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            }
                            {
                            applicationsOfUser !== null && applicationsOfUser.length > 0 &&
                            applicationsOfUser.map((item, i) => {
                                    return (
                                        <li key={i} className='my-2' onClick={()=> handleOpenUserProfile(item.CandidateID)}>
                                            <div className="item">
                                                <div className="imageWrapper in">
                                                <img src={item.CandidateImage ? item.CandidateImage : userPhoto} alt="" className="imaged w64" />
                                                    <div className="in">
                                                        <div>{item.CandidateName}</div>
                                                    </div>
                                                </div>
                                                <div className="in">
                                                    <div>{item.JobName}</div>
                                                </div>
                                                <div className='d-flex align-items-center'>
                                                </div>
                                            </div>
                                        </li>
                                    )
                                })
                            }
                        </ul>
                    </div>
                </Col>
            </Modal.Body>

        </Modal>
    )
}

const mapStateToProps = ({ auth, selectedDrive }) => {
    const { ID, role } = auth
    const { selectedJob } = selectedDrive

    return {
        ID,
        role,
        selectedJob
    }
}


export default connect(mapStateToProps)(AdminCertificateModal);