import React, { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import { Form, Button, InputGroup } from 'react-bootstrap'
import ReactDatePicker from 'react-datepicker';
import { connect } from "react-redux";
import { GetCandidateByID, fetchAllEducationDegrees, fetchAllEducationLevels, postUserEducation } from '../../../features/constants/apis';

const AddNewEducation = ({ID, authToken, refetchUserData, ...props}) => {
    const [eduID, setEduID] = useState(1);
    const [selectedStartYear, setSelectedStartYear] = useState(new Date().getFullYear());
    const [selectedEndYear, setSelectedEndYear] = useState(new Date().getFullYear());
    const [pursuing, setPursuing] = useState(false);

    const [candidateData, setCandidateData] = useState([])

    const [eduLevels, setEduLevels] = useState([])
    const [eduDegrees, setEduDegrees] = useState([])
    

    // console.log(ID)

    // Education fields
    const [eduInstitution, setEduInstitution] = useState('')
    const [eduLevel, setEduLevel] = useState('')
    const [eduDegree, setEduDegree] = useState('')

    const renderYearContent = (year) => {
        const tooltipText = `Tooltip for year: ${year}`;
        return <span title={tooltipText}>{year}</span>;
    };

    const handleStartYearChange = (date) => {
        setSelectedStartYear(date.getFullYear());
    };
    const handleEndYearChange = (date) => {
        setSelectedEndYear(date.getFullYear());
    };

    useEffect(() => {
        const eduLevels = async () => {
            const data = await fetchAllEducationLevels()
            setEduLevels(data)
        }
        eduLevels()
    }, [])

    useEffect(() => {
        const eduDegree = async () => {
            const data = await fetchAllEducationDegrees();
            setEduDegrees(data)
        }
        eduDegree()
    }, [])

    // submit education form
    const [validated, setValidated] = useState(false);

    const handlePursuingChange = (e) => {
        setPursuing(e.target.checked);
        setSelectedEndYear(e.target.checked ? null : new Date().getFullYear());
    }

    const handleSubmit = async(e) => {
        e.preventDefault()
        const form = e.currentTarget;

        if (form.checkValidity() === false) {
            e.stopPropagation();
            setValidated(true);
        } else {
            // console.log('Form is valid. Submitting...');
            
            const educationData = {
                'eduID': eduID,
                'startYear': selectedStartYear,
                'endYear': pursuing ? '' : selectedEndYear,
                'institution': eduInstitution,
                'qualification': eduLevel,
                'degree': eduDegree,
                'pursuing': pursuing
            }

            const jsonEduData = JSON.stringify(educationData)
            // console.log(jsonEduData)

            const userEducationDetails = async () => {
                const eduData = await postUserEducation(ID, educationData, authToken)
                // console.log(eduData)

                const candidateInfo = await GetCandidateByID(ID, authToken)
                setCandidateData(candidateInfo)
            };
            await userEducationDetails();   

            // console.log(candidateData)

            // console.log(educationData)

            refetchUserData()
            props.onHide()

            setValidated(false);
        }

        // // console.log(eduInstitution)
        setEduInstitution("")
    }

    const handleCloseModal= () =>{
        props.onHide()
        setEduInstitution("")
        setValidated(false);
    }


    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Add Education
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                    <div className="d-flex gap-3 my-2">
                        <div className="start-date d-flex flex-column">
                            <Form.Label>Start Date</Form.Label>
                            <ReactDatePicker
                                required
                                selected={new Date(selectedStartYear, 0, 1)}
                                onChange={handleStartYearChange}
                                renderYearContent={renderYearContent}
                                showYearPicker
                                dateFormat="yyyy"
                            />
                        </div>
                        <div className="end-date d-flex flex-column">
                            <Form.Label>End Date</Form.Label>
                            <ReactDatePicker
                                required
                                selected={selectedEndYear ? new Date(selectedEndYear, 0, 1) : null}
                                onChange={handleEndYearChange}
                                renderYearContent={renderYearContent}
                                showYearPicker
                                dateFormat="yyyy"
                                minDate={new Date(selectedStartYear, 0, 1)}
                                disabled={pursuing}
                            />
                        </div>
                        <div className="end-date d-flex flex-column">
                            <Form.Label>Pursuing</Form.Label>
                            <InputGroup className="mb-3">
                                <InputGroup.Checkbox aria-label="Checkbox for following text input" checked={pursuing} onChange={handlePursuingChange}/>
                                {/* <Form.Control aria-label="Text input with checkbox" /> */}
                            </InputGroup>
                        </div>
                    </div>
                    <div className="my-2">
                        <Form.Group className="mb-3">
                            <Form.Label>Institution Name</Form.Label>
                            <Form.Control
                                required
                                type="text"
                                onChange={(e)=>setEduInstitution(e.target.value)}
                                value={eduInstitution}
                                placeholder="Enter Institution Name"
                            />
                            <Form.Control.Feedback type="invalid">
                                Please provide a valid Title.
                            </Form.Control.Feedback>
                        </Form.Group>
                    </div>
                    <div className="my-2">
                        <Form.Group className='my-3'>
                            <Form.Label>Education Level</Form.Label>
                            <Form.Select 
                                required
                                onChange={(e)=>setEduLevel(e.target.value)}
                                aria-label="Default select example">
                                <option selected disabled value="">Select your educational qualification</option>
                                {
                                    eduLevels.map((level, idx) => (
                                        <option key={level.ID} value={level.Level}>{level.Level}</option>
                                    ))
                                }
                            </Form.Select>
                        </Form.Group>
                    </div>
                    <div className="my-2">
                        <Form.Group className='my-3'>
                            <Form.Label>Education Degree</Form.Label>
                            <Form.Select 
                                required
                                onChange={(e)=>setEduDegree(e.target.value)}
                                aria-label="Default select example">
                                <option selected disabled value="">Select your educational degree</option>
                                {
                                    eduDegrees.map((degree, idx) => (
                                        <option key={degree.ID} value={degree.Education}>{degree.Education}</option>
                                    ))
                                }
                            </Form.Select>
                        </Form.Group>
                    </div>
                    <Modal.Footer>
                        <Button type="submit">Add</Button>
                        <Button variant='outline-primary' onClick={handleCloseModal}>Close</Button>
                    </Modal.Footer>
                </Form>

            </Modal.Body>
        </Modal>
    )
}

const mapStateToProps = ( { auth } ) => {
    const { userData, role, ID, authToken } = auth
  
    return {
        userData,
        role,
        ID,
        authToken
    }
  }


export default connect(mapStateToProps)(AddNewEducation)