import React from 'react';
import { Row, Col, Card } from 'react-bootstrap';
import { ShimmerCircularImage, ShimmerTitle, ShimmerButton, ShimmerText } from "react-shimmer-effects";

const ShimmerCard = () => {
  return (
    <Card className='placement-drive-card'>
      <Card.Body>
        <Row className='pb-2 border-bottom border-gray pd-card-head'>
          <Col lg="3" md='3' xs='3'>
            <ShimmerCircularImage size={95} />
          </Col>
          <Col lg="9" md='9' xs='9'>
            <Card.Title>
              <ShimmerTitle line={2} gap={10} variant="primary" />
            </Card.Title>
          </Col>
        </Row>
        <Card.Text className='py-2'>
          <ShimmerTitle line={2} gap={10} variant="primary" />
        </Card.Text>
        <div className='d-flex justify-content-between align-items-center gap-3' style={{ height: "50px" }}>
          <ShimmerText line={2} gap={10} variant="primary" />
          <ShimmerButton size="md" />
        </div>
      </Card.Body>
    </Card>
  );
};

const ShimmerEmpPd = () => {
  const cards = Array(8).fill(null).map((_, index) => (
    <Col key={index}>
      <ShimmerCard />
    </Col>
  ));

  return (
    <div id="appCapsule">
      <div className="section mb-2 mt-2 full">
        <div className="container pb-2">
          <Row xs={1} md={2} className="g-4">
            {cards}
          </Row>
        </div>
      </div>
    </div>
  );
};

export default ShimmerEmpPd;