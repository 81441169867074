import React, { useState, useEffect } from 'react'
import axios from '../../AxiosConfig'
import { fetchCertificationCards } from '../../features/constants/apis'
import { Row, Col, Card, Button } from 'react-bootstrap'
import CertificationCardComp from './Cards/CertificationCardComp'
import { Link } from 'react-router-dom'
import { FaArrowLeft } from 'react-icons/fa'
import { connect } from 'react-redux'

const Certificates = ({authToken}) => {

    const [certificationCards, setCertificationCards] = useState([]);

    useEffect(()=>{
        const fetchData = async () => {
            const data = await fetchCertificationCards(authToken);
            setCertificationCards(data);
            console.log(data)
        }
        fetchData();
    }, [authToken])

  return (
    <div id="appCapsule">
        <div className='section full py-4'>
            <div className='container'>
                <Button variant="primary mb-2">
                    <Link to="/" type="submit" className='my-2 text-white'><FaArrowLeft style={{width: '15px', height: '15px', marginRight: "7px"}}/>Go to Dashboard</Link>
                </Button>
                <h1 className="title text-dark">Certification & Trainings</h1>
                <div className="section-title text-dark ps-0">Recommended training and placement programs</div>
                {/* <p className='text-dark'>Here are the recommended training and placement programs for you.<br/>We have considered the data you have submitted with us and our smart algorithms have come up with some upskilling options for you.</p> */}
            </div>
        </div>
        <div className='section full mt-2 mb-4'>
            <div className='container'>
                <Row className='justify-content-between my-3'>
                    {certificationCards.map((v,k) => {
                        return(
                            <Col key={k} md="auto" sm="1" className="mb-2 d-flex justify-content-center">
                                <CertificationCardComp v={v}></CertificationCardComp>
                            </Col>
                        )
                    })}
                </Row>
            </div>
        </div>
    </div>
  )
}

const mapStateToProps = ({auth}) => {
    const {authToken} = auth;
    return(
        authToken
    )
}

export default connect(mapStateToProps)(Certificates)
