import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { connect } from 'react-redux'
import axios from '../../AxiosConfig'
import Loading from '../images/icon/loading-animate-allpos.svg'
import { showAppToast } from '../../features/utilities/appToastSlice'

const Load = ({
  categoriesDataUpdate,
  itemsDataUpdate
}) => {
  let navigate = useNavigate()
    useEffect(() => {
      setTimeout(() => {
        axios.get('/ItemCategories/business').then(res => {
          if (res.data.message === 'Success') {
            categoriesDataUpdate({categories: res.data.data})
            axios.get('/Items/business').then(res => {
              if (res.data.message === 'Success') {
                itemsDataUpdate({items: res.data.data})
                navigate('/newOrder')
              } else {
                showAppToast({toastMessage: 'Something went wrong',background: 'danger',icon: 'close',position: 'center',timeout: 1000})
              }
            })
          } else {
            showAppToast({toastMessage: 'Something went wrong',background: 'danger',icon: 'close',position: 'center',timeout: 1000})
          }
        })
      }, 15000)
    }, [])
  return (
    <div id="appCapsule" className="pt-5">
      <div className="error-page" style={{ padding: '0px', maxWidth: 'initial' }}>
        <div className="icon-box text-danger">
          <img
            src={Loading}
            alt="Brand"
            width="100%"
            style={{ maxWidth: '550px' }}
          />
        </div>
        <button className="btn btn-light btn-lg rounded mb-1" disabled>
          <span
            className="spinner-grow spinner-grow-sm me-05"
            role="status"
            aria-hidden="true"
          ></span>
          Loading...
        </button>
      </div>
    </div>
  )
}

const mapStateToProps = ({ auth }) => {
  const { authToken } = auth

  return {
    authToken
  }
}

const mapDispatchToProps = {
  showAppToast
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Load)
