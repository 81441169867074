import React, { useEffect, useRef } from 'react';
import { Chart, registerables } from 'chart.js';
import CrosshairPlugin from 'chartjs-plugin-crosshair';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { useSelector } from 'react-redux';

const UserChart = ({handleDateSelection}) => {
  // const { handleDateSelection } = props;
  const { themeValues } = useSelector((state) => state.auth);
  const chartContainer = useRef(null);

  const getPastSevenDays = () => {
    const today = new Date();
    const presentDay = new Date(today.setDate(today.getDate() + 1));
    const labels = [];
    for (let i = 0; i < 7; i++) {
      const pastDay = new Date(today.setDate(today.getDate() - 1));
      labels.push(pastDay.toLocaleDateString('en-US', { day: 'numeric', month: 'short' }));
    }
    return labels.reverse(); // Reverse to display most recent date first
  };

  const ChartTooltipForCrosshair = React.useMemo(() => {
    return {
      enabled: true,
      position: 'nearest',
      backgroundColor: themeValues.foreground,
      titleColor: themeValues.primary,
      titleFont: themeValues.font,
      bodySpacing: 10,
      bodyColor: themeValues.body,
      bodyFont: themeValues.font,
      padding: 15,
      cornerRadius: parseInt(themeValues.borderRadiusMd, 10),
      displayColors: false,
      borderColor: themeValues.separator,
      borderWidth: 1,
      intersect: false,
      mode: 'index',
    };
  }, [themeValues]);

  const Crosshair = React.useMemo(() => {
    return {
      sync: {
        enabled: false,
      },
      zoom: {
        enabled: false,
      },
      line: {
        color: themeValues.separator,
        width: 1,
      },
    };
  }, [themeValues]);

  const data = React.useMemo(() => {
    const labels = getPastSevenDays();
    const startDate = labels[0];
    const endDate = labels[6];
    if (handleDateSelection) {
      handleDateSelection(startDate, endDate);
    }
    return {
      labels,
      datasets: [
        {
            label: ['No. of Registrations'],
            data: [55, 45, 55, 45, 55, 45, 55],
            fill: false,
            cubicInterpolationMode: 'monotone',
            borderColor: themeValues.primary,
            borderWidth: 2,
            pointBackgroundColor: themeValues.primary,
            pointBorderColor: themeValues.primary,
            pointHoverBackgroundColor: themeValues.primary,
            pointHoverBorderColor: themeValues.primary,
            pointRadius: 3,
            pointBorderWidth: 3,
        },
        {
            label: ['1st Logins'],
            data: [40, 34, 48, 41, 37, 40, 30],
            fill: false,
            cubicInterpolationMode: 'monotone',
            borderColor: themeValues.secondary,
            borderWidth: 2,
            pointBackgroundColor: themeValues.secondary,
            pointBorderColor: themeValues.secondary,
            pointHoverBackgroundColor: themeValues.secondary,
            pointHoverBorderColor: themeValues.secondary,
            pointRadius: 3,
            pointBorderWidth: 3,
        },
        {
            label: ['Repeat Logins'],
            data: [15, 25, 15, 25, 15, 25, 15],
            fill: false,
            cubicInterpolationMode: 'monotone',
            borderColor: themeValues.danger,
            borderWidth: 2,
            pointBackgroundColor: themeValues.danger,
            pointBorderColor: themeValues.danger,
            pointHoverBackgroundColor: themeValues.danger,
            pointHoverBorderColor: themeValues.danger,
            pointRadius: 3,
            pointBorderWidth: 3,
        },
        {
            label: ['Milestones'],
            data: [30, 36, 22, 29, 33, 30, 40],
            fill: false,
            cubicInterpolationMode: 'monotone',
            borderColor: themeValues.success,
            borderWidth: 2,
            pointBackgroundColor: themeValues.success,
            pointBorderColor: themeValues.success,
            pointHoverBackgroundColor: themeValues.success,
            pointHoverBorderColor: themeValues.success,
            pointRadius: 3,
            pointBorderWidth: 3,
        },
      ],
    };
  }, [themeValues]);

  const config = React.useMemo(() => {
    return {
      type: 'line',
      plugins: [CrosshairPlugin, ChartDataLabels],
      options: {
        layout: {
          padding: 0,
        },
        showLine: true,
        plugins: {
          crosshair: Crosshair,
          datalabels: false,
          tooltip: ChartTooltipForCrosshair,
          legend: true,
          streaming: false,
        },
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          y: {
            type: 'linear',
            title: {
                display: true,
                text: 'Number of Users',
            },
            grid: {
              display: true,
              lineWidth: 1,
              color: themeValues.separatorLight,
              drawBorder: false,
              drawTicks: true,
            },
            ticks: {
              padding: 8,
              stepSize: 5,
              fontColor: themeValues.alternate,
            },
          },
          x: {
            type: 'category',
            title: {
                display: true,
                text: 'Day',
            },
            grid: {
              display: true,
              drawTicks: true,
              drawBorder: false,
            },
            ticks: { fontColor: themeValues.alternate },
          },
        },
      },
      data,
    };
  }, [themeValues, data, ChartTooltipForCrosshair, Crosshair]);

  useEffect(() => {
    let myChart = null;
    if (chartContainer && chartContainer.current) {
      Chart.register(...registerables);

      myChart = new Chart(chartContainer.current, config);
    }
    return () => {
      if (myChart) {
        myChart.destroy();
      }
    };
  }, [config]);

  return <canvas ref={chartContainer} />;
};

export default React.memo(UserChart);