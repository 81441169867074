import { Checkbox } from '@mui/material';
import { React, useState } from 'react'
import { Form, Button } from 'react-bootstrap'
import { useNavigate } from "react-router-dom";
import CheckStepNumber from '../CheckStepNumber';

const RegFormStep3 = ({changeForm, submitFormStep3, completeProfileStep}) => {
    let navigate = useNavigate();
    const [validated, setValidated] = useState(false);
    const [selectNotification, setSelectNotification] = useState(false);
    console.log('Went to step 3')

    function submitForm(e) {
        e.preventDefault();

        console.log(completeProfileStep)
        const newStep3RegData = {
            'selectNotification': selectNotification,
            'stepNo': 3,
            'completeProfileStep': completeProfileStep
        }
        console.log(selectNotification)
        console.log(newStep3RegData)
        submitFormStep3(newStep3RegData)
    }

  return (
    <div className="section mb-2 mt-5 full">
        <div className="wide-block pt-5 pb-2">
            <Form noValidate validated={validated} onSubmit={submitForm}>
                <Form.Check
                    className='boxed mb-2'
                    type="checkbox"
                    id="default-checkbox"
                    label="Choose if you would like to receive Job/work notifications on Whatsapp/Email."
                    onChange={(e) => setSelectNotification(e.target.value)}
                />
                <Button type="submit" className="btn btn-primary btn-lg btn-block">Done<i className="fa fa-chevron-right" style={{fontSize: "14px", paddingLeft: "5px"}}></i></Button>
            </Form>
        </div>
    </div>
  )
}

export default RegFormStep3
