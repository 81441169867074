import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Button, Card, Col, Row } from 'react-bootstrap'

const CertificationCardComp = ({ v }) => {

    const navigate = useNavigate();
    const handleViewCertificationDetails = (courseID) =>{
        console.log(courseID)
        // navigate(`/certification-details?id=${courseID}`)
        navigate(`/certification-iframe?id=${courseID}`)
    }

    return (
        <div>
        <Card className='cert-card' style={{ width: '18rem', height: '100%' }}>
            <Card.Body className='d-flex flex-column justify-content-between'>
                <Card.Img className='mb-1' variant="top" src={v.Image} />
                <Card.Title className='text-center'>{v.Name}</Card.Title>
                <Row>
                    <Col>
                        <Card.Text className='text-center'>{v.Stat1}</Card.Text>
                    </Col>
                    <Col>
                        <Card.Text className='text-center'>{v.Stat2}</Card.Text>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Card.Text className='text-center'>{v.Stat3}</Card.Text>
                    </Col>
                    <Col>
                        <Card.Text className='text-center'>{v.Stat4}</Card.Text>
                    </Col>
                </Row>
                <div className='mt-2 d-flex justify-content-between align-items-center'>
                    <h5 className='ms-2 mt-2' style={{fontWeight: '700'}}>₹{v.Price}/-</h5>
                    <Button onClick={() => handleViewCertificationDetails(v?.ID)} variant="primary">View</Button>
                </div>
            </Card.Body>
        </Card>
        </div>
    )
}

export default CertificationCardComp