import React from "react";
import { connect } from "react-redux";
import { Link, useNavigate } from 'react-router-dom';
import { useEffect, useState } from "react";
import { useLocation } from 'react-router-dom';
import { Row, Col, Card, Button, Form, InputGroup, Badge, Modal, Image  } from "react-bootstrap";
import job5 from '../images/jobs/job6.png';
import staicLogo from '../images/Favicon_large-removebg-preview.png';
import userPhoto from '../images/user-profile.webp';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';
import DatePicker from "react-datepicker";
import { setToken } from "../../features/auth/authSlice";
import { createJobApplication, deletePlacementDriveByID, fetchPlacementDrivesData, GetAllApplicationsOfDrive, getAllJobs, postNewPlacementDriveJob, updatePlacementDrive } from "../../features/constants/apis";
import { setSelectedJob } from "../../features/data/selectedDriveSlice.js";
import ShimmerEmpPdDetails from "./Shimmer/ShimmerEmpPdDetails.js";
import DeletePdModal from "./Modals/DeletePdModal.js";
import { GoDotFill } from "react-icons/go";
import { FaArrowLeft, FaPen } from "react-icons/fa";
import { RiDeleteBin6Line } from "react-icons/ri";
import { BsSendArrowUp } from "react-icons/bs";
import { AiOutlineClose } from "react-icons/ai";
import profileIcon from '../images/icon/suit-case-icon.svg';
import applicantIcon from '../images/icon/applicants-icon.svg';
import timeIcon from '../images/icon/time-icon.svg';
import NewJobForm from "./Forms/NewJobForm.js";
import JobListCard from "./JobListCard.js";


const EmpPlacementDrivesDetails = ({drive, driveID, userData, role, fullName, job, ID, authToken, setSelectedJob}) =>{

    // editable field states
    const [editableTitle, setEditableTitle] = useState(drive?.Title || '');
    const [editableCriteria, setEditableCriteria] = useState(drive?.Criteria || '');
    const [editableLocation, setEditableLocation] = useState(drive?.Location || '');
    const [editableFromDate, setEditableFromDate] = useState(drive?.FromDate || '');
    const [editableToDate, setEditableToDate] = useState(drive?.ToDate || '');


    //job details
    const [minCtc, setMinCtc] = useState(null)
    const [maxCtc, setMaxCtc] = useState(null)
    const [profileCount, setProfileCount] = useState(null)
    const [totalOpenings, setTotalOpenings] = useState(null)
     
    const [placementDrivesData, setPlacementDrivesData] = useState([]);

    const [allJobs, setAllJobs] = useState([])

    const [ApplicationsOfUser, setApplicationsOfUser] = useState(null);

    const [isEditing, setIsEditing] = useState(false);

    const [isNewJob, setIsNewJob] = useState(false);

    // modal delete
    const [modalShow, setModalShow] = React.useState(false);

    // new job form modal
    const [showJobFormModal, setShowJobFormModal] = useState(false)

    const handleShowNewjobFormModal = () =>{
        setShowJobFormModal(true)
        console.log('modal open')
    }

    const handleCloseNewJobModal = () =>{
        setShowJobFormModal(false)
    }
 
    
    // const { Title } = useParams();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const GetDriveID = searchParams.get('drive');

    // Go back
    const adminToBack = `/admin-placement-drives`
    const empToBack = `/emp-placement-drives`
    const candidToBack = `/placement-drives`
        
    useEffect(()=>{
        const fetchData = async () => {
            const data = await fetchPlacementDrivesData(GetDriveID, authToken);
            setPlacementDrivesData(data.data);
            console.log(data)
        }
        fetchData();
    }, [authToken, GetDriveID]);


    // all jobs
    useEffect(() => {
        const fetchAllJobs = async () => {
          try {
            const data = await getAllJobs(authToken);
            const filteredJobs = data.data.filter(job => job.PlacementDriveID === JSON.parse(GetDriveID));
            setAllJobs(filteredJobs);

            setProfileCount(filteredJobs.length)

            const totalOpenings = filteredJobs.reduce((sum, job) => {
                return sum + job.Openings
            }, 0)

            console.log("Total Openings:", totalOpenings);

            setTotalOpenings(totalOpenings)
    
            if (filteredJobs.length > 0) {
              const ctcRanges = filteredJobs.map(job => {
                const ctcRange = job.Ctc.split(' to ').map(Number);
                return ctcRange;
                });
                console.log(ctcRanges)
    
              const allCtcValues = ctcRanges.flat();
              console.log(allCtcValues)
              
              const minimumCtc = Math.min(...allCtcValues);
              const maximumCtc = Math.max(...allCtcValues);
    
              console.log('Min CTC', minimumCtc);
              console.log('Max CTC', maximumCtc);

              setMinCtc(minimumCtc)
              setMaxCtc(maximumCtc)

              
            } else {
              console.log('No jobs available for the selected Placement Drive.');
            }
          } catch (error) {
            console.error('Error fetching jobs:', error);
          }
        };
    
        fetchAllJobs();
      }, [authToken, GetDriveID]);


// Editing the drive

    useEffect(()=>{
        if(placementDrivesData){
            setEditableTitle(placementDrivesData?.Title);
            setEditableCriteria(placementDrivesData?.Criteria);
            setEditableLocation(placementDrivesData?.Location);
            setEditableFromDate(new Date(placementDrivesData.FromDate));
            setEditableToDate(new Date(placementDrivesData.ToDate));
        }
    }, [placementDrivesData])


    const handleTitleChange = (e) => {
        setEditableTitle(e.target.value); 
    };
    const handleLocationChange = (e) =>{
        setEditableLocation(e.target.value); 
    }


    const handleEditClick = () => {


        setIsEditing(true); // Enable editing mode
    };

    const handleSaveClick = () => {
       
        const editPlacementDrive = {
            'PlacementTitle' : editableTitle,
            'PlacementCriteria' : editableCriteria,
            'PlacementLocation' : editableLocation,
            'PlacementFromDate' : editableFromDate,
            'PlacementToData' : editableToDate,
        }
        console.log(editPlacementDrive)

        const updatePDrive = async() =>{
            const updatePdData = await updatePlacementDrive(GetDriveID, editPlacementDrive, authToken);
            console.log(updatePdData)
            console.log('placement drive updated successfully')

            const updateNewPlacementDrivesData = await fetchPlacementDrivesData(GetDriveID, authToken);
            setPlacementDrivesData(updateNewPlacementDrivesData.data);
            console.log(updateNewPlacementDrivesData , "updated placment drive")
        }
        updatePDrive();
        

        // After saving, exit editing mode
        setIsEditing(false);
    };

    // console.log(placementDrivesData)

    const handleCancelClick = () => {
        // Restore original values and exit editing mode
        setEditableTitle(placementDrivesData?.Title || '');
        setEditableCriteria(placementDrivesData?.Criteria || '');
        setEditableLocation(placementDrivesData?.Location || '');
        setIsEditing(false);
    };


    // New Job 
    
    // const handleNewJob = () => {
    //     // setIsNewJob(true)
    //     setIsNewJob(prevState => !prevState);
    //     console.log('new job')
    //     console.log(isNewJob)
    // }

    const [jobID, setJobID] = useState(1)
    const [jobTitle, setJobTitle] = useState('')
    const [companyName, setCompanyName] = useState('')
    const [ctcFrom, setCtcFrom] = useState(null)
    const [ctcTo, setCtcTo] = useState(null)
    const [jobOpenings, setJobOpenings] = useState(null)

    const handleJobTitle = (event) =>{
        setJobTitle(event.target.value)
    }
    const handleJobCompany = (event) =>{
        setCompanyName(event.target.value)
    }

    // Validation
    const [validated, setValidated] = useState(false);
    

    const handleNewJobPost = (event) => {
        event.preventDefault();
        const jobForm = event.currentTarget;
        if (jobForm.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }

        console.log(validated)
        
        
        const newJobData = {
            'JobID': jobID,
            'jobTitle' : jobTitle,
            'companyName': companyName,
            'jobOpenings': Number(jobOpenings),
            'jobCtcFrom': Number(ctcFrom),
            'jobCtcTo': Number(ctcTo),
        }
        // validated ? setIsNewJob(false) : setIsNewJob(true)

        // setJobID(prevId => prevId + 1);
        
        const jsonJobData = JSON.stringify(newJobData);
        console.log(jsonJobData);
        
        
        console.log('new job created')
        
        const placementDriveJob = async () =>{
            const jobData = await postNewPlacementDriveJob(GetDriveID, newJobData, authToken)
            console.log(jobData)

            const updatedPlacementDrivesData = await fetchPlacementDrivesData(GetDriveID, authToken);
            setPlacementDrivesData(updatedPlacementDrivesData.data);
        }
        
        placementDriveJob()
        
        setJobTitle('')
        setCompanyName('')
        setCtcFrom('')
        setCtcTo('')
        setJobOpenings('')
        
        
        setValidated(true);
        setIsNewJob(false);
    }

    const hanadleCancelNewJob = () =>{

        setIsNewJob(false)
        setJobTitle('')
        setCompanyName('')
        setCtcFrom('')
        setCtcTo('')
        setJobOpenings('')
    }



    const [buttonDisableID, setButtonDisableID] = useState([])
    const navigate = useNavigate();


    const handleViewJobDetails = (JobID) =>{
        // setSelectedDrive(drive);
        const tempData = placementDrivesData.Jobs.filter((itm) => itm.jobID === JobID);
        setSelectedJob(tempData[0]);
        navigate(`/emp-placement-job-details?driveID=${GetDriveID}&Job=${JobID}`)
        console.log(JobID)
    }   

    // const handleViewNewJobForm = () => {
    //     navigate(`/new-placement-drive-job-form`)
    // }

    const calculateCountdown = (fromDate, ToDate) => {
        const now = moment();
        const fromDateMoment = moment(fromDate);
        const formattedToDate = fromDateMoment.format('DD MMMM YYYY');
        const diffDays = fromDateMoment.diff(now, 'days');

        let remainingTime;

        if (diffDays < 0) {
            remainingTime = "Placement Drive is no longer available";
        } else if (diffDays === 0) {
            remainingTime = "Placement drive ends today";
        } else {
            remainingTime = `${diffDays} days remaining`;
        }

        return { formattedToDate, remainingTime, diffDays };

    };

    const notify = async (ar, index) => {

        setButtonDisableID([...buttonDisableID, index]);

        console.log(placementDrivesData?.ID, ar?.JobID, placementDrivesData?.EmployerID, ar?.jobTitle, ID)

        const applicationData = {
            'placementID': placementDrivesData?.ID ,
            'JobID': ar?.JobID, 
            'EmpID':placementDrivesData?.EmployerID, 
            'JobTitle': ar?.jobTitle, 
            'tokenUserID': ID
        }
        console.log(ar.JobID)

        const jobs = async () =>{ 
            
        const jobsData = await createJobApplication(applicationData, authToken)
        console.log(jobsData)

        if(jobsData?.data === "Job appliation created successfully!"){
                // setButtonDisable(false)
                toast.success('Application Submitted!', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light"
                    })
            }
        
        }
        jobs()
            

    };

    useEffect(() => {

        const fetchData = async () => {
            const SendData = {
                placementDriveID : GetDriveID,
                employerID : ID
            }
            const data = await GetAllApplicationsOfDrive(SendData, authToken);
            setApplicationsOfUser(data);
            console.log(data, "Applications data")
        }
        fetchData();
    }, [GetDriveID, ID, authToken])

    // console.log(ApplicationsOfUser)
    

    // console.log(GetDriveID)


    // Calculations from PD Jobs
    const jobs = placementDrivesData?.Jobs
    // console.log(jobs)

    // const parseJob = JSON.parse(jobs)

    // maximum & minimum ctc functionality
    // console.log(jobs)

    // let lowestCtcFrom, highestCtcTo, totalOpenings;

    // if (jobs && jobs.length === 0) { 
    //     lowestCtcFrom = 0;
    //     highestCtcTo = 0;
    //     totalOpenings = 0;
    // } else if (jobs) { 
    //     const ctcFromValues = jobs.map(job => job.jobCtcFrom);
    //     const ctcToValues = jobs.map(job => job.jobCtcTo);
    //     const openingsValues = jobs.map(job => job.jobOpenings)
    
    //     lowestCtcFrom = Math.min(...ctcFromValues);
    //     highestCtcTo = Math.max(...ctcToValues);
    //     totalOpenings = openingsValues.reduce((acc, curr) => acc + curr, 0);
    // } else {
    //     // jobs is undefined or null
    //     lowestCtcFrom = 0;
    //     highestCtcTo = 0;
    //     totalOpenings = 0;
    // }

    const pDriveID = searchParams.get('drive'); 

    // console.log(pDriveID)
    const handleDeleteDrive = async () =>{
        await deletePlacementDriveByID(pDriveID, authToken);
        console.log("placement drive deleted successfully")
        console.log(pDriveID)
        navigate('/emp-placement-drives')
    }
    

    const handleViewUserProfile = (candidateID) => {
        navigate(`/candidate-profile?candidate=${candidateID}`)
    }

    const refreshJobs = async() =>{
        const data = await getAllJobs(authToken);
        const filteredJobs = data.data.filter(job => job.PlacementDriveID === JSON.parse(GetDriveID))
        setAllJobs(filteredJobs);
    }


    return placementDrivesData.length === 0 ? <ShimmerEmpPdDetails/> : 
    (
        <div id="appCapsule" className="placement-drive-detials">
            <div className="section mb-2 mt-2 full">
                <div className="container pb-2">

                {role === "Admin" && 
                    <Link to={adminToBack}type="submit" className='my-2 pe-2' ><FaArrowLeft style={{width: '23px', height: '23px'}}/></Link> 
                }
                {role === "Employer" &&
                    <Link to={empToBack}type="submit" className='my-2 pe-2' ><FaArrowLeft style={{width: '23px', height: '23px'}}/></Link> 
                }
                {role === "Candidate" &&
                    <Link to={candidToBack}type="submit" className='my-2 pe-2' ><FaArrowLeft style={{width: '23px', height: '23px'}}/></Link> 
                }

                <Row xs={1} md={1} className="g-4 py-4">
                    <Col>
                        <Card>
                            <Card.Body className="placement-drive-card ">
                                {/* card editable */}
                                {(role==='Employer'|| role === 'Admin') && (
                                    isEditing ? (
                                        <>
                                            <Row className='pb-2 border-bottom border-gray pd-card-head d-flex justify-content-center'>
                                                <Col lg='2' md='3' xs='4' className="d-flex align-items-center flex-column">
                                                    <Card.Img className="PD-image imaged rounded rounded-image" variant="top" src={placementDrivesData?.Image ? placementDrivesData?.Image : staicLogo} />
                                                    {/* <Form.Control type="file"  accept="image/*" multiple onChange={(e) => {uploadRefImage(e.target.files[0])}} /> */}
                                                </Col>
                                                <Col lg='10' md='9' xs='8'>
                                                    <div className="d-flex justify-content-between">
                                                        <Card.Title style={{width: '75%'}}>
                                                            <Form.Group className="">
                                                                <Form.Control
                                                                type="text"
                                                                onChange={handleTitleChange}
                                                                value={editableTitle}
                                                                placeholder="Enter Placement Drive Title"
                                                                />
                                                            </Form.Group>
                                                        </Card.Title>
                                                        <div className="d-flex gap-1">
                                                            {/* <Button variant="primary" onClick={handleSaveClick}>Save</Button> */}
                                                            <Button className="PD-action-btn" variant="primary" onClick={handleSaveClick}><BsSendArrowUp /></Button>
                                                            {/* <Button variant="outline-primary" onClick={handleCancelClick}>Cancel</Button> */}
                                                            <Button className="PD-action-btn" variant="outline-primary" onClick={handleCancelClick}><AiOutlineClose /></Button>
                                                        </div>
                                                    </div>
                                                    <Card.Text className='drive-details dot-icon'>{minCtc} - {maxCtc} LPA <GoDotFill /> {placementDrivesData?.Jobs.length} profiles <GoDotFill /> {totalOpenings} openings</Card.Text>
                                                </Col>
                                            </Row> 
                                            <Card.Text className="pt-2">
                                                <Form.Group className="mb-3">
                                                    {/* <Form.Label>Criteria</Form.Label> */}
                                                    <Form.Control
                                                    required
                                                    as="textarea"
                                                    placeholder="Description"
                                                    onChange={(e) => setEditableCriteria(e.target.value)}
                                                    value={editableCriteria}
                                                    style={{ height: '100px' }}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        Please provide a valid Criteria.
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Card.Text>
                                            <Card.Text>
                                                <div className="w-25 d-flex" style={{height: '37px'}}>
                                                    {/* <span>location</span> */}
                                                    <InputGroup.Text id="inputGroup-sizing-default" style={{height: '37px'}}>
                                                        <ion-icon name="location-outline" style={{color: '#45a994'}}></ion-icon>
                                                        {/* <IoLocationOutline style={{color: '#45a994'}}/> */}
                                                    </InputGroup.Text>
                                                    <InputGroup size="sm" className="mb-3" style={{height: '37px'}}>
                                                        <Form.Select aria-label="Default select example" onChange={handleLocationChange} value={editableLocation}>
                                                            <option selected disabled>{placementDrivesData?.Location}</option>
                                                            <option value="Mumbai">Mumbai</option>
                                                            <option value="Navi Mumbai">Navi Mumbai</option>
                                                            <option value="Pune">Pune</option>
                                                        </Form.Select> 
                                                    </InputGroup>
                                                </div>
                                            </Card.Text>
                                            <div className="d-md-flex gap-2 mb-2">
                                                <Form.Group className='my-0 d-flex'>
                                                    {/* <Form.Label>Start Date(YYYY/MM/DD)</Form.Label> */}
                                                    <InputGroup.Text id="inputGroup-sizing-default">
                                                        <ion-icon name="calendar-outline" style={{color: '#45a994'}}></ion-icon>
                                                    </InputGroup.Text>
                                                    <InputGroup>
                                                    <DatePicker
                                                        style={{height: '100%'}}
                                                        showIcon
                                                        selected={editableFromDate}
                                                        onChange={(date) => setEditableFromDate(date)}
                                                        icon="fa fa-calendar" 
                                                        dateFormat="dd/MM/yyyy"
                                                         />
                                                    </InputGroup>
                                                </Form.Group> 
                                                <div className="d-flex align-items-center"><span>to</span></div>
                                                <Form.Group className='my-0 d-flex'>
                                                    {/* <Form.Label>End Date(YYYY/MM/DD)</Form.Label> */}
                                                    <InputGroup.Text id="inputGroup-sizing-default">
                                                        <ion-icon name="calendar-outline" style={{color: '#45a994'}}></ion-icon>
                                                    </InputGroup.Text>
                                                    <InputGroup>
                                                    <DatePicker
                                                        showIcon
                                                        selected={editableToDate}
                                                        onChange={(date) => setEditableToDate(date)}
                                                        minDate={editableFromDate}
                                                        icon="fa fa-calendar" 
                                                        dateFormat="dd/MM/yyyy" />
                                                    </InputGroup>
                                                </Form.Group>
                                            </div>
                                        </>
                                ):(
                                    <>
                                        <Row className='pb-2 border-bottom border-gray pd-card-head d-flex justify-content-center'>
                                            <Col lg='2' md='2' xs='2' className="d-flex justify-content-center">
                                                <Card.Img className="PD-image imaged rounded rounded-image" variant="top" src={placementDrivesData?.Image ? placementDrivesData?.Image : staicLogo} />
                                            </Col>
                                            <Col lg='10' md='10' xs='10'>
                                                <div className="d-flex justify-content-between">
                                                    <Card.Title>{placementDrivesData?.Title}</Card.Title>
                                                    <div className="d-flex gap-2">
                                                        {/* <Button className="btn-desktop" onClick={handleEditClick}>Edit</Button> */}
                                                        <Button className="PD-action-btn" onClick={handleEditClick}><FaPen /></Button>
                                                        {/* <Button variant="danger" onClick={() => setModalShow(true)}>Delete</Button> */}
                                                        <Button className="PD-action-btn" variant="danger" onClick={() => setModalShow(true)}><RiDeleteBin6Line /></Button>
                                                    </div>
                                                </div>
                                                <Card.Text className='drive-details dot-icon d-flex'>
                                                    <span className='d-flex gap-1'> <span className='rupee'>₹</span>{minCtc !== undefined ? minCtc : 0} - {maxCtc !== undefined ? maxCtc : 0} LPA</span>
                                                    <span className='ps-1 d-flex gap-1'><Image className='icons' src={profileIcon}/>profiles({profileCount})</span>
                                                    <span className='ps-1 d-flex gap-1'><Image className='icons' src={applicantIcon}/>openings({totalOpenings})</span>
                                                </Card.Text>
                                            </Col>
                                        </Row> 
                                        <Card.Text className='py-3'>{placementDrivesData?.Criteria}</Card.Text>
                                        <div className='d-flex justify-content-between align-items-center gap-3'>
                                            <div>
                                                <Card.Text className='mb-0 location'><ion-icon name="location-outline"></ion-icon> {placementDrivesData?.Location}</Card.Text> 
                                                <Card.Text className='date'>
                                                    {moment(placementDrivesData?.FromDate).format('DD MMMM YYYY')} to {moment(placementDrivesData?.ToDate).format('DD MMMM YYYY')}
                                                    <span className='d-flex text-muted gap-1'> <Image className='muted-icon' src={timeIcon}/>{calculateCountdown(placementDrivesData?.ToDate, placementDrivesData?.ToDate).remainingTime}
                                                    </span>
                                                </Card.Text> 
                                            </div> 
                                        </div>
                                        <DeletePdModal
                                            show={modalShow}
                                            onHide={() => setModalShow(false)}
                                            handleDeleteDrive={handleDeleteDrive}
                                            confirmDeleteMessage="Are you sure you want to delete this Placement Drive?"
                                        />
                                    </>
                                ))}

                                {role === 'Candidate' && (
                                    <>
                                        <Row className='pb-2 border-bottom border-gray pd-card-head '>
                                            <Col lg='2' md='2' xs='2'><Card.Img className="PD-image" variant="top" src={placementDrivesData?.Image ? placementDrivesData?.Image : staicLogo} /></Col>
                                            <Col lg='10' md='10' xs='10'>
                                                <div className="d-flex justify-content-between">
                                                    <Card.Title>{placementDrivesData?.Title}</Card.Title>
                                                </div>
                                                <Card.Text className='drive-details dot-icon d-flex'>
                                                    <span className='d-flex gap-1'> <span className='rupee'>₹</span>{minCtc !== undefined ? minCtc : 0} - {maxCtc !== undefined ? maxCtc : 0} LPA</span>
                                                    <span className='ps-1 d-flex gap-1'><Image className='icons' src={profileIcon}/>profiles({profileCount})</span>
                                                    <span className='ps-1 d-flex gap-1'><Image className='icons' src={applicantIcon}/>openings({totalOpenings})</span>
                                                </Card.Text>
                                            </Col>
                                        </Row> 
                                        <Card.Text className='py-3 min-h-6'>{placementDrivesData?.Criteria}</Card.Text>
                                        <div className='d-flex justify-content-between align-items-center gap-3'>
                                            <div>
                                                <Card.Text className='mb-0 location'><ion-icon name="location-outline"></ion-icon> {placementDrivesData?.Location}</Card.Text> 
                                                <Card.Text className='date'>
                                                    {moment(placementDrivesData?.FromDate).format('DD MMMM YYYY')} to {moment(placementDrivesData?.ToDate).format('DD MMMM YYYY')}
                                                    <span className='d-flex text-muted gap-1'><Image className='muted-icon' src={timeIcon}/>
                                                    {calculateCountdown(placementDrivesData?.ToDate, placementDrivesData?.ToDate).remainingTime}
                                                    </span>
                                                </Card.Text> 
                                            </div> 
                                        </div>
                                    </>
                                )}
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>


                {/* card non editable */}
              

                
            <div className="section mb-2 mt-2 full">
                <div className="container pb-2">
                    <div className='d-flex justify-content-between'>
                        <h1 className='text-primary d-flex align-items-center gap-2'>Jobs at {placementDrivesData?.Title}
                        <Badge bg="primary" className="text-white">{allJobs ? allJobs.length : '0'}</Badge>
                        </h1>
                        {(role === 'Employer' || role === 'Admin') && 
                        <>
                            {/* <Button className="btn-secondary" onClick={handleViewNewJobForm}>Add</Button> */}
                            {isNewJob ? 
                                <Button variant="outline-primary" onClick={hanadleCancelNewJob}>Cancel</Button> :
                                <Button className="btn-secondary" onClick={handleShowNewjobFormModal}>New Job</Button> 
                            }
                        </>
                        }
                    </div>


                    <Row  className="g-4">
                    {
                        isNewJob ? (
                            <>
                                <Col xs={12} md={6} lg={6} >
                                    <Form>
                                        <Card className="job-card">
                                            <Card.Body>
                                                <Row className='pb-2 '>
                                                    <Col lg='3' md='3' xs='3'><Card.Img className="PD-image p-1" variant="top" src={staicLogo}/></Col>
                                                    <Col lg='9' md='9' xs='9'>
                                                        <Card.Text>
                                                            <Form.Group className="">
                                                                <Form.Control
                                                                required 
                                                                type="text"
                                                                onChange={handleJobTitle}
                                                                value={jobTitle}
                                                                placeholder="Enter Job Title"
                                                                />
                                                                <Form.Control.Feedback type="invalid" style={{fontSize: '.8rem'}}>
                                                                    Please provide a valid Title.
                                                                </Form.Control.Feedback>
                                                            </Form.Group>
                                                        </Card.Text>
                                                        <Card.Text className="">
                                                            <Form.Group className="">
                                                                <Form.Control
                                                                required 
                                                                type="text"
                                                                onChange={handleJobCompany}
                                                                value={companyName}
                                                                placeholder="Enter Company Name"
                                                                />
                                                                <Form.Control.Feedback type="invalid" style={{fontSize: '.8rem'}}>
                                                                    Please provide a valid Company Name.
                                                                </Form.Control.Feedback>
                                                            </Form.Group>
                                                        </Card.Text>
                                                        <Card.Text className='job-details dot-icon'>
                                                            <div className="d-md-flex align-items-center gap-2 mb-2">
                                                                <Form.Select 
                                                                aria-label="Default select example" 
                                                                style={{width: '79px', padding: '3px 8px'}}
                                                                onChange={(e) => setCtcFrom(e.target.value)}
                                                                >
                                                                    <option disabled selected value=''>0</option>
                                                                    <option value="5">5</option>
                                                                    <option value="10">10</option>
                                                                    <option value="15">15</option>
                                                                    <option value="20">20</option>
                                                                    <option value="25">25</option>
                                                                    <option value="35">35</option>
                                                                </Form.Select> -
                                                                <Form.Select 
                                                                aria-label="Default select example" 
                                                                style={{width: '79px', padding: '3px 5px'}}
                                                                required
                                                                onChange={(e) => setCtcTo(e.target.value)}
                                                                >
                                                                    <option disabled selected value=''>0</option>
                                                                    <option value="5">5</option>
                                                                    <option value="10">10</option>
                                                                    <option value="15">15</option>
                                                                    <option value="20">20</option>
                                                                    <option value="25">25</option>
                                                                    <option value="35">35</option>
                                                                </Form.Select> LPA <GoDotFill /> 
                                                                <Form.Select 
                                                                aria-label="Default select example" 
                                                                style={{width: '60px', padding: '3px 5px'}}
                                                                onChange={(e) => setJobOpenings(e.target.value)}
                                                                >
                                                                    <option disabled selected value=''>0</option>
                                                                    <option value="1">1 </option>
                                                                    <option value="2">2</option>
                                                                    <option value="3">3</option>
                                                                    <option value="4">4</option>
                                                                    <option value="5">5</option>
                                                                    <option value="6">6</option>
                                                                </Form.Select> openings
                                                            </div>
                                                        </Card.Text>
                                                        {/* <Card.Text className='py-3'>{data?.Criteria}</Card.Text> */}
                                                        <div className='d-flex justify-content-start align-items-center gap-3'>
                                                            <div>
                                                                <Button className="d-flex justify-end btn-secondary" onClick={handleNewJobPost}>Add Job</Button>
                                                            </div> 
                                                        
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Card.Body>
                                        </Card>
                                    </Form>
                                </Col>
                            </>
                        ) : 
                        (
                            <>
                            {
                                allJobs.length === null && 
                                <div className='my-4'>
                                    <div className="item">
                                        <div className="imageWrapper in">
                                            <div className="in">
                                                <div>Loading...</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                }
                                
                                {
                                    allJobs.length !== null && allJobs.length === 0 &&
                                    <div className="d-flex flex-column">
                                        <div className='my-4'>
                                            <div className="item">
                                                <div className="imageWrapper in">
                                                    <div className="in">
                                                        <div>No Job found at Placement Drive</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        { (role === 'Employer' || role === 'Admin') &&
                                            <div className="d-flex justify-content-center align-items-center my-4">
                                                {/* <Button className="btn-secondary" onClick={handleNewJob}>New Job</Button>  */}
                                                <Button className="btn-secondary" onClick={handleShowNewjobFormModal}>New Job</Button> 
                                            </div>
                                        }
                                    </div>
                                }
                            </>
                        )
                    }

                    {/* New job modal */}
                    <Modal
                        show={showJobFormModal} 
                        onHide={handleCloseNewJobModal}
                        size="lg"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                    >
                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">
                                Add New Job at {placementDrivesData?.Title}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <NewJobForm 
                            refreshJobs={refreshJobs}
                            placementDriveId={placementDrivesData?.ID}
                            onHide={()=> setShowJobFormModal(false)}
                            />
                        </Modal.Body>
                    </Modal>

                


                    {/* jobs component */}
                    <JobListCard 
                        allJobs={allJobs}
                    />
                    

                    </Row>
                    {/* Toast */}
                    
                </div>
            </div>

            </div>
            </div>

            {/* Applicants List Start */}
            {(role === 'Employer' || role === 'Admin') &&
            placementDrivesData?.Jobs.length > 0 && (
            
                <>
                    <div className="section full mt-4 mb-4">
                        <div className='container'>
                            <div className="d-flex gap-2 align-items-center">
                                <div className="section-title ps-0">All Applied Candidates</div>
                                <Badge bg="secondary">{ApplicationsOfUser ? ApplicationsOfUser.length : '0'}</Badge>
                            </div>
                                <ul className="listview image-listview media">
                                {
                                    ApplicationsOfUser === null &&
                                    <li className='my-2'>
                                    <div className="item">
                                        <div className="imageWrapper in">
                                            <div className="in">
                                                <div>Loading...</div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                }

                                {
                                    ApplicationsOfUser !== null && ApplicationsOfUser.length === 0 &&
                                    <li className='my-2'>
                                    <div className="item">
                                        <div className="imageWrapper in">
                                            <div className="in">
                                                <div>No Application Applied</div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                }
                                {
                                    ApplicationsOfUser !== null && ApplicationsOfUser.length > 0 &&
                                    ApplicationsOfUser.map((item, i) => {
                                        return (
                                            <li key={i} className='my-2'>
                                                <div className="item">
                                                    <div className="imageWrapper in">
                                                        <img src={userPhoto} alt="" className="imaged w64" />
                                                        <div className="in">
                                                            <div>{item.CandidateName}</div>
                                                        </div>
                                                    </div>
                                                    <div className="in">
                                                        <div>{item.JobTitle}</div>
                                                    </div>
                                                    <div className="in">
                                                        <div>{item.PlacementDriveName}</div>
                                                    </div>
                                                    <div className='d-flex align-items-center'>
                                                        {/* <Button className='me-2 p-1 rounded' variant="secondary">
                                                            <ion-icon style={{marginRight: '0px'}} name="bookmark-outline"></ion-icon>
                                                        </Button> */}
                                                        <Button variant="primary" onClick={() => handleViewUserProfile(item?.CandidateID)}>View</Button>
                                                    </div>
                                                </div>
                                            </li>
                                        )
                                    })
                                }
                            </ul>
                        </div>
                    </div>
                </>
            )
            }
            {/* Applicants List End */}

        </div>
    )
}

const mapStateToProps = ( { auth } ) => {
    const { userData, role, fullName, ID, authToken } = auth
  
    return {
        userData,
        role,
        fullName,
        ID,
        authToken
    }
  }
  
  const mapDispatchToProps = {
    setToken,
    setSelectedJob
  }

export default connect(
    mapStateToProps,
    mapDispatchToProps,
  )(EmpPlacementDrivesDetails)
